export const bg_BG = 'bg_BG';
export const cs_CZ = 'cs_CZ';
export const de_AT = 'de_AT';
export const de_DE = 'de_DE';
export const el_GR = 'el_GR';
// export const en_US = 'en_US'; // @todo fix config
export const et_EE = 'et_EE';
export const fr_FR = 'fr_FR';
export const hr_HR = 'hr_HR';
export const hu_HU = 'hu_HU';
export const it_IT = 'it_IT';
export const lt_LT = 'lt_LT';
export const lv_LV = 'lv_LV';
export const pl_PL = 'pl_PL';
export const ro_RO = 'ro_RO';
// export const ru_UA = 'ru_UA'; // @todo fix config
export const sk_SK = 'sk_SK';
export const sl_SI = 'sl_SI';
export const uk_UA = 'uk_UA';

export const LOCALES = [
    bg_BG,
    cs_CZ,
    de_AT,
    de_DE,
    el_GR,
    // en_US, // @todo fix config
    et_EE,
    fr_FR,
    hr_HR,
    hu_HU,
    it_IT,
    lt_LT,
    lv_LV,
    pl_PL,
    ro_RO,
    // ru_UA, // @todo fix config
    sk_SK,
    sl_SI,
    uk_UA,
];
