import mutations from './mutations';
import actions from './actions';

const state = () => ({
    query: '',
    products: [],
    brands: [],
    categories: [],
    suggestions: [],
    phrases: [],
    redirect: null,
    isQuerying: false,
    autocompleteVisited: false,
    isNewAutocomplete: false,
    capping: false,
    analyticsTrigger: false,
    autocompleteActiveMainCategory: {},
});

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
