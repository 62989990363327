const middleware = {}

middleware['check-locale-path'] = require('../middleware/check-locale-path.js')
middleware['check-locale-path'] = middleware['check-locale-path'].default || middleware['check-locale-path']

middleware['customer/authorization'] = require('../middleware/customer/authorization.js')
middleware['customer/authorization'] = middleware['customer/authorization'].default || middleware['customer/authorization']

middleware['global/fetch-store-data'] = require('../middleware/global/fetch-store-data.js')
middleware['global/fetch-store-data'] = middleware['global/fetch-store-data'].default || middleware['global/fetch-store-data']

middleware['global/normalized-path-redirect'] = require('../middleware/global/normalized-path-redirect.js')
middleware['global/normalized-path-redirect'] = middleware['global/normalized-path-redirect'].default || middleware['global/normalized-path-redirect']

middleware['global/set-error-handler-page-context'] = require('../middleware/global/set-error-handler-page-context.js')
middleware['global/set-error-handler-page-context'] = middleware['global/set-error-handler-page-context'].default || middleware['global/set-error-handler-page-context']

middleware['global/set-headers'] = require('../middleware/global/set-headers.js')
middleware['global/set-headers'] = middleware['global/set-headers'].default || middleware['global/set-headers']

middleware['pages-data/fetch-checkout-cart'] = require('../middleware/pages-data/fetch-checkout-cart.js')
middleware['pages-data/fetch-checkout-cart'] = middleware['pages-data/fetch-checkout-cart'].default || middleware['pages-data/fetch-checkout-cart']

middleware['pages-data/fetch-checkout'] = require('../middleware/pages-data/fetch-checkout.js')
middleware['pages-data/fetch-checkout'] = middleware['pages-data/fetch-checkout'].default || middleware['pages-data/fetch-checkout']

middleware['pages-data/fetch-cms-sidebar'] = require('../middleware/pages-data/fetch-cms-sidebar.js')
middleware['pages-data/fetch-cms-sidebar'] = middleware['pages-data/fetch-cms-sidebar'].default || middleware['pages-data/fetch-cms-sidebar']

middleware['pages-data/fetch-contact'] = require('../middleware/pages-data/fetch-contact.js')
middleware['pages-data/fetch-contact'] = middleware['pages-data/fetch-contact'].default || middleware['pages-data/fetch-contact']

middleware['parse-product-route-url'] = require('../middleware/parse-product-route-url.js')
middleware['parse-product-route-url'] = middleware['parse-product-route-url'].default || middleware['parse-product-route-url']

middleware['redirect-to-customer-account'] = require('../middleware/redirect-to-customer-account.js')
middleware['redirect-to-customer-account'] = middleware['redirect-to-customer-account'].default || middleware['redirect-to-customer-account']

middleware['rma/redirect-to-rma-static-page'] = require('../middleware/rma/redirect-to-rma-static-page.js')
middleware['rma/redirect-to-rma-static-page'] = middleware['rma/redirect-to-rma-static-page'].default || middleware['rma/redirect-to-rma-static-page']

middleware['url-resolver'] = require('../middleware/url-resolver.js')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
