export const ERROR_ACTION_TAG_NAME = 'pwa.action';
export const ERROR_PAGE_TAG_NAME = 'pwa.page';
export const ERROR_STORE_MODULE_TAG_NAME = 'pwa.store';
export const ERROR_PAGE_BUILDER_TAG_NAME = 'pwa.pageBuilder';
export const ERROR_LOCALE_TAG_NAME = 'pwa.locale';
export const ERROR_CORRELATION_ID_TAG_NAME = 'pwa.correlation-id';
export const ERROR_FEATURE_DOMAIN_TAG_NAME = 'pwa.featureDomain';
export const ERROR_SYNERISE_ERROR_HTTP_STATUS = 'pwa.synerise.error.http-status';
export const ERROR_SYNERISE_ERROR_CODE = 'pwa.synerise.error.code';
export const ERROR_K8S_TAG_NAME = 'pwa.k8s';

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
