export const errorHandler = {
    captureError: () => null,
    captureStoreMessage: () => null,
};

export default {
    install: Vue => {
        Vue.prototype.$errorHandler = errorHandler;
    },
};
