import { USER_UNAUTHORIZED } from '@rma/types/Errors';

import { FEATURE_DOMAIN_NAME } from './context';

import { UNAUTHORIZED_CODE } from './status-codes';

import { endpointToSuffixMap } from './endpoints';

import { createGuestProcessAuthorizationHeaders } from './guest-process-authorization-headers';

import { getRmaHeaders } from './headers';

const isRmaFeatureDomain = context => context?.domainName === FEATURE_DOMAIN_NAME;

const getRmaMegatronEndpoints = ({ storeCode, megatronEndpoint, createMegatronEndpointUrl }) =>
    Array.from(endpointToSuffixMap.entries()).reduce((endpoints, [name, suffix]) => {
        endpoints[name] = {
            uri: createMegatronEndpointUrl(megatronEndpoint, suffix),
            headers: {
                ...getRmaHeaders(storeCode),
            },
            useErrorCallback: false,
            context: {
                domainName: FEATURE_DOMAIN_NAME,
                useAuthorization: true,
            },
        };

        return endpoints;
    }, {});

export const getRmaMegatronConfig = ({
    store,
    storeCode,
    megatronEndpoint,
    createMegatronEndpointUrl,
}) => {
    return {
        endpoints: {
            ...getRmaMegatronEndpoints({
                storeCode,
                megatronEndpoint,
                createMegatronEndpointUrl,
            }),
        },
        interceptors: {
            request: {
                success: (request, context) => {
                    if (!isRmaFeatureDomain(context)) {
                        return;
                    }

                    const guestProcessHeaders = createGuestProcessAuthorizationHeaders(store);

                    if (guestProcessHeaders) {
                        request.headers = {
                            ...request.headers,
                            ...guestProcessHeaders,
                        };
                    }
                },
            },
            response: {
                error: (error, context) => {
                    if (!isRmaFeatureDomain(context)) {
                        return;
                    }

                    const { isGuestSignInRequest = false } = context;

                    if (isGuestSignInRequest) {
                        return;
                    }

                    if (error?.response?.status === UNAUTHORIZED_CODE) {
                        store.dispatch('rma/setProcessError', {
                            code: UNAUTHORIZED_CODE,
                            type: USER_UNAUTHORIZED,
                        });
                    }
                },
            },
        },
    };
};
