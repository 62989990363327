import { HTTP_CLIENT_ENDPOINT_NAME_AVATAR } from '@configs/http-client';

const ENDPOINT_PARAMETER = { endpoint: HTTP_CLIENT_ENDPOINT_NAME_AVATAR };

export default httpClient => ({
    createIdForGuest: payload =>
        httpClient.post('/guest/anonymous-user', {
            ...ENDPOINT_PARAMETER,
            payload,
            context: {
                useAuthorization: false,
            },
        }),

    connectClient: avatarId =>
        httpClient.post('/guest/connect-client', {
            ...ENDPOINT_PARAMETER,
            payload: {
                anonymousUserId: avatarId,
            },
        }),

    sendMagicLink: payload =>
        httpClient.post('/magic-link/send', {
            ...ENDPOINT_PARAMETER,
            payload,
            context: {
                useAuthorization: false,
            },
        }),

    createClientAddress: payload =>
        httpClient.post('/client/address', {
            ...ENDPOINT_PARAMETER,
            payload,
        }),

    readClientAddresses: () =>
        httpClient.get('/client/address/', {
            ...ENDPOINT_PARAMETER,
        }),

    updateClientAddress: (clientAddressId, payload) =>
        httpClient.put(`/client/address/${clientAddressId}`, {
            ...ENDPOINT_PARAMETER,
            payload,
        }),

    deleteClientAddress: clientAddressId =>
        httpClient.delete(`/client/address/${clientAddressId}`, {
            ...ENDPOINT_PARAMETER,
        }),

    setDefaultBillingClientAddress: clientAddressId =>
        httpClient.put(`/client/address/defaults/billing/${clientAddressId}`, {
            ...ENDPOINT_PARAMETER,
        }),

    setDefaultDeliveryClientAddress: clientAddressId =>
        httpClient.put(`/client/address/defaults/delivery/${clientAddressId}`, {
            ...ENDPOINT_PARAMETER,
        }),

    createAccountVerification: payload =>
        httpClient.post('/verification/verification', {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            payload,
        }),

    confirmAccountVerification: token =>
        httpClient.get(`/verification/verification/confirm/token/${token}`, {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            context: {
                useAuthorization: false,
            },
        }),

    resendAccountVerification: payload =>
        httpClient.patch('/verification/verification/resend', {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            payload,
        }),

    checkAccountVerificationStatus: payload =>
        httpClient.post('/verification/verification/status', {
            ...ENDPOINT_PARAMETER,
            useErrorCallback: false,
            payload,
        }),

    checkEmailExist: payload =>
        httpClient.post('/client/client/check-email-exists', {
            ...ENDPOINT_PARAMETER,
            payload,
            context: {
                useAuthorization: false,
            },
        }),
});
