import { types } from './mutations';

export default {
    setVisualSearchPimCode({ commit }, data) {
        commit(types.SET_VISUAL_SEARCH_PIM_CODE, data);
    },
    setVisualSearchImg({ commit }, data) {
        commit(types.SET_VISUAL_SEARCH_IMG, data);
    },
    setVisualSearchFileName({ commit }, data) {
        commit(types.SET_VISUAL_SEARCH_FILE_NAME, data);
    },
    setVisualSearchId({ commit }, data) {
        commit(types.SET_VISUAL_SEARCH_ID, data);
    },
};
