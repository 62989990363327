import createAsyncServices from '@eobuwie/nuxt-async-services';

import asyncServicesConfig from '@async-services';

import createServices from '@services/create';

import { SYNERISE_CONFIG_NAME } from '@localeConfig/keys';

import { getMegatronNormalizer } from '@normalizers/megatron-normalizers';

export default ({ store, app, $config, $errorHandler }, inject) => {
    const { asyncServicesManagerDebugModeEnabled } = $config;

    const { api_key, profile_key, workspace_key, campaigns, placements } =
        store.state.config.localeConfig[SYNERISE_CONFIG_NAME] || {};

    const config = {
        api_key,
        profile_key,
        workspace_key,
        campaigns,
        placements,
    };

    const {
        $abTests,
        $megatronClient,
        $httpClient,
        $cookies,
        $performanceRecorder,
        redirect,
        route,
    } = app;

    const helpers = {
        abTests: $abTests,
        $cookies,
        store,
    };

    const normalizeMegatronResponse = getMegatronNormalizer(app, store);

    const services = createServices(
        $errorHandler,
        $megatronClient,
        $httpClient,
        config,
        helpers,
        normalizeMegatronResponse
    );

    inject('services', services);

    inject(
        'asyncServices',
        createAsyncServices({
            servicesConfig: asyncServicesConfig,
            runtimeServicesHelpers: {
                megatronClient: $megatronClient,
                httpClient: $httpClient,
                $performanceRecorder,
                $errorHandler,
                config,
                helpers,
                normalizeMegatronResponse,
                redirect,
                route,
            },
            debugModeEnabled: asyncServicesManagerDebugModeEnabled,
        })
    );
};
