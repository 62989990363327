<template>
    <div class="download-app">
        <BaseHeading class="heading">{{ $t('Download our application') }}:</BaseHeading>

        <div class="wrapper">
            <div class="qr-code">
                <MobileAppQrCode />
            </div>

            <MobileAppLinks
                :play-url="playUrl"
                :app-store-url="appStoreUrl"
                :app-gallery-url="appGalleryUrl"
                class="apps"
            />
        </div>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import MobileAppQrCode from '@molecules/MobileAppQrCode/MobileAppQrCode';
import MobileAppLinks from '@molecules/MobileAppLinks/MobileAppLinks';

export default {
    name: 'DownloadApp',

    components: {
        MobileAppQrCode,
        BaseHeading,
        MobileAppLinks,
    },

    props: {
        playUrl: {
            type: String,
            default: '',
        },

        appStoreUrl: {
            type: String,
            default: '',
        },

        appGalleryUrl: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
$qr-code-dimension: 112px;

.download-app {
    @apply text-center;

    .heading {
        @apply uppercase;
    }

    .wrapper {
        @apply flex items-center justify-center mt-3;
    }

    .apps {
        @apply flex flex-col items-center;
    }

    .qr-code {
        @apply shrink-0 hidden text-gray1;
        width: $qr-code-dimension;
        height: $qr-code-dimension;
    }

    @screen lg {
        @apply text-left;

        .apps {
            @apply ml-3;
        }

        .qr-code {
            @apply block;
        }

        .wrapper {
            @apply justify-start;
        }
    }
}
</style>
