export const TOGGLE = 'toggle';
export const MULTISELECT = 'multiselect';
export const RANGE = 'range';
export const SPECIAL = 'special';
export const NESTED_MULTISELECT = 'nestedmultiselect';
export const GROUP = 'group';

export const PRICE_FILTER_CODE = 'final_price';
export const DISCOUNT_FILTER_CODE = 'discount';
export const SPECIAL_ACTION_CODE = 'action_label';
export const HOT_DEAL_ACTION_CODE = 'hot_deal';
export const PRICE_CODE = 'price';
export const BRAND_GROUP_CODE = 'brand_group';
export const COLOR_FILTER_CODE = 'kolor';
export const BRAND_FILTER_CODE = 'manufacturer_with_collection';
export const PRODUCT_BADGE_FILTER_CODE = 'product_badge';
export const OCCASION_OPTION_CODE = 'okazja';
export const PREMIUM_OPTION_CODE = 'premium';
export const NEW_OPTION_CODE = 'nowosc';

export const DISCOUNT_FILTER_URL = '1';

export const AGGREGATED_SIZE_FILTER_B_CODE = 'aggregated_size_b';

export const RANGE_FILTER_MIN_KEY = 'min';
export const RANGE_FILTER_MAX_KEY = 'max';

export const URL = 'url';
export const CODE = 'code';

export const EMPTY = 'empty';

export const FILTER_TYPES = {
    TOGGLE,
    MULTISELECT,
    RANGE,
    SPECIAL,
    NESTED_MULTISELECT,
};
