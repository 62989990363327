<script>
import { createNamespacedHelpers } from 'vuex';

import {
    FOOTER_DOWNLOAD_APP,
    FOOTER_DOWNLOAD_APP_GALLERY,
    SUCCESS_PAGE_DOWNLOAD_APP,
    SUCCESS_PAGE_DOWNLOAD_APP_GALLERY,
} from '@localeConfig/keys';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'MobileAppsLinksProvider',

    computed: {
        ...mapConfigGetters(['localeConfigByKey']),
    },

    render() {
        return this.$scopedSlots.default({
            footerAppUrl: this.localeConfigByKey(FOOTER_DOWNLOAD_APP),
            footerAppGalleryUrl: this.localeConfigByKey(FOOTER_DOWNLOAD_APP_GALLERY),
            successPageAppUrl: this.localeConfigByKey(SUCCESS_PAGE_DOWNLOAD_APP),
            successPageAppGalleryUrl: this.localeConfigByKey(SUCCESS_PAGE_DOWNLOAD_APP_GALLERY),
        });
    },
};
</script>
