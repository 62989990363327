import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { CATALOG_NAMESPACE } from '@models/Performance/PerformanceRecorder';

import { types } from './mutations';

const { GET_SEO_DATA } = CATALOG_NAMESPACE;

function getSeoDataUrl(isServer, endpoint) {
    if (isServer || typeof window === 'undefined') {
        return endpoint;
    }

    return `${window.location.origin}${endpoint}`;
}

const SUPPORTED_LOCALES = ['de_DE', 'fr_FR', 'it_IT', 'lt_LT', 'pl_PL', 'uk_UA', 'cs_CZ', 'sk_SK'];

const actions = {
    async getSeoData({ commit, state, rootGetters }) {
        this.app.$performanceRecorder.start(GET_SEO_DATA);

        if (Object.keys(state.seoData).length) {
            this.app.$performanceRecorder.end(GET_SEO_DATA);

            return state.seoData;
        }

        const locale = rootGetters['config/locale'];
        const { appVersion } = this.app.context.env;

        const seoDataUrl = getSeoDataUrl(process.server, this.app.$config.seoLinksEndpoint);

        try {
            if (!SUPPORTED_LOCALES.includes(locale)) {
                return {};
            }

            const localeSeoDataResponse = await fetch(`${seoDataUrl}/${locale}?v=${appVersion}`);

            const { ok } = localeSeoDataResponse;

            if (!ok) {
                throw new Error('Fetching seo data error ocurred');
            }

            const localSeoData = await localeSeoDataResponse.json();

            if (localSeoData && Object.keys(localSeoData).length) {
                commit(types.SET_SEO_DATA, localSeoData);

                this.app.$performanceRecorder.end(GET_SEO_DATA);

                return localSeoData;
            }

            return {};
        } catch (error) {
            this.app.$errorHandler.captureStoreError('seo', error, {
                [ERROR_ACTION_TAG_NAME]: 'seo.getSeoData',
            });

            return {};
        }
    },
};

export default actions;
