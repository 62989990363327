import FlagPL from '@core-static/icons/24px/flag-pl.svg?inline';
import FlagSK from '@core-static/icons/24px/flag-sk.svg?inline';
import FlagCZ from '@core-static/icons/24px/flag-cz.svg?inline';
import FlagRO from '@core-static/icons/24px/flag-ro.svg?inline';
import FlagHU from '@core-static/icons/24px/flag-hu.svg?inline';
import FlagBG from '@core-static/icons/24px/flag-bg.svg?inline';
import FlagGR from '@core-static/icons/24px/flag-gr.svg?inline';
import FlagDE from '@core-static/icons/24px/flag-de.svg?inline';
import FlagFR from '@core-static/icons/24px/flag-fr.svg?inline';
import FlagLT from '@core-static/icons/24px/flag-lt.svg?inline';
import FlagIT from '@core-static/icons/24px/flag-it.svg?inline';
import FlagHR from '@core-static/icons/24px/flag-hr.svg?inline';
import FlagUA from '@core-static/icons/24px/flag-ua.svg?inline';
import FlagLV from '@core-static/icons/24px/flag-lv.svg?inline';
import FlagSI from '@core-static/icons/24px/flag-si.svg?inline';
import FlagAT from '@core-static/icons/24px/flag-at.svg?inline';
import FlagEE from '@core-static/icons/24px/flag-ee.svg?inline';

export default {
    FlagPL,
    FlagSK,
    FlagCZ,
    FlagRO,
    FlagHU,
    FlagBG,
    FlagGR,
    FlagDE,
    FlagFR,
    FlagLT,
    FlagIT,
    FlagHR,
    FlagUA,
    FlagLV,
    FlagSI,
    FlagAT,
    FlagEE,
};
