<template>
    <div class="copyright">
        {{ $t('Copyright') }}
        <SvgIcon width="16px" height="16px" class="icon">
            <CopyrightIcon />
        </SvgIcon>
        {{ content }}
        {{ year }}
    </div>
</template>

<script>
import CopyrightIcon from '@static/icons/16px/copyright.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'Copyright',

    components: {
        SvgIcon,
        CopyrightIcon,
    },

    props: {
        content: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.year = new Date().getFullYear();
    },
};
</script>

<style lang="scss" scoped>
.copyright {
    @apply flex justify-center items-center text-dark leading-new-s;

    .icon {
        @apply mx-1;
    }
}
</style>
