<template>
    <div class="expand-collapse">
        <div
            v-if="showExpandHeader"
            class="header"
            :class="{
                'is-desktop-expand': !isDesktopExpand,
            }"
            @click="changeState()"
        >
            <TextButton>
                <BaseHeading :theme="headerTheme" class="heading">
                    <slot name="header" />
                </BaseHeading>
            </TextButton>

            <template v-if="showExpandIcon">
                <ButtonIcon v-if="!isExpand" class="icon">
                    <slot name="expand-icon">
                        <SvgIcon width="16px" height="16px"><Plus3 /></SvgIcon>
                    </slot>
                </ButtonIcon>
                <ButtonIcon v-else class="icon">
                    <slot name="collapse-icon">
                        <SvgIcon width="16px" height="16px"><Minus2 /></SvgIcon>
                    </slot>
                </ButtonIcon>
            </template>
        </div>

        <transition name="expand">
            <div
                class="content"
                :class="{
                    'is-expand': isExpand,
                    'is-desktop-expand': isDesktopExpand,
                }"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
import { VALID_HEADING_TYPES } from '@configs/headings';

import Plus3 from '@static/icons/16px/plus3.svg?inline';
import Minus2 from '@static/icons/16px/minus2.svg?inline';

import TextButton from '@atoms/TextButton/TextButton';
import ButtonIcon from '@atoms/ButtonIcon/ButtonIcon';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'ExpandCollapse',

    components: {
        TextButton,
        ButtonIcon,
        BaseHeading,
        SvgIcon,
        Plus3,
        Minus2,
    },

    props: {
        isDesktopExpand: {
            type: Boolean,
            default: true,
        },

        isExpandDefault: {
            type: Boolean,
            default: false,
        },

        showExpandIcon: {
            type: Boolean,
            default: true,
        },

        showExpandHeader: {
            type: Boolean,
            default: true,
        },

        headerTheme: {
            type: String,
            default: 'h5',
            validator: value => VALID_HEADING_TYPES.includes(value),
        },
    },

    data() {
        return {
            isExpand: false,
        };
    },

    created() {
        this.isExpand = this.isExpandDefault;
    },

    methods: {
        changeState() {
            this.isExpand = !this.isExpand;
            this.$emit('expand-change', this.isExpand);
        },
    },
};
</script>

<style lang="scss" scoped>
.expand-collapse {
    @apply flex flex-col w-auto;

    .header {
        @apply flex flex-row justify-between;
    }
    .content {
        @apply hidden;

        &.is-expand {
            @apply block;
        }
    }

    @screen lg {
        .header {
            .icon {
                @apply hidden;
            }

            &:deep() .text-button {
                @apply cursor-default;
            }

            &.is-desktop-expand {
                .icon {
                    @apply block;
                }

                &:deep() .text-button {
                    @apply cursor-pointer;
                }
            }
        }
        .content {
            &.is-desktop-expand {
                @apply block mt-4;
            }
        }
    }
}

.expand-enter-active,
.expand-leave-active {
    transition: auto 1s ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>
