import CatalogProduct from '@search/models/CatalogProduct';

export default async function normalizeProduct(
    product,
    { createProductPath, imaginator, abTests, dateHelper, t, localeConfigByKey },
    { locale, currency, storeViewTimezone }
) {
    const normalizedProduct = { ...product };

    const productModel = new CatalogProduct(
        normalizedProduct,
        locale,
        currency,
        storeViewTimezone,
        createProductPath,
        imaginator,
        abTests,
        dateHelper,
        t,
        localeConfigByKey
    );

    const details = await productModel.buildDetails();

    return details;
}
