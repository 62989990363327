<template>
    <div
        class="container-content"
        :class="{
            _full: fullWidth,
            _fullonmobile: fullWidthOnMobile,
            _nogutters: noGutters,
            _nopaddings: noPaddings,
            _alignleft: alignLeft,
            _cmspadding: cmsPadding,
        }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ContainerContent',

    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },

        fullWidthOnMobile: {
            type: Boolean,
            default: false,
        },

        noPaddings: {
            type: Boolean,
            default: false,
        },

        noGutters: {
            type: Boolean,
            default: false,
        },

        alignLeft: {
            type: Boolean,
            default: false,
        },

        cmsPadding: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.container-content {
    @apply flex flex-wrap justify-center w-full px-3 mx-auto;
    max-width: 1596px;

    &._full {
        @apply px-0;
        max-width: 100%;
    }

    &._fullonmobile {
        @apply px-0;
        max-width: 100%;
    }

    &._nopaddings {
        @apply px-0;
    }

    &._nogutters {
        margin-right: -16px;
        margin-left: -16px;
    }

    &._alignleft {
        @apply justify-start;
    }

    @screen lg {
        &._fullonmobile {
            @apply px-3;
        }

        &._cmspadding {
            @apply px-5;
        }
    }
}
</style>
