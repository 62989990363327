export const PSB_PROMO_ACTION_REDIRECT = 'psb-promo-action-redirect';
export const PSB_MARKETING_PROMO_SLIDER = 'psb_marketing_promo_slider';
export const PSB_RETURN_POLICY = 'psb_return_policy';
export const PSB_BRAND_SHOP = 'psb_brand_shop';
export const PSB_PAYMENT_WIDGET = 'psb_payment_widget';

export const MAX_BADGES_TO_DISPLAY = 2;

export const PRODUCT_SLIDER_SLIDE_ATTRIBUTE = 'data-product-slider-slide';

export const PRODUCT_ONE_SIZE_LABEL = 'One Size';

export const VIDEO_GALLERY_POSITION = 1;

export const GOOD_PRICE_ENABLED_LOCALES = ['pl_PL', 'sk_SK'];
