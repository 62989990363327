import { createDefaultGlobalConfig, createDefaultHelpCenterConfig } from './state';

export const types = {
    SET_STORE_VIEWS: 'SET_STORE_VIEWS',
    SET_STORE_VIEW: 'SET_STORE_VIEW',
    SET_BASE_URL: 'SET_BASE_URL',
    SET_COOKIES: 'SET_COOKIES',
    SET_LOCALE_CONFIG: 'SET_LOCALE_CONFIG',
    SET_GLOBAL_CONFIG: 'SET_GLOBAL_CONFIG',
    SET_HELP_CENTER_CONFIG: 'SET_HELP_CENTER_CONFIG',
    SET_GOOGLE_ANALYTICS_CONFIG: 'SET_GOOGLE_ANALYTICS_CONFIG',
};

export default {
    [types.SET_STORE_VIEWS](state, storeViews) {
        state.storeViews = storeViews;
    },

    [types.SET_STORE_VIEW](state, storeView) {
        state.storeView = storeView;
    },

    [types.SET_BASE_URL](state, baseUrl) {
        state.baseUrl = baseUrl;
    },

    [types.SET_COOKIES](state, cookies) {
        state.cookies = cookies;
    },

    [types.SET_LOCALE_CONFIG](state, localeConfig) {
        state.localeConfig = localeConfig;
    },

    [types.SET_GLOBAL_CONFIG](state, config) {
        state.globalConfig = {
            ...createDefaultGlobalConfig(),
            ...config,
        };
    },

    [types.SET_HELP_CENTER_CONFIG](state, config) {
        state.helpCenterConfig = {
            ...createDefaultHelpCenterConfig(),
            ...config,
        };
    },

    [types.SET_GOOGLE_ANALYTICS_CONFIG](state, config) {
        state.googleAnalyticsConfig = config;
    },
};
