import { v4 as uuid } from 'uuid';
import { createNamespacedHelpers } from 'vuex';

import registerHeaderStoreModule from '@header/store/helpers/registerHeaderStoreModule';
import { CATALOG_PAGE_NAME } from '@search/routing/names';

import { USER_FIRST_VISIT_KEY } from '@configs/storage';

import TitleBaseDomain from '@mixins/TitleBaseDomain';
import WithCanonicalUrl from '@mixins/WithCanonicalUrl';
import Optimizely from '@mixins/Optimizely';

import { formatDate } from '@assets/date';

import OgImage from '@static/img/og-logo-modivo.jpg';

const { mapState: mapConfigState, mapGetters: mapConfigGetters } = createNamespacedHelpers(
    'config'
);

const { mapState: mapStateLayout } = createNamespacedHelpers('layout');

const { mapActions: mapActionsMessages } = createNamespacedHelpers('messages');

export default {
    mixins: [WithCanonicalUrl, TitleBaseDomain, Optimizely],

    middleware: [
        registerHeaderStoreModule,
        'global/set-error-handler-page-context',
        'global/normalized-path-redirect',
    ],

    data() {
        return {
            offlineMessageId: null,
        };
    },

    computed: {
        ...mapStateLayout(['htmlClasses']),
        ...mapConfigState(['baseUrl']),
        ...mapConfigGetters(['locale']),

        pageUrl() {
            return this.$absoluteUrl(this.$route.fullPath);
        },

        ogLogoPath() {
            return this.baseUrl + OgImage;
        },

        languageCode() {
            return this.locale.slice(0, 2);
        },
    },

    methods: {
        ...mapActionsMessages(['addInfoMessage', 'removeMessage']),

        captureFirstVisitData() {
            const firstVisitDate = this.$storage.getItem(USER_FIRST_VISIT_KEY);

            if (!firstVisitDate) {
                this.$storage.setItem(USER_FIRST_VISIT_KEY, formatDate(new Date()));
            }
        },

        isOffline(offline) {
            if (offline) {
                this.offlineMessageId = uuid();

                this.addInfoMessage({
                    text: this.$t(
                        // eslint-disable-next-line max-len
                        'You are currently offline. Please check your internet connection and try again.'
                    ),
                    timeout: false,
                    messageId: this.offlineMessageId,
                    handler: () => window.location.reload(),
                    label: this.$t('Reconnect'),
                });
            } else if (this.offlineMessageId !== null) {
                this.removeMessage(this.offlineMessageId);
                this.offlineMessageId = null;
            }
        },
    },

    beforeCreate() {
        registerHeaderStoreModule({ store: this.$store });
    },

    beforeMount() {
        this.captureFirstVisitData();
    },

    watch: {
        '$nuxt.isOffline': 'isOffline',
    },

    head() {
        const { baseUrl, titleBaseDomain } = this;

        const script = [
            {
                hid: 'ldjson-schema-organization',
                innerHTML: JSON.stringify({
                    '@context': 'http://schema.org/',
                    '@type': 'WebSite',
                    name: titleBaseDomain,
                    url: baseUrl,
                    sameAs: [
                        'https://www.youtube.com/channel/UCbmw7rr86pVh6197n0upy2g',
                        'https://www.facebook.com/modivopl/',
                        'https://www.instagram.com/modivo/',
                        'https://www.linkedin.com/company/eobuwie-pl-sa/',
                    ],
                    potentialAction: {
                        '@type': 'SearchAction',
                        target: `${baseUrl}${decodeURIComponent(this.$createSearchPath('{q}'))}`,
                        'query-input': 'required name=q',
                    },
                }),
                type: 'application/ld+json',
            },
        ];

        let canonicalLink = [
            {
                hid: 'canonical',
                rel: 'canonical',
                href: this.canonicalUrl,
            },
        ];

        if (this.$route.name === CATALOG_PAGE_NAME) {
            canonicalLink = [];
        }

        const link = [
            ...canonicalLink,
            {
                hid: 'manifest',
                rel: 'manifest',
                href: `${process.env.assetsPath}manifest.${this.locale}.json`,
                crossorigin: 'use-credentials',
            },
        ];

        if (this.isOptimizelyEnabled) {
            script.push({
                src: this.optimizelyScriptUrl,
                defer: true,
            });

            link.push({ rel: 'preconnect', href: '//cdn.optimizely.com' });
        }

        return {
            titleTemplate: titleChunk =>
                titleChunk ? `${titleChunk} | ${titleBaseDomain}` : titleBaseDomain,
            link,
            meta: [
                {
                    hid: 'og:url',
                    property: 'og:url',
                    content: this.pageUrl,
                },
                {
                    hid: 'og:site_name',
                    property: 'og:site_name',
                    content: titleBaseDomain,
                },
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: this.ogLogoPath,
                },
                {
                    hid: 'og:image:secure_url',
                    property: 'og:image:secure_url',
                    content: this.ogLogoPath,
                },
                {
                    hid: 'og:image:width',
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    hid: 'og:image:height',
                    property: 'og:image:height',
                    content: '630',
                },
                {
                    hid: 'og:image:alt',
                    property: 'og:image:alt',
                    content: titleBaseDomain,
                },
                {
                    hid: 'og:image:type',
                    property: 'og:image:type',
                    content: 'image/jpg',
                },
                {
                    hid: 'og:locale',
                    property: 'og:locale',
                    content: this.locale,
                },
            ],
            htmlAttrs: {
                class: this.htmlClasses.join(' '),
                lang: this.languageCode,
            },
            script,
            __dangerouslyDisableSanitizersByTagID: {
                'ldjson-schema-organization': ['innerHTML'],
            },
        };
    },
};
