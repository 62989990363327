<template>
    <ContainerContent class="copyright-footer">
        <Copyright :content="COPYRIGHT_CONTENT" />
    </ContainerContent>
</template>

<script>
import { COPYRIGHT_CONTENT } from '@configs/footer';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import Copyright from '@molecules/Copyright/Copyright';

export default {
    name: 'CopyrightFooter',

    components: {
        ContainerContent,
        Copyright,
    },

    beforeCreate() {
        this.COPYRIGHT_CONTENT = COPYRIGHT_CONTENT;
    },
};
</script>

<style lang="scss" scoped>
.copyright-footer {
    @apply border-t-1 py-7 border-border;

    @screen lg {
        @apply justify-start;
    }
}
</style>
