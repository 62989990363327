<template>
    <div class="dropdown" :class="{ [size]: true, 'has-error': hasError, success: isValid }">
        <select
            v-if="isSelect"
            ref="select"
            :value="value"
            :disabled="disabled"
            @blur="onBlur($event)"
            @focus="onFocus($event)"
            @change="onChange($event)"
            @keyup.enter="enterKeyup($event)"
        >
            <option
                v-for="option in nativeSelectOptions"
                :key="option.id"
                :value="option.value"
                :data-test-id="`${DROPDOWN_OPTION}_option_${option.id}`"
            >
                {{ option.label }}
            </option>
        </select>

        <WithClickOutsideDetection :handler="opened ? 'close' : null">
            <div
                class="select"
                :class="{
                    focused,
                    opened,
                    up: directionUp,
                    disabled,
                    'with-label': $slots.label,
                }"
            >
                <div class="selected">
                    <button
                        type="button"
                        class="toggle"
                        :tabindex="tabindex"
                        v-bind="attrs"
                        :disabled="disabled"
                        :data-test-id="DROPDOWN_SELECTED_BUTTON"
                        @click="toggle($event)"
                        v-on="selectedListeners"
                    >
                        <span v-if="$slots.label" class="label">
                            <!--
                            @slot Label slot for custom text to display
                            on the top of selected option
                        -->
                            <slot name="label" />
                        </span>
                        <span class="selected-text">
                            <!--
                            @slot Selected slot for displaying custom selected element or text
                        -->
                            <slot name="selected">{{ $t('Select') }}</slot>
                            <span class="icons">
                                <SvgIcon v-if="opened" width="16px" height="16px">
                                    <Arrow1Up />
                                </SvgIcon>
                                <SvgIcon v-else width="16px" height="16px">
                                    <Arrow1Down />
                                </SvgIcon>
                            </span>
                        </span>
                    </button>
                </div>
                <ul v-if="optionsLoaded" v-show="opened" class="options">
                    <li v-if="$slots.beforeOptions">
                        <!--
                        @slot beforeOptions slot for displaying custom element before options
                    -->
                        <slot name="beforeOptions" />
                    </li>
                    <template v-if="!areOptionsGrouped">
                        <div class="options-container">
                            <li
                                v-for="(option, index) in options"
                                :key="option.id"
                                class="option"
                                :class="{
                                    'currently-selected': isTempSelected(index),
                                }"
                                :data-test-id="`${DROPDOWN_OPTION}_li_${option.id}`"
                                @click="selectOption(option)"
                                @mouseenter="setTempSelected(index)"
                            >
                                <!--
                        @slot Option scoped slot for displaying option element
                    -->
                                <slot name="option" :option="option">
                                    {{ option.label }}
                                </slot>
                            </li>
                        </div>
                    </template>
                    <template v-else>
                        <li v-for="group in options" :key="group.id" class="group">
                            <ul>
                                <li class="option group">
                                    <slot name="group" :group="group">
                                        {{ group.label }}
                                    </slot>
                                </li>
                                <li
                                    v-for="(option, index) in group.options"
                                    :key="option.id"
                                    class="option"
                                    :class="{
                                        'currently-selected': isTempSelected(
                                            `${group.id}-${index}`
                                        ),
                                    }"
                                    :data-test-id="`${DROPDOWN_OPTION}_${option.id}`"
                                    @click="selectOption(option)"
                                    @mouseenter="setTempSelected(`${group.id}-${index}`)"
                                >
                                    <!--
                                    @slot Option scoped slot for displaying option element
                                -->
                                    <slot name="option" :option="option">
                                        {{ option.label }}
                                    </slot>
                                </li>
                            </ul>
                        </li>
                    </template>
                    <!--
                    @slot afterOptions slot for displaying custom element after options
                -->
                    <li v-if="$slots.afterOptions">
                        <slot name="afterOptions" />
                    </li>
                </ul>
            </div>
        </WithClickOutsideDetection>
        <div v-if="error" class="error">
            <span class="error-msg">{{ error }}</span>
        </div>
    </div>
</template>

<script>
import { SIZES } from '@types/Dropdown';

import { DROPDOWN_SELECTED_BUTTON, DROPDOWN_OPTION } from '@types/AutomaticTestIDs';

import Arrow1Up from '@static/icons/16px/arrow1-up.svg?inline';
import Arrow1Down from '@static/icons/16px/arrow1-down.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

export default {
    name: 'Dropdown',

    components: {
        WithClickOutsideDetection,
        SvgIcon,
        Arrow1Up,
        Arrow1Down,
    },

    inheritAttrs: false,

    props: {
        /**
         * Array of select/custom dropdown options
         */
        options: {
            type: Array,
            required: true,
        },

        /**
         * @model
         */
        value: {
            type: [Array, String, Number, Object, Boolean],
            required: true,
        },

        size: {
            type: String,
            default: SIZES.SIZE_BIG,
            validator: value => Object.values(SIZES).includes(value),
        },

        closeAfterSelect: {
            type: Boolean,
            default: true,
        },

        /**
         * Flag for modifying dropdown structure to create native html select
         */
        isSelect: {
            type: Boolean,
            default: false,
        },

        /**
         * Display dropdown options as groups
         * WARNING: need to pass different data model as options
         * options: Array<Group>
         * Group: { id: Number|String, label: String, options: Array<Option> }
         */
        areOptionsGrouped: {
            type: Boolean,
            default: false,
        },

        error: {
            type: String,
            default: '',
        },

        valid: {
            default: null,
            validator: prop => typeof prop === 'boolean' || prop === null,
        },

        directionUp: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            optionsLoaded: false,
            opened: false,
            focused: false,
            tempSelectedId: null,
        };
    },

    computed: {
        nativeSelectOptions() {
            const nativeOptions = this.options;

            if (!this.areOptionsGrouped) {
                return nativeOptions;
            }

            return this.options.reduce((flatten, group) => {
                return [...flatten, ...group.options];
            }, []);
        },

        disabled() {
            return this.$attrs.disabled || null;
        },

        attrs() {
            const { disabled, ...res } = this.$attrs;

            return res;
        },

        tabindex() {
            return this.isSelect ? '-1' : '0';
        },

        selectedListeners() {
            if (this.isSelect) {
                return null;
            }

            const { focus, blur, ...res } = this.$listeners;

            return {
                focus: this.onFocus,
                blur: this.onBlur,
                ...res,
            };
        },

        hasError() {
            return !!this.error.length;
        },

        isValid() {
            return this.valid && !this.hasError;
        },
    },

    beforeCreate() {
        this.DROPDOWN_SELECTED_BUTTON = DROPDOWN_SELECTED_BUTTON;
        this.DROPDOWN_OPTION = DROPDOWN_OPTION;
    },

    methods: {
        onChange(ev) {
            /**
             * Select event.
             *
             * @type {String|Number}
             */
            this.$emit('input', ev.target.value);
        },

        onBlur(ev) {
            this.focused = false;

            /**
             * Dropdown event.
             *
             * @type {Event}
             */
            this.$emit('blur', ev);
        },

        onFocus(ev) {
            this.focused = true;

            /**
             * Dropdown event.
             *
             * @type {Event}
             */
            this.$emit('focus', ev);
        },

        enterKeyup(ev) {
            ev.stopPropagation();

            if (this.focused) {
                this.toggle();
            }
        },

        selectOption(option) {
            if (this.closeAfterSelect) {
                this.close();
            }

            const valueToEmit = this.isSelect ? option.value : option;

            /**
             * Dropdown select event.
             *
             * @type {String|Number|Object|Array}
             */
            this.$emit('input', valueToEmit);
        },

        toggle() {
            if (this.opened) {
                this.close();
            } else {
                this.open();
            }
        },

        open() {
            if (!this.optionsLoaded) {
                this.optionsLoaded = true;
            }

            if (this.isSelect && this.$refs.select) {
                this.$refs.select.focus();
            }

            this.opened = true;
            this.focused = true;

            /**
             * Dropdown open event.
             *
             * @type {Void}
             */
            this.$emit('open');
        },

        close() {
            this.opened = false;

            this.tempSelectedId = null;

            /**
             * Dropdown close event.
             *
             * @type {Void}
             */
            this.$emit('close');
        },

        isTempSelected(id) {
            return this.tempSelectedId !== null && this.tempSelectedId === id;
        },

        setTempSelected(id) {
            this.tempSelectedId = id;
        },
    },
};
</script>

<style lang="scss" scoped>
$errorHeight: #{theme('lineHeight.s')};
$optionsOffset: #{theme('spacing.2')};

.dropdown {
    @apply relative text-r leading-r;

    &.small {
        @apply inline-block;

        .select {
            &::after {
                @apply hidden;
            }
        }
    }

    &.has-error {
        select {
            height: calc(100% - #{$errorHeight});
        }

        .select {
            &.opened {
                .options {
                    top: calc(100% - #{theme('spacing.7')} - #{$optionsOffset} - #{$errorHeight});
                }
            }
        }
    }

    select {
        @apply absolute top-0 left-0 h-full w-full opacity-0;

        &:focus {
            + .select:not(.opened) {
                &::after {
                    @apply border-focus shadow-7;
                }
            }
        }
    }

    .select {
        &.focused:not(.opened) {
            &::after {
                @apply border-focus shadow-7;
            }
        }

        &::after {
            @apply block border-0 border-solid border-b-1 border-gray2 w-full;
            content: '';
            height: 1px;
        }

        &:hover {
            &:not(.opened) {
                .toggle {
                    .selected-text {
                        @apply text-primary;
                    }

                    &:deep() .svg-icon {
                        stroke: theme('colors.primary');
                    }
                }

                &::after {
                    @apply border-primary;
                }
            }
        }

        &.with-label.disabled,
        &.disabled {
            &:hover::after,
            &::after {
                @apply border-gray4;
            }

            .label {
                @apply text-gray2;
            }

            .selected {
                .toggle {
                    @apply cursor-default;

                    .selected-text {
                        @apply text-gray3;
                    }

                    &:deep() .svg-icon {
                        stroke: theme('colors.gray3');
                    }
                }
            }
        }

        &.opened {
            @apply z-2;

            &::after {
                @apply border-transparent;
            }

            .selected {
                @apply relative w-full z-2;

                .toggle {
                    .selected-text {
                        @apply text-dark;
                    }
                }
            }

            .options {
                @apply absolute border-2 border-border px-4 bg-light z-1 pb-3;
                min-width: calc(100% + #{theme('spacing.7')});
                padding-top: calc(#{theme('spacing.7')} + #{$optionsOffset});
                top: calc(100% - #{theme('spacing.7')} - #{$optionsOffset});
                left: calc(-1 * #{theme('spacing.4')});

                .option {
                    @apply flex items-center h-7 cursor-pointer;

                    &.currently-selected,
                    &.group {
                        margin: 0 calc(-1 * #{theme('spacing.4')});
                    }

                    &.currently-selected {
                        @apply text-dark bg-gray5 px-4;
                        margin: 0 calc(-1 * #{theme('spacing.4')});
                    }

                    &.group {
                        @apply border-t-1 border-b-1 border-border px-4 font-semibold text-s;
                    }
                }
            }

            &.up {
                .options {
                    @apply bottom-[-8px] left-[-26px] pt-0 pb-ui-9 top-auto;
                }
            }
        }

        &.with-label {
            &:hover {
                &:not(.opened):not(.disabled) {
                    .selected {
                        .toggle {
                            .selected-text {
                                @apply text-primary;
                            }
                        }
                    }
                }
            }

            &.opened {
                .label {
                    @apply hidden;
                }
            }

            .label {
                @apply text-xs;
            }

            .selected {
                .toggle {
                    .selected-text {
                        @apply text-dark;
                    }
                }
            }
        }

        .selected {
            .toggle {
                @apply flex flex-col items-stretch h-7 w-full text-left justify-center;

                .label {
                    @apply leading-xs;
                }

                .selected-text {
                    @apply flex items-center justify-between text-gray2 w-full h-full;

                    &:deep() span:not(.icons) {
                        @apply truncate;
                    }
                }

                &:focus {
                    @apply outline-none;
                }

                .icons {
                    @apply ml-4 text-dark;
                }
            }
        }
    }

    .error {
        @apply text-xs leading-s text-error;
    }
}

.has-error {
    .select {
        &::after {
            @apply border-error;
        }
    }
}

.success {
    .select {
        &::after {
            @apply border-success;
        }
    }
}

@screen lg {
    .dropdown {
        select {
            @apply h-0 w-0;
        }

        .select {
            &.opened {
                .option {
                    @apply whitespace-nowrap;
                }
            }
        }
    }
}
</style>
