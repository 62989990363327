import { REDIRECT_HTTP_CODE } from '@configs/redirect';

import Redirector from '@modules/redirector/Redirector';

export default async function ({
    params,
    app,
    error,
    store,
    route: { path: currentUrl },
    redirect,
}) {
    let { slug } = params;

    slug = slug || '/';

    const locale = store.getters['config/locale'];

    const staticRedirects = Redirector.getRedirectsForLocale(locale);
    const staticRedirect = staticRedirects?.[decodeURI(currentUrl)];

    if (staticRedirect) {
        return redirect(REDIRECT_HTTP_CODE, staticRedirect);
    }

    const urlResolver = await app.$services.global.resolveUrl(slug);

    if (urlResolver) {
        const { type, id } = urlResolver;

        store.dispatch('page/setPageData', {
            id,
            pageType: type,
        });

        return;
    }

    store.dispatch('page/setPageData', {
        id: null,
        pageType: null,
    });

    error({
        statusCode: 404,
        message: 'Page does not exist',
    });
}
