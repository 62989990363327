import { IS_OMNIBUS_STRICT_ENABLED, IS_REGULAR_PRICE_ENABLED } from '@localeConfig/keys';

const PRICE_TYPE_MINIMAL = 'minimal';

export const getPriceFromPricingData = (
    {
        discount,
        final_price: promotionalPrice,
        price: regularPrice,
        omnibus_price: omnibusPrice,
        omnibus_discount: omnibusDiscount,
        presentation_settings: presentationSettings,
        market_type: marketType,
        on_sale: onSale,
    },
    hasMarketingBadge = false
) => {
    const { amount: regularAmount, formatted: regularFormatted } = regularPrice;
    const { amount: promotionalAmount, formatted: promotionalFormatted } = promotionalPrice;
    const { amount: omnibusAmount = null, formatted: omnibusFormatted = null } = omnibusPrice || {};

    const isMinimal = promotionalPrice.type === PRICE_TYPE_MINIMAL;

    const {
        show_omnibus_price: showOmnibusPrice,
        show_regular_price: showRegularPrice,
    } = presentationSettings;

    const isOmnibusMarket = marketType.startsWith('OMNIBUS');
    const isOmnibusMarketWithRegularPrice = marketType === 'OMNIBUS_WITH_REGULAR_PRICE';

    const arePricesTheSame =
        regularAmount === promotionalAmount && omnibusAmount === promotionalAmount;

    const discountValue = parseInt(discount.amount, 10) > 0 ? `${discount.amount}%` : null;
    const omnibusDiscountValue =
        parseInt(omnibusDiscount?.amount, 10) > 0 ? `${omnibusDiscount?.amount}%` : null;

    let showRegular;
    let showOmnibus;
    let isOnSale = onSale;

    if (isMinimal) {
        showRegular = false;
        showOmnibus = false;
        isOnSale = false;
    } else {
        showRegular =
            (hasMarketingBadge && isOmnibusMarketWithRegularPrice && !arePricesTheSame) ||
            showRegularPrice;

        showOmnibus = hasMarketingBadge
            ? isOmnibusMarket && !!omnibusAmount && !arePricesTheSame
            : !!showOmnibusPrice;
    }

    return {
        regular: {
            amount: regularAmount,
            formatted: regularFormatted,
            show: showRegular,
        },
        promotional: {
            amount: promotionalAmount,
            formatted: promotionalFormatted,
        },
        omnibus: {
            amount: omnibusAmount,
            formatted: omnibusFormatted,
            show: showOmnibus,
        },
        discount: isOmnibusMarket ? omnibusDiscountValue : discountValue,
        discountRegular: discountValue,
        isMinimal,
        isOnSale,
    };
};

export const getPriceFromPricingDataButLocalConfig = (
    {
        discount,
        final_price: promotionalPrice,
        price: regularPrice,
        omnibus_price: omnibusPrice,
        omnibus_discount: omnibusDiscount,
    },
    $localeConfig,
    hasMarketingBadge = false,
    showOmnibusOnGoodPrice = false
) => {
    const { amount: regularAmount, formatted: regularFormatted } = regularPrice;
    const { amount: promotionalAmount, formatted: promotionalFormatted } = promotionalPrice;
    const { amount: omnibusAmount = null, formatted: omnibusFormatted = null } = omnibusPrice || {};

    const isMinimal = promotionalPrice.type === PRICE_TYPE_MINIMAL;

    const isOmnibusMarket = $localeConfig(IS_OMNIBUS_STRICT_ENABLED);
    const isOmnibusMarketWithRegularPrice =
        isOmnibusMarket && $localeConfig(IS_REGULAR_PRICE_ENABLED);

    const arePricesTheSame =
        regularAmount === promotionalAmount && omnibusAmount === promotionalAmount;

    const discountValue = parseInt(discount.amount, 10) > 0 ? `${discount.amount}%` : null;
    const omnibusDiscountValue =
        parseInt(omnibusDiscount?.amount, 10) > 0 ? `${omnibusDiscount?.amount}%` : null;

    const showOmnibusPrice =
        isOmnibusMarket && (!!omnibusDiscountValue || (!!discountValue && showOmnibusOnGoodPrice));

    const showRegularPrice = isOmnibusMarket
        ? showOmnibusPrice && isOmnibusMarketWithRegularPrice
        : !!discountValue;

    let showRegular;
    let showOmnibus;
    let isOnSale = isOmnibusMarket ? !!omnibusDiscountValue : !!discountValue;

    if (isMinimal) {
        showRegular = false;
        showOmnibus = false;
        isOnSale = false;
    } else {
        showRegular =
            (hasMarketingBadge && isOmnibusMarketWithRegularPrice && !arePricesTheSame) ||
            showRegularPrice;

        showOmnibus = hasMarketingBadge
            ? !arePricesTheSame && isOmnibusMarket && !!omnibusAmount
            : !!showOmnibusPrice;
    }

    return {
        regular: {
            amount: regularAmount,
            formatted: regularFormatted,
            show: showRegular,
        },
        promotional: {
            amount: promotionalAmount,
            formatted: promotionalFormatted,
        },
        omnibus: {
            amount: omnibusAmount,
            formatted: omnibusFormatted,
            show: showOmnibus,
        },
        discount: isOmnibusMarket ? omnibusDiscountValue : discountValue,
        discountRegular: discountValue,
        isMinimal,
        isOnSale,
    };
};
