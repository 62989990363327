import {
    bg_BG,
    cs_CZ,
    de_AT,
    de_DE,
    el_GR,
    // en_US,  // @todo fix config
    et_EE,
    fr_FR,
    hr_HR,
    hu_HU,
    it_IT,
    lt_LT,
    lv_LV,
    pl_PL,
    ro_RO,
    // ru_UA,  // @todo fix config
    sk_SK,
    sl_SI,
    uk_UA,
} from '@localeConfig/locales';

import { IS_HREFLANG_ALLOWED } from '@localeConfig/keys';

export const HOME_DECOR_CATEGORY_ID = '11252';
export const BEAUTY_WOMAN_CATEGORY_ID = '11053';
export const BEAUTY_MAN_CATEGORY_ID = '11194';

export default {
    [IS_HREFLANG_ALLOWED]: {
        type: Object,
        value: {
            [HOME_DECOR_CATEGORY_ID]: {
                [bg_BG]: false,
                [cs_CZ]: false,
                [de_AT]: false,
                [de_DE]: false,
                [el_GR]: false,
                // [en_US]: false, // @todo fix config
                [et_EE]: false,
                [fr_FR]: false,
                [hr_HR]: false,
                [hu_HU]: false,
                [it_IT]: true,
                [lt_LT]: false,
                [lv_LV]: false,
                [pl_PL]: true,
                [ro_RO]: false,
                // [ru_UA]: false,  // @todo fix config
                [sk_SK]: false,
                [sl_SI]: false,
                [uk_UA]: false,
            },
            [BEAUTY_MAN_CATEGORY_ID]: {
                [bg_BG]: false,
                [cs_CZ]: false,
                [de_AT]: false,
                [de_DE]: false,
                [el_GR]: false,
                // [en_US]: false,  // @todo fix config
                [et_EE]: false,
                [fr_FR]: false,
                [hr_HR]: false,
                [hu_HU]: false,
                [it_IT]: true,
                [lt_LT]: false,
                [lv_LV]: false,
                [pl_PL]: true,
                [ro_RO]: false,
                // [ru_UA]: false, // @todo fix config
                [sk_SK]: false,
                [sl_SI]: false,
                [uk_UA]: false,
            },
            [BEAUTY_WOMAN_CATEGORY_ID]: {
                [bg_BG]: false,
                [cs_CZ]: false,
                [de_AT]: false,
                [de_DE]: false,
                [el_GR]: false,
                // [en_US]: false,  // @todo fix config
                [et_EE]: false,
                [fr_FR]: false,
                [hr_HR]: false,
                [hu_HU]: false,
                [it_IT]: true,
                [lt_LT]: false,
                [lv_LV]: false,
                [pl_PL]: true,
                [ro_RO]: false,
                // [ru_UA]: false, // @todo fix config
                [sk_SK]: false,
                [sl_SI]: false,
                [uk_UA]: false,
            },
        },
    },
};
