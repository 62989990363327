import { LOCALE_CONFIG_KEYS_MAP, COMMON_LOCALE_CONFIG_KEYS_MAP } from '@localeConfig/keys';
import commonLocaleConfig from '@localeConfig/locales/common';

const getValuesForKeys = (keys, values) =>
    Object.fromEntries(keys.map(key => [key, values?.[key]?.value ?? null]));

const buildLocaleConfig = () => {
    let data = null;
    let currentLocale = null;

    return async locale => {
        if (typeof locale === 'undefined') {
            throw new Error('Locale is undefined.');
        }

        if (data !== null && currentLocale === locale) {
            return data;
        }

        currentLocale = locale;

        const { default: localeConfig } =
            (await import(
                /* webpackChunkName: "locale-config" */ `@localeConfig/locales/${locale}`
            )) || null;

        if (localeConfig === null) {
            throw new Error(`LocaleConfig for local ${locale} is not defined.`);
        }

        data = {
            ...getValuesForKeys(COMMON_LOCALE_CONFIG_KEYS_MAP, commonLocaleConfig),
            ...getValuesForKeys(LOCALE_CONFIG_KEYS_MAP, localeConfig),
        };

        return data;
    };
};

export const localeConfigBuilder = buildLocaleConfig();
