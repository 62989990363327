import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en_US from '@i18n/lang/en_US';
import fetch from 'isomorphic-fetch';

import { removeHostPrefix } from '@assets/removeHostPrefix';

import { DEFAULT_LOCALE_CODE } from '@configs/locale';

import { CORE_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

Vue.use(VueI18n);

const FALLBACK_LOCALE = {
    code: 'en',
    file: 'en_US.js',
    iso: 'en-US',
    domain: null,
};

function getCurrentLocaleName(locale, locales) {
    const localeObj = locales.find(localeItem => localeItem.code === locale);

    const [localeName] = localeObj?.file?.split('.') || [];

    if (!localeName) {
        throw new Error(`Locale code "${locale}" does not exists`);
    }

    return localeName;
}

function tPathKey(translationKey) {
    return this.t(`__paths.${translationKey}`);
}

function tPath(translationKey) {
    return `/${tPathKey.call(this, translationKey)}`;
}

function getTranslationUrl(isServer, endpoint) {
    if (isServer || typeof window === 'undefined') {
        return endpoint;
    }

    return `${window.location.origin}${endpoint}`;
}

const createI18nInstance = async ({
    locale = null,
    locales,
    translationUrl,
    errorHandler,
    appVersion,
}) => {
    const messages = {
        [FALLBACK_LOCALE.code]: en_US(),
    };

    if (locale !== FALLBACK_LOCALE.code) {
        let currentLocaleName = '';
        let status = null;
        let url = null;
        let ok = false;

        try {
            currentLocaleName = getCurrentLocaleName(locale, locales);

            const localeTranslationsResponse = await fetch(
                `${translationUrl}/${currentLocaleName}?v=${appVersion}`
            );

            ({ status, url, ok } = localeTranslationsResponse);

            if (!ok) {
                throw new Error('Fetching translations error ocurred');
            }

            const localTranslations = await localeTranslationsResponse.json();

            if (Object.keys(localTranslations).length) {
                messages[locale] = localTranslations;
            }
        } catch (error) {
            errorHandler.captureDomainError(
                CORE_ERROR_DOMAIN,
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'i18n',
                },
                {
                    locale,
                    currentLocaleName,
                    url,
                    status,
                }
            );
        }
    }

    const i18n = new VueI18n({
        locale: locale || FALLBACK_LOCALE.code,
        fallbackLocale: FALLBACK_LOCALE.code,
        messages,
    });

    i18n.locales = locales;
    i18n.tPathKey = tPathKey.bind(i18n);
    i18n.tPath = tPath.bind(i18n);

    return i18n;
};

export default async ({ app, env, $config, req }, inject) => {
    const { locales } = $config;

    const host = process.server ? req.headers.host : window.location.host;

    const hostWithoutPrefix = removeHostPrefix(host);

    const domainLocaleConfig = locales.find(config => {
        return config.domain && removeHostPrefix(config.domain) === hostWithoutPrefix;
    });

    const locale =
        domainLocaleConfig && domainLocaleConfig.code
            ? domainLocaleConfig.code
            : DEFAULT_LOCALE_CODE;

    const translationUrl = getTranslationUrl(process.server, $config.translationEndpoint);

    const i18n = await createI18nInstance({
        locale,
        locales,
        translationUrl,
        errorHandler: app.$errorHandler,
        appVersion: env.appVersion,
    });

    app.i18n = i18n;
    app.$t = i18n.t.bind(i18n);
    app.$tPath = i18n.tPath.bind(i18n);
    app.$tPathKey = i18n.tPathKey.bind(i18n);

    inject('tPath', app.$tPath);
    inject('tPathKey', app.$tPathKey);
};
