import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export const calculateAverageFromRatings = (ratingsToCalculate = []) => {
    const ratings = ratingsToCalculate || [];

    if (!Array.isArray(ratings) || ratings.length === 0) {
        return 0;
    }

    const average =
        ratings.reduce((total, rating) => {
            return total + rating.value;
        }, 0) / ratings.length;

    return Math.round(average * 10) / 10;
};

export const sortReviews = ({ reviews = [], locale }) => {
    const sortedByDate = Array.from(reviews).sort(
        ({ createdAt: reviewDateA }, { createdAt: reviewDateB }) => {
            if (reviewDateA === reviewDateB) {
                return 0;
            }

            return reviewDateA > reviewDateB ? -1 : 1;
        }
    );

    const { reviewsFull, reviewsWithoutConent } = sortedByDate.reduce(
        (acc, review) => {
            if (review.content) {
                acc.reviewsFull.push(review);
            } else {
                acc.reviewsWithoutConent.push(review);
            }

            return acc;
        },
        { reviewsFull: [], reviewsWithoutConent: [] }
    );

    // empty or null language is treated as current locale
    const currentLanguages = [locale, '', null];

    reviewsFull.sort((reviewA, reviewB) => {
        const isReviewAInCurrentLanguage = currentLanguages.includes(reviewA.originalLanguage);

        const isReviewBInCurrentLanguage = currentLanguages.includes(reviewB.originalLanguage);

        if (isReviewAInCurrentLanguage === isReviewBInCurrentLanguage) {
            return 0;
        }

        return isReviewAInCurrentLanguage ? -1 : 1;
    });

    return [...reviewsFull, ...reviewsWithoutConent];
};

export const loadReviews = async ({ app, sku, locale }) => {
    try {
        const { reviews } = await app.$services.product.getProductReviews({
            sku,
            locale,
        });

        const items = reviews?.items || [];
        const [shortLocale] = locale.split('_');
        const sortedReviews = sortReviews({ reviews: items, locale: shortLocale });

        return { ...reviews, items: sortedReviews } || {};
    } catch (error) {
        app.$errorHandler.captureError(error, {
            [ERROR_ACTION_TAG_NAME]: 'product.getProductReviews',
        });

        return {};
    }
};

export const isTrustmateProxyTestOn = abTests => {
    return abTests.getVariant('mod_all_pdp_trustmate_proxy') === 'on';
};
