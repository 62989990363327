<template>
    <div :class="{ 'second-stage': stickySecondStage && isMobile }" class="header-wrapper">
        <template v-if="!isWebViewMobile">
            <header class="header">
                <HeaderTopBar v-if="!isMobile" class="desktop-top-bar" />

                <HeaderMainBar
                    :class="{ 'hidden-state': stickySecondStage && isMobile }"
                    :placeholder="displayPlaceholderText"
                    class="main-bar"
                />

                <div class="search-button-wrapper">
                    <HeaderSearchButton :placeholder="displayPlaceholderText" />
                </div>

                <NavigationBar
                    :class="{ 'invisible-state': stickySecondStage || isHomePage }"
                    class="nav-bar"
                />

                <HeaderMainCategories v-if="isMainCategoryPage" class="main-cat" />

                <Divider :class="{ 'on-main-cat': isMainCategoryPage }" class="header-divider" />
            </header>

            <Scrim v-show="isDesktopMenuOpen" class="backdrop" />
        </template>
    </div>
</template>

<script>
import throttle from 'lodash.throttle';

import { createNamespacedHelpers, mapState } from 'vuex';

import { IS_NEW_SEARCH_PLACEHOLDER_ENABLED } from '@localeConfig/keys';

import { HOME_PAGE_NAME, MAIN_CATEGORY_PAGE_NAME } from '@router/names';

import HeaderMainCategories from '@header-atoms/HeaderMainCategories/HeaderMainCategories';

import HeaderTopBar from '@header-organisms/HeaderTopBar/HeaderTopBar';
import HeaderSearchButton from '@header-organisms/HeaderSearchButton/HeaderSearchButton';
import NavigationBar from '@header-organisms/NavigationBar/NavigationBar';
import HeaderMainBar from '@header-organisms/HeaderMainBar/HeaderMainBar';

import { Divider } from '@modivo-ui/components/Divider/v1';
import { Scrim } from '@modivo-ui/components/Scrim/v1';

const { mapGetters: mapNavigationGetters, mapState: mapNavigationState } = createNamespacedHelpers(
    'navigation'
);
const { mapState: mapAutocompleteState } = createNamespacedHelpers('header/autocomplete');

const SCROLL_POSITION_MARGIN = 100;

export default {
    name: 'HeaderWrapper',

    components: {
        HeaderTopBar,
        HeaderMainBar,
        HeaderSearchButton,
        HeaderMainCategories,
        Divider,
        NavigationBar,
        Scrim,
    },

    props: {
        isWebViewMobile: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            frameId: null,
            lastScrollPosition: 0,
            stickySecondStage: false,
            placeholderPhrases: [
                'Guess',
                'polo',
                'Tommy Hilfiger',
                'bikini',
                'len',
                'Calvin Klein',
                'espadryle',
                'Polo Ralph Lauren',
                'denim',
                'Birkenstock',
                'szorty',
                'Nike',
                'Pinko',
                'szopperka',
                'Adidas',
            ],
            currentWordIndex: 0,
            placeholderAnimationText: '',
            isAnimationActive: false,
            typingPlaceholderInterval: null,
            initPlaceholderTimeout: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen', 'isSearchInputActive']),
        ...mapNavigationGetters(['activeMainCategory', 'isNavigationScrimVisible']),
        ...mapAutocompleteState(['query']),

        isHomePage() {
            return this.$route.name === HOME_PAGE_NAME;
        },

        isMainCategoryPage() {
            return this.$route.name === MAIN_CATEGORY_PAGE_NAME;
        },

        displayPlaceholderText() {
            return !this.isSearchInputActive &&
                this.isAnimationActive &&
                this.$getLocaleConfigByKey(IS_NEW_SEARCH_PLACEHOLDER_ENABLED)
                ? this.placeholderAnimationText
                : this.$t('Search brand, product, style');
        },
    },

    watch: {
        isSearchInputActive(isSearchInputActive) {
            if (isSearchInputActive) {
                this.stopTypingEffect();
            } else if (!this.query && !this.isAnimationActive) {
                this.startTypingEffect();
            }
        },
        query(query) {
            if (query) {
                this.stopTypingEffect();
            } else if (!this.isSearchInputActive && !this.isAnimationActive) {
                this.startTypingEffect();
            }
        },
    },

    beforeMount() {
        this.handleScrollDelayed = throttle(this.handleScroll, 50);
        window.addEventListener('scroll', this.handleScrollDelayed);
    },

    beforeDestroy() {
        if (this.frameId) {
            window.cancelAnimationFrame(this.frameId);
        }

        this.handleScrollDelayed.cancel();
        window.removeEventListener('scroll', this.handleScrollDelayed);
        clearTimeout(this.initPlaceholderTimeout);
    },

    mounted() {
        this.initPlaceholderTimeout = setTimeout(() => {
            if (!this.isAnimationActive) {
                this.startTypingEffect();
            }
        }, 3000);
    },

    methods: {
        handleScroll() {
            if (this.frameId) {
                return;
            }

            this.frameId = window.requestAnimationFrame(() => {
                this.onScroll();
                this.frameId = null;
            });
        },

        onScroll() {
            const scrollPosition = window.scrollY;

            if (this.lastScrollPosition === 0) {
                this.lastScrollPosition = scrollPosition;

                return;
            }

            if (scrollPosition < this.lastScrollPosition - SCROLL_POSITION_MARGIN) {
                this.stickySecondStage = false;
            }

            if (scrollPosition >= this.lastScrollPosition + SCROLL_POSITION_MARGIN) {
                this.stickySecondStage = scrollPosition >= 200;
            }

            if (
                (!this.stickySecondStage && scrollPosition < this.lastScrollPosition) ||
                (this.stickySecondStage && scrollPosition > this.lastScrollPosition)
            ) {
                this.lastScrollPosition = scrollPosition;
            }
        },

        startTypingEffect() {
            if (!this.$getLocaleConfigByKey(IS_NEW_SEARCH_PLACEHOLDER_ENABLED)) {
                return;
            }

            const currentWord = this.placeholderPhrases[this.currentWordIndex];
            let characterIndex = 0;
            const currentWorldLength = currentWord.length;

            this.isAnimationActive = true;

            const addCharacter = () => {
                if (characterIndex < currentWorldLength) {
                    this.placeholderAnimationText += currentWord[characterIndex];
                    characterIndex += 1;
                    this.typingPlaceholderInterval = setTimeout(addCharacter, 100);
                } else {
                    this.typingPlaceholderInterval = setTimeout(this.startDeletingEffect, 800);
                }
            };

            addCharacter();
        },

        startDeletingEffect() {
            const currentWord = this.placeholderPhrases[this.currentWordIndex];
            let characterIndex = currentWord.length;

            const removeCharacter = () => {
                if (characterIndex > 0) {
                    this.placeholderAnimationText = this.placeholderAnimationText.slice(0, -1);
                    characterIndex -= 1;
                    this.typingPlaceholderInterval = setTimeout(removeCharacter, 50);
                } else {
                    this.currentWordIndex =
                        (this.currentWordIndex + 1) % this.placeholderPhrases.length;
                    this.typingPlaceholderInterval = setTimeout(this.startTypingEffect, 800);
                }
            };

            removeCharacter();
        },
        stopTypingEffect() {
            if (!this.$getLocaleConfigByKey(IS_NEW_SEARCH_PLACEHOLDER_ENABLED)) {
                return;
            }

            clearTimeout(this.typingPlaceholderInterval);
            this.placeholderAnimationText = '';
            this.currentWordIndex = 0;
            this.isAnimationActive = false;
        },
    },
};
</script>

<style scoped lang="scss">
.header-wrapper {
    transition: top 0.3s, opacity 0.5s;
    @apply sticky top-0 z-3;

    .header {
        @apply relative bg-ui-container-default-default;
        @apply z-3;
        @apply max-h-[160px];
    }

    .main-bar {
        @apply opacity-100;

        transition: top 0.3s, opacity 0.5s;
    }

    &.second-stage {
        @apply top-[-48px];
    }

    .hidden-state {
        @apply opacity-0;
        @apply pointer-events-none;
    }

    .backdrop {
        @apply z-2 fixed;
    }

    .desktop-top-bar {
        @apply hidden;
    }

    .search-button-wrapper {
        @apply px-ui-4;
    }

    .header-divider {
        @apply mt-ui-4;

        &.on-main-cat {
            @apply mt-0 relative top-[-2px];
        }
    }

    .nav-bar {
        @apply hidden;
    }

    .main-cat {
        @apply px-ui-4;
    }

    @screen lg {
        @apply top-[-48px];

        .header {
            @apply max-h-none;
        }

        .main-cat {
            @apply hidden;
        }

        .desktop-top-bar {
            @apply block;
        }

        .nav-bar {
            @apply block;
            transition: 0.3s;
        }

        .search-button-wrapper {
            @apply hidden;
        }

        .header-divider {
            @apply mt-0;

            &.on-main-cat {
                @apply static;
            }
        }

        .invisible-state {
            @apply opacity-0 h-ui-6;

            :deep(.navigation) {
                @apply pointer-events-none;
            }
        }
    }
}
</style>
