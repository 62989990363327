export const ASC = 'asc';
export const DESC = 'desc';
export const CREATED = 'created';
export const PRICE = 'price';
export const DEFAULT = 'default';

export const VALID_ORDERS = [CREATED, PRICE];
export const VALID_ORDER_DIRS = [ASC, DESC];
export const DEFAULT_ORDER_DIR = ASC;

export default {
    ASC,
    DESC,
};
