export const types = {
    SET_PAGE_TYPE: 'SET_PAGE_TYPE',
    SET_PAGE_ID: 'SET_PAGE_ID',
};

export default {
    [types.SET_PAGE_TYPE](state, pageType) {
        state.type = pageType;
    },
    [types.SET_PAGE_ID](state, id) {
        state.id = id;
    },
};
