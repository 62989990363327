var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:{ [_vm.size]: true, 'has-error': _vm.hasError, success: _vm.isValid }},[(_vm.isSelect)?_c('select',{ref:"select",attrs:{"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.onBlur($event)},"focus":function($event){return _vm.onFocus($event)},"change":function($event){return _vm.onChange($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterKeyup($event)}}},_vm._l((_vm.nativeSelectOptions),function(option){return _c('option',{key:option.id,attrs:{"data-test-id":`${_vm.DROPDOWN_OPTION}_option_${option.id}`},domProps:{"value":option.value}},[_vm._v("\n            "+_vm._s(option.label)+"\n        ")])}),0):_vm._e(),_vm._v(" "),_c('WithClickOutsideDetection',{attrs:{"handler":_vm.opened ? 'close' : null}},[_c('div',{staticClass:"select",class:{
                focused: _vm.focused,
                opened: _vm.opened,
                up: _vm.directionUp,
                disabled: _vm.disabled,
                'with-label': _vm.$slots.label,
            }},[_c('div',{staticClass:"selected"},[_c('button',_vm._g(_vm._b({staticClass:"toggle",attrs:{"type":"button","tabindex":_vm.tabindex,"disabled":_vm.disabled,"data-test-id":_vm.DROPDOWN_SELECTED_BUTTON},on:{"click":function($event){return _vm.toggle($event)}}},'button',_vm.attrs,false),_vm.selectedListeners),[(_vm.$slots.label)?_c('span',{staticClass:"label"},[_vm._t("label")],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"selected-text"},[_vm._t("selected",function(){return [_vm._v(_vm._s(_vm.$t('Select')))]}),_vm._v(" "),_c('span',{staticClass:"icons"},[(_vm.opened)?_c('SvgIcon',{attrs:{"width":"16px","height":"16px"}},[_c('Arrow1Up')],1):_c('SvgIcon',{attrs:{"width":"16px","height":"16px"}},[_c('Arrow1Down')],1)],1)],2)])]),_vm._v(" "),(_vm.optionsLoaded)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.opened),expression:"opened"}],staticClass:"options"},[(_vm.$slots.beforeOptions)?_c('li',[_vm._t("beforeOptions")],2):_vm._e(),_vm._v(" "),(!_vm.areOptionsGrouped)?[_c('div',{staticClass:"options-container"},_vm._l((_vm.options),function(option,index){return _c('li',{key:option.id,staticClass:"option",class:{
                                'currently-selected': _vm.isTempSelected(index),
                            },attrs:{"data-test-id":`${_vm.DROPDOWN_OPTION}_li_${option.id}`},on:{"click":function($event){return _vm.selectOption(option)},"mouseenter":function($event){return _vm.setTempSelected(index)}}},[_vm._t("option",function(){return [_vm._v("\n                                "+_vm._s(option.label)+"\n                            ")]},{"option":option})],2)}),0)]:_vm._l((_vm.options),function(group){return _c('li',{key:group.id,staticClass:"group"},[_c('ul',[_c('li',{staticClass:"option group"},[_vm._t("group",function(){return [_vm._v("\n                                    "+_vm._s(group.label)+"\n                                ")]},{"group":group})],2),_vm._v(" "),_vm._l((group.options),function(option,index){return _c('li',{key:option.id,staticClass:"option",class:{
                                    'currently-selected': _vm.isTempSelected(
                                        `${group.id}-${index}`
                                    ),
                                },attrs:{"data-test-id":`${_vm.DROPDOWN_OPTION}_${option.id}`},on:{"click":function($event){return _vm.selectOption(option)},"mouseenter":function($event){return _vm.setTempSelected(`${group.id}-${index}`)}}},[_vm._t("option",function(){return [_vm._v("\n                                    "+_vm._s(option.label)+"\n                                ")]},{"option":option})],2)})],2)])}),_vm._v(" "),(_vm.$slots.afterOptions)?_c('li',[_vm._t("afterOptions")],2):_vm._e()],2):_vm._e()])]),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"error"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.error))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }