import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
    MEGATRON_CUSTOMER_GET_CUSTOMER_ORDER_EXT_DATA_OPERATION_NAME,
} from '@configs/megatron-client';
import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { UNAUTHENTICATED } from '@types/GraphQLErrorCodes';

import { getFirstErrorCode } from '@assets/megatron-client/graphql-errors-helper';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.customer.${action}`,
});

const addStoreCodePrefix = (storeCode, url) => {
    if (storeCode !== 'default') {
        return `${storeCode}/${url}`;
    }

    return url;
};

export default ({ megatronClient, normalizeMegatronResponse, $errorHandler, helpers }) => ({
    async generateAuthToken(email, password) {
        const response = tokens => {
            const {
                access_token: accessToken = null,
                refresh_token: refreshToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthToken');

        try {
            const { data, error, status } = await megatronClient.post('v1/generateThrudToken', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    email,
                    password,
                },
            });

            if (!error) {
                return response(data?.generateThrudToken);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async refreshAuthToken(refreshToken) {
        const response = (tokens, success = false) => {
            const {
                access_token: accessToken = null,
                refresh_token: newRefreshToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken: newRefreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                success,
            };
        };
        const ERROR_TAGS = getErrorTags('refreshAuthToken');

        try {
            const { data, error, status } = await megatronClient.post('v1/refreshThrudToken', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    refreshToken,
                },
            });

            if (!error) {
                return response(data?.refreshThrudToken, true);
            }

            const firstErrorCode = getFirstErrorCode(error.data.errors);

            if (![UNAUTHENTICATED].includes(firstErrorCode)) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    ERROR_TAGS,
                    {
                        data,
                        error,
                        status,
                    }
                );
            }
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async generateAuthTokenWithGoogle(token) {
        const response = tokens => {
            const {
                access_token: accessToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token: refreshToken = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
                isRegistration = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                isRegistration,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthTokenWithGoogle');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudTokenWithGoogle',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        token,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudTokenWithGoogle);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async generateAuthTokenWithFacebook(token) {
        const response = tokens => {
            const {
                access_token: accessToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token: refreshToken = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
                isRegistration = null,
            } = tokens || {};

            return {
                accessToken,
                refreshToken,
                accessTokenExpirationISO,
                refreshTokenExpirationISO,
                isRegistration,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthTokenWithFacebook');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudTokenWithFacebook',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        token,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudTokenWithFacebook);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async getCustomer() {
        const ERROR_TAGS = getErrorTags('getCustomer');

        try {
            const { data, error, status } = await megatronClient.get('v1/customer', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.extCustomer || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async getCustomerAddresses() {
        const ERROR_TAGS = getErrorTags('getCustomerAddresses');

        try {
            const { data, error, status } = await megatronClient.get('v1/getCustomerAddresses', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.extCustomer?.addresses || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async getCustomerEmail() {
        const ERROR_TAGS = getErrorTags('getCustomerEmail');

        try {
            const { data, error, status } = await megatronClient.get('v1/customerEmail', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.extCustomer?.email || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async revokeAuthToken() {
        const ERROR_TAGS = getErrorTags('revokeAuthToken');

        try {
            const { data, error, status } = await megatronClient.post('v1/revokeThrudToken', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.revokeThrudToken?.result || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },

    async createCustomer(registerData) {
        const ERROR_TAGS = getErrorTags('createCustomer');

        try {
            const { data, error, status } = await megatronClient.post('v1/createCustomer', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: registerData,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );

            return false;
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);

            return false;
        }
    },

    async wishlistProducts(skus = []) {
        const ERROR_TAGS = getErrorTags('wishlistProducts');

        try {
            if (!skus.length) {
                return [];
            }

            const { data, error, status } = await megatronClient.get('v1/wishlistProducts', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    skus,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.wishlistProducts || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                skus,
            });
        }

        return [];
    },

    async addWishlistProducts(skus = []) {
        const response = isAdded => ({ isAdded });
        const ERROR_TAGS = getErrorTags('addWishlistProducts');

        try {
            if (!skus.length) {
                return response(false);
            }

            const { data, error, status } = await megatronClient.post('v1/addWishlistProducts', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    skus,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return response(!data.addWishlistProducts.hasErrors);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                skus,
            });
        }

        return response(false);
    },

    async deleteWishlistProducts(skus = []) {
        const response = isDeleted => ({ isDeleted });
        const ERROR_TAGS = getErrorTags('deleteWishlistProducts');

        try {
            if (!skus.length) {
                return response(false);
            }

            const { data, error, status } = await megatronClient.post('v1/deleteWishlistProducts', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    skus,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return response(!data.deleteWishlistProducts.hasErrors);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                skus,
            });
        }

        return response(false);
    },

    async clearWishlist() {
        const response = isCleared => ({ isCleared });
        const ERROR_TAGS = getErrorTags('clearWishlist');

        try {
            const { data, error, status } = await megatronClient.post('v1/clearWishlist', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return response(!data.clearWishlist.hasErrors);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response(false);
    },

    async getCustomerWishlist(pageSize, currentPage = 1) {
        const response = customerWishlist => ({ customerWishlist });
        const ERROR_TAGS = getErrorTags('getCustomerWishlist');

        try {
            const { data, error, status } = await megatronClient.get('v1/customerWishlist', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    pageSize,
                    currentPage,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return response(data?.extCustomer?.paginatedWishlist?.items || []);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                pageSize,
                currentPage,
            });
        }

        return response([]);
    },

    async initiateResetPassword(email) {
        const ERROR_TAGS = getErrorTags('initiateResetPassword');
        const storeCode = helpers.store.getters['config/storeCode'];

        try {
            const { data, error, status } = await megatronClient.put(
                addStoreCodePrefix(storeCode, 'V1/customers/password'),
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
                    payload: {
                        email,
                        template: 'email_reset',
                    },
                }
            );

            if (!error || status === NOT_FOUND_HTTP_CODE) {
                return typeof data === 'boolean' ? data : false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                email,
            });
        }

        return false;
    },

    async validateResetPasswordToken(customerId, token) {
        const ERROR_TAGS = getErrorTags('validateResetPasswordToken');
        const storeCode = helpers.store.getters['config/storeCode'];

        try {
            const { data, error, status } = await megatronClient.get(
                addStoreCodePrefix(
                    storeCode,
                    `V1/customers/${customerId}/password/resetLinkToken/${token}`
                ),
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
                }
            );

            if (!error) {
                return typeof data === 'boolean' ? data : false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerId,
                token,
            });
        }

        return false;
    },

    async resetPassword(resetToken, newPassword) {
        const ERROR_TAGS = getErrorTags('resetPassword');
        const storeCode = helpers.store.getters['config/storeCode'];

        try {
            const { data, error, status } = await megatronClient.post(
                addStoreCodePrefix(storeCode, 'V1/customers/resetPassword'),
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST,
                    payload: {
                        email: '',
                        resetToken,
                        newPassword,
                    },
                }
            );

            if (!error) {
                return typeof data === 'boolean' ? data : false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },

    async updateCustomer(customerInput) {
        const ERROR_TAGS = getErrorTags('updateCustomer');

        try {
            const { data, error, status } = await megatronClient.post('v1/updateCustomer', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    customerInput,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.extUpdateCustomer?.customer || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    customerInput,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerInput,
            });
        }

        return null;
    },

    async updateCustomerNonSensitiveData(payload) {
        const ERROR_TAGS = getErrorTags('updateCustomerNonSensitiveData');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/updateCustomerNonSensitive',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data.updateCustomerNonSensitive?.customer || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return null;
    },

    async changeCustomerPassword(currentPassword, newPassword) {
        const ERROR_TAGS = getErrorTags('changeCustomerPassword');

        try {
            const { data, error, status } = await megatronClient.post('v1/changeCustomerPassword', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    currentPassword,
                    newPassword,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },

    async getCustomerOrdersExtData(currentPage, pageSize, field, order) {
        const response = (hasError, orders, ordersCount) => ({
            hasError: hasError || false,
            orders: orders || [],
            ordersCount: ordersCount || 0,
        });
        const ERROR_TAGS = getErrorTags('getCustomerOrdersExtData');
        const payload = { currentPage, pageSize, field, order };

        try {
            const { data, error, status } = await megatronClient.get('v1/getCustomerOrdersExt', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                const { items, itemsCount } = data?.customerOrdersExt || {};

                return response(false, items, itemsCount);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                payload,
            });
        }

        return response(true);
    },

    async getCustomerOrderExtData(orderNumber) {
        const ERROR_TAGS = getErrorTags('getCustomerOrderExtData');

        try {
            const response = await megatronClient.get('v1/getCustomerOrderExtData', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    orderNumber,
                },
                context: {
                    useAuthorization: true,
                },
            });

            const { data, error, status } = normalizeMegatronResponse(
                response,
                MEGATRON_CUSTOMER_GET_CUSTOMER_ORDER_EXT_DATA_OPERATION_NAME
            );

            if (!error) {
                return data?.customerOrderExt || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    orderNumber,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                orderNumber,
            });
        }

        return null;
    },

    async createAvailabilityNotificationSignUp(email, sku) {
        const ERROR_TAGS = getErrorTags('createAvailabilityNotificationSignUp');

        const response = ({ success = false, message = '' } = {}) => ({
            success,
            message,
        });

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/availabilityNotificationSignUp',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        email,
                        sku,
                    },
                }
            );

            if (!error) {
                return response(data?.availabilityNotificationSignUp || {});
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    email,
                    sku,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                email,
                sku,
            });
        }

        return response();
    },

    async createCustomerAddress(customerAddressInput) {
        const ERROR_TAGS = getErrorTags('createCustomerAddress');

        try {
            const { data, error, status } = await megatronClient.post('v1/createCustomerAddress', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    customerAddressInput,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    customerAddressInput,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerAddressInput,
            });
        }

        return false;
    },

    async deleteCustomerAddress(addressId) {
        const ERROR_TAGS = getErrorTags('deleteCustomerAddress');

        try {
            const { data, error, status } = await megatronClient.post('v1/deleteCustomerAddress', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    addressId,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    addressId,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                addressId,
            });
        }

        return false;
    },

    async updateCustomerAddress(customerAddressInput, addressId) {
        const ERROR_TAGS = getErrorTags('updateCustomerAddress');

        try {
            const { data, error, status } = await megatronClient.post('v1/updateCustomerAddress', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    customerAddressInput,
                    addressId,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return true;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    customerAddressInput,
                    addressId,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                customerAddressInput,
                addressId,
            });
        }

        return false;
    },

    async registerCustomerInLoyaltyClub(loyaltyClubRegisterData) {
        const ERROR_TAGS = getErrorTags('registerCustomerInLoyaltyClub');

        const response = ({
            success = false,
            message = 'MODIVO Club register error occured',
        } = {}) => ({
            success,
            message,
        });

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/registerCustomerInLoyaltyClub',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: loyaltyClubRegisterData,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return response(data?.registerCustomerInLoyaltyClub || {});
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return response();
    },

    async noticeLoyaltyClubStatus() {
        const ERROR_TAGS = getErrorTags('noticeLoyaltyClubStatus');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/noticeLoyaltyClubStatus',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.noticeLoyaltyClubStatus || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },

    async setCustomerBirthDate(birthDayMonth) {
        const response = (result, message) => ({
            result: result || false,
            message: message || 'Updating customer details error occured',
        });
        const ERROR_TAGS = getErrorTags('setCustomerBirthDate');

        try {
            const { data, error, status } = await megatronClient.post('v1/setCustomerBirthDate', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload: {
                    birthDayMonth,
                },
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                const { success, message } = data?.setLoyaltyClubCustomerBirthDayMonth || {};

                return response(success, message);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    birthDayMonth,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                birthDayMonth,
            });
        }

        return response(false);
    },

    async getLatestUnpaidOrders() {
        const ERROR_TAGS = getErrorTags('getLatestUnpaidOrders');

        try {
            const { data, error, status } = await megatronClient.get('v1/getLatestUnpaidOrders', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                context: {
                    useAuthorization: true,
                },
            });

            if (!error) {
                return data?.latestUnpaidOrders?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return [];
    },

    async generateAuthTokenWithMagicLink(magicLink) {
        const response = (tokens, success = false) => {
            const {
                access_token: accessToken = null,
                exp: accessTokenExpirationISO = null,
                refresh_token: refreshToken = null,
                refresh_token_exp: refreshTokenExpirationISO = null,
                isRegistration = null,
            } = tokens || {};

            return {
                accessToken,
                accessTokenExpirationISO,
                refreshToken,
                refreshTokenExpirationISO,
                isRegistration,
                success,
            };
        };
        const ERROR_TAGS = getErrorTags('generateAuthTokenWithMagicLink');

        try {
            const { data, error, status } = await megatronClient.post(
                'v1/generateThrudTokenWithMagicLink',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload: {
                        magicLink,
                    },
                }
            );

            if (!error) {
                return response(data?.generateThrudTokenWithMagicLink, true);
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    magicLink,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, {
                magicLink,
            });
        }

        return response();
    },

    async getCustomerIsSubscribedToNewsletter() {
        const ERROR_TAGS = getErrorTags('getCustomerIsSubscribedToNewsletter');

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/customerIsSubscribedToNewsletter',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    context: {
                        useAuthorization: true,
                    },
                }
            );

            if (!error) {
                return data?.customerIsSubscribedToNewsletter || false;
            }

            $errorHandler.captureError(
                new Error(error?.message || 'UNKNOWN_ERROR', {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return false;
    },
});
