const HISTORY_LIMIT = 10;

export default function addToSearchHistory(searchText, history) {
    const historyArray = history || [];
    const searchQuery = searchText.trim();

    if (searchQuery === '') {
        return historyArray;
    }

    const historyNew = historyArray.filter(query => query !== searchQuery);

    historyNew.unshift(searchQuery);

    return historyNew.slice(0, HISTORY_LIMIT);
}
