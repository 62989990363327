export const types = {
    SET_VISUAL_SEARCH_PIM_CODE: 'SET_VISUAL_SEARCH_PIM_CODE',
    SET_VISUAL_SEARCH_IMG: 'SET_VISUAL_SEARCH_IMG',
    SET_VISUAL_SEARCH_FILE_NAME: 'SET_VISUAL_SEARCH_FILE_NAME',
    SET_VISUAL_SEARCH_ID: 'SET_VISUAL_SEARCH_ID',
};

export default {
    [types.SET_VISUAL_SEARCH_PIM_CODE](state, data) {
        state.activeMainCategoryPimCode = data;
    },

    [types.SET_VISUAL_SEARCH_IMG](state, data) {
        state.base64 = data;
    },

    [types.SET_VISUAL_SEARCH_FILE_NAME](state, data) {
        state.fileName = data;
    },

    [types.SET_VISUAL_SEARCH_ID](state, data) {
        state.searchId = data;
    },
};
