import { MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE } from '@configs/megatron-client';

const MODIVO_SUFFIX_PATH = 'modivo/web';

export const MODIVO_SMAUG_SUFFIX_PATH = 'rest/smaug';

export const REST_SUFFIX_PATH = 'rest';
export const SYNERISE_SUFFIX_PATH = 'rest/synerise';
export const SEARCH_SUFFIX_PATH = 'rest/search/modivo';
export const PRODUCT_SEARCH_SUFFIX_PATH = 'rest/product-search/modivo';
export const TRUSTMATE_SUFFIX_PATH = 'rest/trustmate_mod';
export const TRUSTMATE_PROXY_SUFFIX_PATH = 'rest/trustmate';
export const MAGENTO_SUFFIX_PATH = 'rest/magento/modivo';

export const createMegatronEndpointUrl = (baseUrl, suffix = MODIVO_SUFFIX_PATH) =>
    `${[baseUrl, suffix].join('/')}`;

export const createMegatronTrustmateEndpoints = ({
    countries,
    megatronEndpoint,
    isNewProxyEnabled,
}) => {
    if (isNewProxyEnabled) {
        return {
            [MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, TRUSTMATE_PROXY_SUFFIX_PATH),
                useErrorCallback: false,
                context: {
                    useAuthorization: false,
                },
            },
        };
    }

    return countries.reduce((acc, country) => {
        acc[`${MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE}_${country}`] = {
            uri: createMegatronEndpointUrl(
                megatronEndpoint,
                `${TRUSTMATE_SUFFIX_PATH}_${country}_1_1`
            ),
            useErrorCallback: false,
            context: {
                useAuthorization: false,
            },
        };

        return acc;
    }, {});
};
