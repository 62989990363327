export const types = {
    SET_CART_ID: 'SET_CART_ID',
    SET_CART_DATA: 'SET_CART_DATA',
    SET_CART_PROMOTIONS: 'SET_CART_PROMOTIONS',
    SET_IS_CART_DATA_VALID: 'SET_IS_CART_DATA_VALID',
    SET_IS_CART_DATA_LOADING: 'SET_IS_CART_DATA_LOADING',
    SET_COMPLEX_DATA_PRODUCT_ITEMS: 'SET_COMPLEX_DATA_PRODUCT_ITEMS',
    REMOVE_COMPLEX_DATA_PRODUCT_ITEMS: 'REMOVE_COMPLEX_DATA_PRODUCT_ITEMS',
    SET_LOADER_TEXT: 'SET_LOADER_TEXT',
    SET_IS_ADD_TO_CART_PENDING: 'SET_IS_ADD_TO_CART_PENDING',
    SET_INVALID_COUPON_DATA: 'SET_INVALID_COUPON_DATA',
    SET_KLARNA_METHOD_ACTIVE: 'SET_KLARNA_METHOD_ACTIVE',
    SET_PAD_DATA: 'SET_PAD_DATA',
    SET_IS_LOADER_VISIBLE: 'SET_IS_LOADER_VISIBLE',
};

export default {
    [types.SET_CART_ID](state, cartId) {
        state.cartId = cartId;
    },
    [types.SET_KLARNA_METHOD_ACTIVE](state, klarnaData) {
        state.cartData = { ...state.cartData, ...klarnaData };
    },
    [types.SET_CART_DATA](state, cartData) {
        if (cartData) {
            state.cartData = { ...state.cartData, ...cartData };
        } else {
            state.cartData = cartData;
        }
    },
    [types.SET_IS_CART_DATA_VALID](state, isCartDataValid) {
        state.isCartDataValid = isCartDataValid;
    },
    [types.SET_IS_CART_DATA_LOADING](state, isCartDataLoading) {
        state.isCartDataLoading = isCartDataLoading;
    },
    [types.SET_COMPLEX_DATA_PRODUCT_ITEMS](state, complexDataProductItems) {
        state.complexProductDataItems = [
            ...state.complexProductDataItems,
            ...complexDataProductItems,
        ];
    },
    [types.REMOVE_COMPLEX_DATA_PRODUCT_ITEMS](state, skusToRemove) {
        state.complexProductDataItems = state.complexProductDataItems.filter(
            complexProduct => !skusToRemove.includes(complexProduct.id)
        );
    },
    [types.SET_LOADER_TEXT](state, text) {
        state.loaderText = text;
    },
    [types.SET_IS_ADD_TO_CART_PENDING](state, isAddToCartPending) {
        state.isAddToCartPending = isAddToCartPending;
    },
    [types.SET_PAD_DATA](state, padData) {
        state.padData = padData;
    },
    [types.SET_IS_LOADER_VISIBLE](state, isVisible) {
        state.isLoaderVisible = isVisible;
    },
    [types.SET_INVALID_COUPON_DATA](state, invalidCouponData) {
        state.invalidCouponData = invalidCouponData;
    },
};
