<template>
    <div class="mobile-app-links">
        <BaseLinkStatic
            v-for="({ path, logo, company }, index) in apps"
            :key="`${company}-${index}`"
            :path="path"
            :title="company"
            target="_blank"
            class="logo"
        >
            <SvgIcon width="108" height="33">
                <component :is="logo" />
            </SvgIcon>
        </BaseLinkStatic>
    </div>
</template>

<script>
import GooglePlay from '@static/pwa/static/download-app/google-play.svg?inline';
import AppStore from '@static/pwa/static/download-app/app-store.svg?inline';
import AppGallery from '@static/pwa/static/download-app/app-gallery.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

export default {
    name: 'MobileAppLinks',

    components: {
        BaseLinkStatic,
        SvgIcon,
    },

    props: {
        playUrl: {
            type: String,
            default: '',
        },

        appStoreUrl: {
            type: String,
            default: '',
        },

        appGalleryUrl: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isAndroid: false,
            isIOS: false,
        };
    },

    computed: {
        apps() {
            const { isAndroid, checkIfSystemActive } = this;

            return [
                {
                    logo: GooglePlay,
                    company: this.$t('Google Play'),
                    path: this.playUrl,
                    isActive: checkIfSystemActive(isAndroid),
                },
                {
                    logo: AppStore,
                    company: this.$t('App Store'),
                    path: this.appStoreUrl,
                    isActive: checkIfSystemActive(this.isIOS),
                },
                {
                    logo: AppGallery,
                    company: this.$t('AppGallery'),
                    path: this.appGalleryUrl,
                    isActive: checkIfSystemActive(isAndroid),
                },
            ].filter(({ isActive, logo }) => isActive && !!logo);
        },
    },

    mounted() {
        this.isAndroid = this.$ua?.isAndroid;
        this.isIOS = this.$ua?.isIOS;
    },

    methods: {
        checkIfSystemActive(system) {
            return !this.isAndroid && !this.isIOS ? true : system;
        },
    },
};
</script>

<style lang="scss" scoped>
$app-logo-width: 108px;
$app-logo-height: 33px;

.mobile-app-links {
    .logo {
        @apply block;
        width: $app-logo-width;
        height: $app-logo-height;

        &:not(:first-of-type) {
            @apply mt-2;
        }
    }
}
</style>
