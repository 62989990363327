import navigationMutations from './mutations';
import navigationActions from './actions';
import navigationGetters from './getters';

// fetched at @queries/getNavigationCategories

export const state = () => ({
    defaultCategoryId: '2',
    categories: [],
    mainCategoryPimSlug: '',
    currentNavPath: '',
    currentNavType: null,
    currentNavColor: {},
    isDesktopMenuOpen: false,
    activeMainCategoryPimSlug: '',
    isSearchInputActive: false,
    isSortFilterPanelActive: false,
    allowDoubleColumns: false,
    isAccordionAbTestOn: false,
});

export const mutations = navigationMutations;
export const actions = navigationActions;
export const getters = navigationGetters;
