export const checkoutService = {
    getOrderRepayOptions: () =>
        Promise.resolve([
            { label: 'Blik', code: 'blik' },
            { label: 'payu', code: 'payu_gateway' },
        ]),

    getRepayLinkByPaymentMethod: () => ({
        success: 'success',
        message: 'message',
        redirect_uri: '/',
    }),

    getOrderRepayLink: () => ({
        success: 'success',
        message: 'message',
        redirect_uri: '/',
    }),

    getPaymentMethods: () => ({
        paymentMethods: [{ code: 'blik' }, { code: 'payu_gateway' }],
    }),
};

export const cmsBlocksService = {
    getData: cmsBlocksIds => {
        return cmsBlocksIds.map(blockId => ({
            content: `Static block...`,
            identifier: blockId,
        }));
    },
};

export default {
    install: Vue => {
        Vue.prototype.$services = {
            checkout: checkoutService,
            cmsBlocks: cmsBlocksService,
        };
        Vue.prototype.$asyncServices = {
            use: () => ({}),
        };
    },
};
