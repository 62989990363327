<template>
    <SmartBannerLarge
        v-if="isVisible && config.isLarge"
        :image-url="config.imageUrl"
        :banner-text="config.bannerText"
        :button-text="config.buttonText"
        :one-link-url="config.oneLinkUrl"
        :button-variant="config.buttonVariant"
        :banner-name="config.bannerName"
        @close="close()"
    />
    <SmartBanner
        v-else-if="isVisible"
        :banner-text="config.bannerText"
        :button-text="config.buttonText"
        :one-link-url="config.oneLinkUrl"
        :banner-name="config.bannerName"
        @close="close()"
    />
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import {
    COOKIE_EXPIRE_TIME_ALL_VIEWS,
    COOKIE_NAME_RECENTLY_VIEWED,
    COOKIE_NAME_VIEWS_COUNT,
    COOKIE_EXPIRE_TIME_RECENT_VIEW,
    VIEWS_LIMIT,
    CMS_BLOCK_ID,
} from '@configs/smart-banner';

import cookies from '@services/cookies';

import { CMS_SMART_BANNER_CONFIG } from '@modules/page-builder/page-builder.config';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

const getCookieOptions = maxAge => ({
    path: '/',
    maxAge,
    sameSite: 'lax',
    secure: process.env.NODE_ENV !== 'development',
});

export default {
    name: 'SmartBannerWrapper',

    components: {
        SmartBanner: () => ({
            component: import(
                /* webpackChunkName: "smart-banner" */
                '@molecules/SmartBanner/SmartBanner'
            ),
        }),
        SmartBannerLarge: () => ({
            component: import(
                /* webpackChunkName: "smart-banner-large" */
                '@molecules/SmartBanner/SmartBannerLarge'
            ),
        }),
    },

    data() {
        return {
            isRecentlyViewed: false,
            viewsCount: 0,
            config: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['getComponentFromPageBuilderBlock']),

        isConfigValid() {
            const { enabledPages, buttonText, bannerText, oneLinkUrl, bannerName } =
                this.config || {};

            return (
                !!buttonText &&
                !!bannerText &&
                !!oneLinkUrl &&
                bannerName &&
                Array.isArray(enabledPages)
            );
        },

        isEnabledOnThisPage() {
            return this.config.enabledPages?.includes(this.$route.name);
        },

        hasViewsLeft() {
            return this.viewsCount < VIEWS_LIMIT;
        },

        isVisible() {
            return (
                this.isMobile &&
                this.isConfigValid &&
                this.isEnabledOnThisPage &&
                this.hasViewsLeft &&
                !this.isRecentlyViewed
            );
        },

        cookieNameRecentlyViewed() {
            if (!this.config.isLarge) {
                return COOKIE_NAME_RECENTLY_VIEWED;
            }

            return `${COOKIE_NAME_RECENTLY_VIEWED}-${this.config.capId}`;
        },

        cookieNameViewsCount() {
            if (!this.config.isLarge) {
                return COOKIE_NAME_VIEWS_COUNT;
            }

            return `${COOKIE_NAME_VIEWS_COUNT}-${this.config.capId}`;
        },
    },

    mounted() {
        this.getConfig();

        this.PREFIXED_COOKIE_RECENTLY_VIEWED = cookies.createCookieWithPrefix(
            this.cookieNameRecentlyViewed
        );
        this.PREFIXED_COOKIE_VIEWS_COUNT = cookies.createCookieWithPrefix(
            this.cookieNameViewsCount
        );

        this.isRecentlyViewed = !!this.$cookies.get(this.PREFIXED_COOKIE_RECENTLY_VIEWED);

        this.viewsCount = parseInt(this.$cookies.get(this.PREFIXED_COOKIE_VIEWS_COUNT), 10) || 0;
    },

    methods: {
        getConfig() {
            const blockComponents = this.getComponentFromPageBuilderBlock(
                CMS_BLOCK_ID,
                CMS_SMART_BANNER_CONFIG
            );

            this.config = blockComponents?.props || {};
        },

        close() {
            this.$cookies.set(
                this.PREFIXED_COOKIE_RECENTLY_VIEWED,
                1,
                getCookieOptions(COOKIE_EXPIRE_TIME_RECENT_VIEW)
            );

            this.$cookies.set(
                this.PREFIXED_COOKIE_VIEWS_COUNT,
                this.viewsCount + 1,
                getCookieOptions(COOKIE_EXPIRE_TIME_ALL_VIEWS)
            );

            this.isRecentlyViewed = true;
        },
    },
};
</script>
