import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG } from '@configs/megatron-client';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default (megatronClient, $errorHandler) => ({
    async preauthorize(cartId) {
        const payload = {
            cartId,
        };

        try {
            return await megatronClient.post('v1/payments/preauthorize', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                payload,
            });
        } catch (err) {
            $errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.paypal.preauthorize',
                },
                {
                    payload,
                }
            );
        }
    },
    async authorize(paymentId) {
        const payload = {
            paymentId,
        };

        try {
            return await megatronClient.post('v1/payments/authorize', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                payload,
            });
        } catch (err) {
            $errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.paypal.authorize',
                },
                {
                    payload,
                }
            );
        }
    },
});
