<template>
    <SvgIcon :width="QR_CODE_SIZE" :height="QR_CODE_SIZE">
        <QrCode />
    </SvgIcon>
</template>

<script>
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import QrCode from '@static/pwa/static/mobile-app-qr-codes/qr-code-all.svg?inline';

export default {
    name: 'MobileAppQrCode',

    components: {
        QrCode,
        SvgIcon,
    },

    beforeCreate() {
        this.QR_CODE_SIZE = '122px';
    },
};
</script>
