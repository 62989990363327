<template>
    <HeaderSimple :heading="heading" :class="{ 'is-cart': isCart }">
        <slot />
        <template #right>
            <template v-if="withFreeDeliveryAndRefundInfo">
                <div class="item">
                    <SvgIcon class="item-svg">
                        <Gift />
                    </SvgIcon>
                    {{ freeDeliveryText }}
                </div>
                <div class="item">
                    <SvgIcon class="item-svg">
                        <Return1 />
                    </SvgIcon>
                    {{
                        $t('{days} day for free refund', {
                            days: $getLocaleConfigByKey(DAYS_FOR_RETURNS),
                        })
                    }}
                </div>
            </template>
            <slot name="user-dashboard" />
        </template>
    </HeaderSimple>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { DAYS_FOR_RETURNS } from '@localeConfig/keys';

import { CHECKOUT_CART_PAGE_NAME, CHECKOUT_PAGE_NAME } from '@router/names';

import Gift from '@static/icons/24px/gift.svg?inline';
import Return1 from '@static/icons/16px/return1.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import HeaderSimple from '@molecules/HeaderSimple/HeaderSimple';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart');

export default {
    name: 'HeaderSimpleWrapperV2',

    components: {
        HeaderSimple,
        SvgIcon,
        Gift,
        Return1,
    },

    props: {
        withFreeDeliveryAndRefundInfo: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapConfigGetters(['freeDeliveryText']),
        ...mapCartGetters(['cartItemsCount']),

        isCart() {
            return this.$route.name === CHECKOUT_CART_PAGE_NAME;
        },

        isCheckout() {
            return this.$route.name === CHECKOUT_PAGE_NAME;
        },

        heading() {
            if (this.isCart) {
                return `${this.$t('Cart')} (${this.cartItemsCount})`;
            }

            if (this.isCheckout) {
                return this.$t('Checkout');
            }

            return '';
        },
    },

    beforeCreate() {
        this.DAYS_FOR_RETURNS = DAYS_FOR_RETURNS;
    },
};
</script>

<style lang="scss" scoped>
.is-cart {
    @screen mobile-only {
        .item {
            @apply hidden;
        }
    }
}
</style>
