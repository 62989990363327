export const types = {
    SET_IS_CREATING_ID: 'SET_IS_CREATING_ID',
    SET_IS_CONNECTING_CLIENT: 'SET_IS_CONNECTING_CLIENT',
    SET_CLIENT_ADDRESSES: 'SET_CLIENT_ADDRESSES',
    SET_AVATAR_ID: 'SET_AVATAR_ID',
    SET_SHOULD_CONNECT_CLIENT: 'SET_SHOULD_CONNECT_CLIENT',
    SET_ACCOUNT_VERIFICATION_STATUS: 'SET_ACCOUNT_VERIFICATION_STATUS',
};

export default {
    [types.SET_IS_CREATING_ID](state, isCreating) {
        state.isCreatingId = isCreating;
    },

    [types.SET_IS_CONNECTING_CLIENT](state, isConnecting) {
        state.isConnectingClient = isConnecting;
    },

    [types.SET_CLIENT_ADDRESSES](state, addresses) {
        state.clientAddresses = addresses || [];
    },

    [types.SET_AVATAR_ID](state, id) {
        state.avatarId = id;
    },

    [types.SET_SHOULD_CONNECT_CLIENT](state, shouldConnect) {
        state.shouldConnectClient = shouldConnect;
    },

    [types.SET_ACCOUNT_VERIFICATION_STATUS](state, status) {
        state.accountVerificationStatus = status;
    },
};
