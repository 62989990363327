import { v4 as uuid } from 'uuid';

import { AVATAR_HASH, AVATAR_ID, SECONDS_IN_YEAR } from '@configs/storage';
import {
    ROUTES_USING_GENERATE_AVATAR_ID_IN_SSR,
    GENERATE_AVATAR_ID_IN_SSR_TEST_NAME,
    VARIANT_ON,
    AVATAR_ID_CREATION_TIMEOUT_THRESHOLD_IN_MILLISECONDS,
} from '@configs/avatar';

import cookies from '@services/cookies';

const AVATAR_ID_COOKIE_NAME = cookies.createCookieWithPrefix(AVATAR_ID);
const AVATAR_HASH_COOKIE_NAME = cookies.createCookieWithPrefix(AVATAR_HASH);

const COOKIE_OPTIONS = {
    path: '/',
    maxAge: SECONDS_IN_YEAR,
    sameSite: 'lax',
    secure: process.env.NODE_ENV !== 'development',
};

const setAvatarHashInCookie = ($cookies, avatarHash) => {
    if (!avatarHash) {
        return;
    }

    $cookies.set(AVATAR_HASH_COOKIE_NAME, avatarHash, COOKIE_OPTIONS);
};

export const isAvatarEnabled = $config => $config.isAvatarEnabled;

export const getAvatarHashFromCookies = $cookies => {
    const hashFromCookie = $cookies.get(AVATAR_HASH_COOKIE_NAME) || null;

    if (hashFromCookie) {
        return hashFromCookie;
    }

    const hash = uuid();

    setAvatarHashInCookie($cookies, hash);

    return hash;
};

export const setAvatarIdInCookie = ($cookies, avatarId) => {
    if (!avatarId) {
        return;
    }

    $cookies.set(AVATAR_ID_COOKIE_NAME, avatarId, COOKIE_OPTIONS);
};

export const getAvatarIdFromCookies = $cookies => $cookies.get(AVATAR_ID_COOKIE_NAME) || null;

export const getAvatarIdFromStore = $store => $store?.state?.avatar?.avatarId || null;

export const isRouteUsingGenerateAvatarIdInSsr = route =>
    ROUTES_USING_GENERATE_AVATAR_ID_IN_SSR.includes(route?.name);

export const isGenerateAvatarIdInSsrEnabled = $abTests =>
    $abTests.getVariant(GENERATE_AVATAR_ID_IN_SSR_TEST_NAME) === VARIANT_ON;

export const createIdForGuestInSsrHandler = async ({
    $ua,
    $config,
    $cookies,
    $abTests,
    route,
    store,
}) => {
    if (
        process.server &&
        !$ua?.isBot &&
        isAvatarEnabled($config) &&
        isGenerateAvatarIdInSsrEnabled($abTests) &&
        isRouteUsingGenerateAvatarIdInSsr(route) &&
        !getAvatarIdFromCookies($cookies)
    ) {
        await store.dispatch(
            'avatar/createIdForGuest',
            AVATAR_ID_CREATION_TIMEOUT_THRESHOLD_IN_MILLISECONDS
        );
    }
};
