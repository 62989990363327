export const DEFAULT_STORE_CODE = 'default';
export const PL_STORE_CODE = 'pl';
export const SK_STORE_CODE = 'sk';
export const CZ_STORE_CODE = 'cz';
export const RO_STORE_CODE = 'ro';
export const HU_STORE_CODE = 'hu';
export const BG_STORE_CODE = 'bg';
export const GR_STORE_CODE = 'gr';
export const LT_STORE_CODE = 'lt';
export const DE_STORE_CODE = 'de';
export const FR_STORE_CODE = 'fr';
export const IT_STORE_CODE = 'it';
export const HR_STORE_CODE = 'hr';
export const UA_STORE_CODE = 'ua_uk';
export const AT_STORE_CODE = 'at';
export const LV_STORE_CODE = 'lv';
export const SI_STORE_CODE = 'si';

export const STORE_CODES = [
    DEFAULT_STORE_CODE,
    PL_STORE_CODE,
    SK_STORE_CODE,
    CZ_STORE_CODE,
    RO_STORE_CODE,
    HU_STORE_CODE,
    BG_STORE_CODE,
    GR_STORE_CODE,
    LT_STORE_CODE,
    DE_STORE_CODE,
    FR_STORE_CODE,
    IT_STORE_CODE,
    HR_STORE_CODE,
    UA_STORE_CODE,
    AT_STORE_CODE,
    LV_STORE_CODE,
    SI_STORE_CODE,
];

export const MOBILE_APP_STORE_CODES = [
    DEFAULT_STORE_CODE,
    PL_STORE_CODE,
    SK_STORE_CODE,
    CZ_STORE_CODE,
    RO_STORE_CODE,
    HU_STORE_CODE,
    BG_STORE_CODE,
    GR_STORE_CODE,
    LT_STORE_CODE,
    DE_STORE_CODE,
    FR_STORE_CODE,
    IT_STORE_CODE,
    HR_STORE_CODE,
    UA_STORE_CODE,
    AT_STORE_CODE,
    LV_STORE_CODE,
    SI_STORE_CODE,
];
