const options = JSON.parse(`{
  "luxId": "448150142",
  "defaultSampleRate": 0,
  "sampleRateForPageGroupConfig": [
    {
      "routeName": "product",
      "sampleRate": 2
    },
    {
      "routeName": "category",
      "sampleRate": 2
    },
    {
      "routeName": "homepage",
      "sampleRate": 2
    },
    {
      "routeName": "search",
      "sampleRate": 2
    },
    {
      "routeName": "login",
      "sampleRate": 2
    },
    {
      "routeName": "register",
      "sampleRate": 2
    },
    {
      "routeName": "main-category",
      "sampleRate": 2
    },
    {
      "routeName": "wishlist",
      "sampleRate": 2
    },
    {
      "routeName": "rma/create",
      "sampleRate": 2
    },
    {
      "routeName": "rma/list",
      "sampleRate": 2
    },
    {
      "routeName": "checkout-account",
      "sampleRate": 50
    },
    {
      "routeName": "checkout-cart",
      "sampleRate": 50
    },
    {
      "routeName": "checkout",
      "sampleRate": 50
    },
    {
      "routeName": "checkout-success",
      "sampleRate": 50
    },
    {
      "routeName": "customer/orders",
      "sampleRate": 2
    },
    {
      "routeName": "customer/orders/order-view",
      "sampleRate": 2
    },
    {
      "routeName": "cms",
      "sampleRate": 2
    }
  ]
}`);
const isEnabledCheck = ({ $abTests }) => $abTests?.getVariant('dev_rum_sc') === 'on';

const {
    luxId,
    defaultSampleRate,
    sampleRateForPageGroupConfig,
} = options;

const getSampleRateForPage = (route) => {
    const sampleRate = defaultSampleRate || 0;
    const groupSampleRate = sampleRateForPageGroupConfig || [];

    const group = groupSampleRate.find(
        groupConfig => route.name === groupConfig.routeName
    );

    return group ? group.sampleRate : sampleRate;
};

export default (context, inject) => {
    const isEnabledCheckFunctionType = typeof isEnabledCheck === 'function';

    if (!isEnabledCheckFunctionType || (isEnabledCheckFunctionType && !isEnabledCheck(context))) {
        return;
    }

    if (process.server) {
        context.beforeNuxtRender(() => {
            const { head } = context.app;

            /* eslint-disable no-underscore-dangle */
            head.__dangerouslyDisableSanitizersByTagID =
                head.__dangerouslyDisableSanitizersByTagID || {};
            head.__dangerouslyDisableSanitizersByTagID['lux-init'] = [
                'innerHTML',
            ];

            head.script.unshift({
                hid: 'lux-src',
                src: `https://cdn.speedcurve.com/js/lux.js?id=${luxId}`,
                async: true,
                defer: true,
                crossorigin: 'anonymous',
            });

            head.script.unshift({
                hid: 'lux-options',
                innerHTML: `
                    if (window.LUX) {
                        window.LUX.auto = false;
                        window.LUX.sendBeaconOnPageHidden = true;
                        window.LUX.samplerate = ${getSampleRateForPage(context.route)};
                    }
                `,
            });

            head.script.unshift({
                hid: 'lux-init',
                innerHTML:
                    // eslint-disable-next-line max-len
                    'LUX=function(){function n(){return Date.now?Date.now():+new Date}var r,e=n(),t=window.performance||{},a=t.timing||{navigationStart:(null===(r=window.LUX)||void 0===r?void 0:r.ns)||e};function o(){return t.now?(r=t.now(),Math.floor(r)):n()-a.navigationStart;var r}(LUX=window.LUX||{}).ac=[],LUX.addData=function(n,r){return LUX.cmd(["addData",n,r])},LUX.cmd=function(n){return LUX.ac.push(n)},LUX.getDebug=function(){return[[e,0,[]]]},LUX.init=function(){return LUX.cmd(["init"])},LUX.mark=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.mark)return t.mark.apply(t,n);var e=n[0],a=n[1]||{};void 0===a.startTime&&(a.startTime=o());LUX.cmd(["mark",e,a])},LUX.markLoadTime=function(){return LUX.cmd(["markLoadTime",o()])},LUX.measure=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.measure)return t.measure.apply(t,n);var e,a=n[0],i=n[1],u=n[2];e="object"==typeof i?n[1]:{start:i,end:u};e.duration||e.end||(e.end=o());LUX.cmd(["measure",a,e])},LUX.send=function(){return LUX.cmd(["send"])},LUX.ns=e;var i=LUX;if(window.LUX_ae=[],window.addEventListener("error",(function(n){window.LUX_ae.push(n)})),window.LUX_al=[],"function"==typeof PerformanceObserver&&"function"==typeof PerformanceLongTaskTiming){var u=new PerformanceObserver((function(n){for(var r=n.getEntries(),e=0;e<r.length;e++)window.LUX_al.push(r[e])}));try{u.observe({type:"longtask"})}catch(n){}}return i}();',
            });
        });
    }

    if (!process.client) {
        return;
    }

    let label = '';
    let readyToSend = false;

    const lux = {
        label: newLabel => {
            if (!window.LUX) {
                return;
            }

            label = newLabel;
        },
        init() {
            const { LUX } = window;

            if (!LUX) {
                return;
            }

            LUX.init();
            label = '';
        },
        addData(name, value) {
            const { LUX } = window;

            if (!LUX) {
                return;
            }

            LUX.addData(name, value);
        },
        markLoadTime() {
            const { LUX } = window;

            if (!LUX) {
                return;
            }

            LUX.markLoadTime();
        },
        readyToSend() {
            const { LUX } = window;

            if (!LUX) {
                return;
            }

            readyToSend = true;
        },
        send() {
            const { LUX } = window;

            if (!LUX) {
                return;
            }

            if (!readyToSend) {
                return;
            }

            LUX.label = label;
            LUX.send();
            readyToSend = false;
        },
    };

    inject('lux', lux);
};
