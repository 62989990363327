import {
    MANUAL_TABLE_KEYS,
    MANUFACTURER_TABLE_ATTRIBUTE_KEY,
    MANUFACTURER_TABLE_ATTRIBUTES_KEYS,
    MANUFACTURER_TABLE_LABELS,
    MANUFACTURER_TABLE_SIZE,
    MANUFACTURER_TABLE_VALUE_KEY,
    SAFE_SIZE_LENGTH,
    SAFE_SIZE_MAX_SIZE,
} from '@configs/size-tables';

import {
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    COLOR_ARRAY_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';

import { getFirstOfferFromVariant } from '@assets/product-offers';

export const getProductVariantLabel = (
    productVariant,
    variantDimension,
    fallbackVariantDimension
) => {
    const { size, values: productVariantValues } = productVariant;

    return (
        productVariantValues?.[variantDimension]?.value?.label ||
        productVariantValues?.[fallbackVariantDimension]?.value?.label ||
        size
    );
};

export const getChosenSizeLabel = (product, chosenSize) => {
    const { variants = {}, family: { variant_dimension: variantDimension = null } = {} } = product;

    const productVariant = variants[chosenSize];

    return productVariant ? getProductVariantLabel(productVariant, variantDimension) : '';
};

export const getOneSizeWithOfferId = product => {
    const [size] = Object.keys(product.variants);

    const variant = product.variants[size];
    const offer = getFirstOfferFromVariant(variant);

    if (offer === null) {
        return null;
    }

    const {
        id: offerId,
        store: { internal: isInternal },
    } = offer;

    return { size, offerId, isInternal };
};

export const isProductInStock = (productVariants = {}) => {
    const total = Object.values(productVariants).reduce(
        (totalStock, variant) => totalStock + (variant.stock_quantity || 0),
        0
    );

    return total > 0;
};

export const sortProductVariantsBySortOrder = (variant1, variant2) =>
    variant1.sort_order - variant2.sort_order;

export const getProductVariantsBySortOrder = (productVariants = {}) => {
    return Object.entries(productVariants)
        .map(([sizeKey, variant]) => {
            return { ...variant, sizeKey };
        })
        .sort(sortProductVariantsBySortOrder);
};

export const getPrice = (
    regular,
    promotional,
    omnibus,
    discount,
    discountRegular,
    isMinimal = false
) => {
    const { amount: regularAmount, formatted: regularFormatted } = regular;
    const { amount: promotionalAmount, formatted: promotionalFormatted } = promotional;

    let { amount: omnibusAmount, formatted: omnibusFormatted } = omnibus || {};
    let discountValue = discount;

    if (
        regularAmount < promotionalAmount ||
        (regularAmount === promotionalAmount &&
            omnibusAmount &&
            omnibusAmount > promotionalAmount) ||
        (omnibusAmount && omnibusAmount < 0)
    ) {
        omnibusAmount = null;
        omnibusFormatted = null;
        discountValue = null;
    }

    return {
        regular: {
            amount: regularAmount,
            formatted: regularFormatted,
        },
        promotional: {
            amount: promotionalAmount,
            formatted: promotionalFormatted,
        },
        omnibus: {
            amount: omnibusAmount,
            formatted: omnibusFormatted,
        },
        discount: discountValue,
        discountRegular,
        isMinimal,
    };
};

export const getManufacturerTable = product => {
    const attribute = product?.values?.[MANUFACTURER_TABLE_ATTRIBUTE_KEY];

    if (!attribute) {
        return {};
    }

    const { [MANUFACTURER_TABLE_VALUE_KEY]: manufacturerTableValues = [] } =
        attribute?.value?.values || {};

    if (!Array.isArray(manufacturerTableValues) || !manufacturerTableValues?.length) {
        return {};
    }

    const manufacturerTable = {};

    manufacturerTableValues.forEach(({ values }) => {
        if (!values) {
            return;
        }

        const sizeValues = values[MANUFACTURER_TABLE_SIZE];

        if (!sizeValues) {
            return;
        }

        MANUFACTURER_TABLE_ATTRIBUTES_KEYS.forEach(key => {
            const manufacturerValues = values[key];

            if (!manufacturerValues) {
                return;
            }

            manufacturerTable[key] = {
                ...manufacturerTable[key],
                [sizeValues]: {
                    value: manufacturerValues || '-',
                    label: MANUFACTURER_TABLE_LABELS[key] || '',
                },
            };
        });
    });

    return manufacturerTable;
};

export const getManualTable = product => {
    const { variants, attributes } = product;

    if (!variants || !attributes) {
        return;
    }

    const manualTable = {};

    Object.values(variants).forEach(({ values, size }) => {
        MANUAL_TABLE_KEYS.forEach(attribute => {
            const propertyValue = values[attribute];
            const propertyAttribute = attributes[attribute];

            if (!propertyValue || !propertyAttribute) {
                return false;
            }

            const { formatted, value } = propertyValue;
            const { label, code } = propertyAttribute;

            manualTable[code] = {
                ...manualTable[code],
                [size]: { formatted, label, value },
            };
        });
    });

    if (!Object.keys(manualTable).length) {
        return getManufacturerTable(product);
    }

    return manualTable;
};

const addSizeToSizeTable = (safeSizeValues, attribute, sizeTable, size) => {
    if (!safeSizeValues) {
        return;
    }

    const { label, url } = attribute;

    const { value } = safeSizeValues;

    sizeTable[url] = {
        [size]: {
            label,
            value,
        },
    };
};

export const getSizeTable = product => {
    const { variants, attributes } = product;

    if (!variants) {
        return {};
    }

    const safeSizeTable = {};

    Object.values(variants).forEach(({ size, values }) => {
        Object.entries(values).forEach(([code, value]) => {
            if (![SAFE_SIZE_MAX_SIZE, SAFE_SIZE_LENGTH].includes(code)) {
                return;
            }

            const attribute = attributes[code];

            addSizeToSizeTable(value, attribute, safeSizeTable, size);
        });
    });

    if (!Object.keys(safeSizeTable).length) {
        return getManualTable(product);
    }

    return safeSizeTable;
};

const getCategoryAndAttribute = (product, productAttributeCode) => {
    const {
        category: { label: categoryName = '', url: categoryUrl = '' },
        values = {},
    } = product || {};
    const productAttribute = values[productAttributeCode] || null;

    return {
        categoryName,
        categoryUrl,
        productAttribute,
    };
};

export const getCategoryUrlsWithColorFilter = (
    product,
    $createCategoryPath,
    $stringifyCategoryUrl
) => {
    const { categoryName, categoryUrl, productAttribute } = getCategoryAndAttribute(
        product,
        COLOR_ARRAY_ATTRIBUTE_NAME
    );
    const { attribute: { url: attributeUrl = null } = {}, value: colors = null } =
        productAttribute || {};

    if (!attributeUrl || !colors) {
        return [];
    }

    return Object.values(colors).map(({ url: valueUrl, label }) => ({
        label: `${categoryName} ${label}`,
        url: $createCategoryPath(
            $stringifyCategoryUrl({
                categoryUrl,
                filters: {
                    [attributeUrl]: [valueUrl],
                },
            })
        ),
    }));
};

export const getCategoryUrlWithBrandFilter = (
    product,
    $createCategoryPath,
    $stringifyCategoryUrl
) => {
    const { categoryName, categoryUrl, productAttribute } = getCategoryAndAttribute(
        product,
        BRAND_WITH_COLLECTION_ATTRIBUTE_NAME
    );

    if (!productAttribute) {
        return null;
    }

    const [brandValue = null] = Object.values(productAttribute.value);

    if (brandValue === null) {
        return null;
    }

    const { url, label } = brandValue;

    return {
        label: `${categoryName} ${label}`,
        path: $createCategoryPath(
            $stringifyCategoryUrl({
                categoryUrl,
                filters: {
                    [productAttribute.attribute.url]: [url],
                },
            })
        ),
    };
};

export const getCategoriesWithBrandFilter = ({
    categories,
    brandAttribute,
    brandUrl,
    brandName,
    locale,
    $createCategoryPath,
    $stringifyCategoryUrl,
}) => {
    const SEO_CATEGORY_VARIABLE_REGEXP = /(\(\([^)]+\)\))/gm;

    return categories
        .map(category => {
            const { header, url: categoryUrl, label: categoryLabel } =
                category.translations[locale] || {};

            if (!header || !categoryUrl || !categoryLabel) {
                return null;
            }

            const { url: brandAttributeUrl } = brandAttribute;

            let [headerMain] = header.split('{');

            headerMain = headerMain.replaceAll(SEO_CATEGORY_VARIABLE_REGEXP, categoryLabel);

            const label = `${headerMain.trim()} ${brandName}`;

            const url = $createCategoryPath(
                $stringifyCategoryUrl({
                    categoryUrl,
                    filters: {
                        [brandAttributeUrl]: [brandUrl],
                    },
                })
            );

            return {
                url,
                label,
            };
        })
        .filter(category => !!category);
};

export const formatMarketingContent = content => {
    const boldRegExp = /\*\*(.*?)\*\*/gm;
    const tagsRegExp = /(<([^>]+)>)/gi;

    return content.replace(tagsRegExp, '').replace(boldRegExp, '<strong>$1</strong>');
};

export const isProductPricesEqual = (firstPrice, secondPrice) => {
    const {
        promotional: { amount: firstPromotionalPrice } = {},
        regular: { amount: firstRegularPrice } = {},
    } = firstPrice || {};
    const {
        promotional: { amount: secondPromotionalPrice } = {},
        regular: { amount: secondRegularPrice } = {},
    } = secondPrice || {};

    return (
        firstPromotionalPrice === secondPromotionalPrice && firstRegularPrice === secondRegularPrice
    );
};

export const getManufacturerVariantValue = (sizeTable, attribute, size) => {
    const sizeValue = sizeTable?.[attribute]?.[size];

    if (!sizeValue) {
        return;
    }

    return {
        attribute: {
            code: size,
            url: attribute,
            label: sizeValue.label,
        },
        value: parseFloat(sizeValue.value) * 10,
    };
};

export const getManufacturerVariant = (sizeTable, attribute, size) => {
    return {
        doubleSize: size,
        sizeLabel: size,
        size,
        values: {
            [attribute]: getManufacturerVariantValue(sizeTable, attribute, size),
        },
    };
};

export const addManufacturerTableResult = (productVariants, manufacturerSizeTable) => {
    const productVariantWithSizes = { ...productVariants };

    Object.entries(manufacturerSizeTable).forEach(([attribute, sizes]) => {
        Object.keys(sizes).forEach(size => {
            if (!productVariantWithSizes[size]) {
                productVariantWithSizes[size] = getManufacturerVariant(
                    manufacturerSizeTable,
                    attribute,
                    size
                );

                return;
            }

            productVariantWithSizes[size] = {
                ...productVariantWithSizes[size],
                values: {
                    ...productVariantWithSizes[size].values,
                    [attribute]: {
                        ...getManufacturerVariantValue(manufacturerSizeTable, attribute, size),
                        ...productVariantWithSizes[size].values[attribute],
                    },
                },
            };
        });
    });

    return productVariantWithSizes;
};

export const getSellersOffersFromVariant = ({ offers = [] }) => {
    if (Object.keys(offers).length === 0) {
        return [];
    }

    return (
        offers
            .map(({ id, store, discount, price, final_price, omnibus_price, omnibus_discount }) => {
                return {
                    id,
                    store,
                    discount,
                    price,
                    final_price,
                    omnibus_price,
                    omnibus_discount,
                };
            })
            .sort(({ final_price: { amount: amount1 } }, { final_price: { amount: amount2 } }) => {
                return amount1 - amount2;
            }) || []
    );
};

export const getVariantKey = (variants, variantId) =>
    Object.entries(variants || {}).find(([, { id }]) => id === variantId)?.[0] || '';
