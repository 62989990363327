import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG } from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default (megatronClient, $errorHandler) => ({
    async validateMerchant(payload) {
        try {
            return await megatronClient.post('v1/apple-pay/session', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                payload,
                context: {
                    useAuthorization: false,
                },
            });
        } catch (error) {
            $errorHandler.captureError(
                error,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.apple-pay.session',
                },
                {
                    payload,
                }
            );
        }
    },
});
