<template>
    <SvgIcon stroke-color="none" class="modivo-logo" width="147" height="38">
        <ModivoLogo />
    </SvgIcon>
</template>

<script>
import ModivoLogo from '@static/logo/logo-modivo.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'MainLogo',

    components: {
        SvgIcon,
        ModivoLogo,
    },
};
</script>

<style lang="scss">
.modivo-logo {
    width: 100px;
    height: 26px;
}

@screen lg {
    .modivo-logo {
        width: 200px;
        height: 38px;
    }
}
</style>
