import { createNamespacedHelpers } from 'vuex';

const { mapState: mapConfigState } = createNamespacedHelpers('config');

export default {
    computed: {
        ...mapConfigState(['storeView', 'storeViews']),

        currentLocale() {
            return this.formatLocales(this.storeView);
        },

        availableLocales() {
            return this.storeViews.map(storeView => this.formatLocales(storeView));
        },
    },

    methods: {
        formatLocales({ base_url: baseUrl, code, locale }) {
            const label = locale.split('_')[1];

            return {
                baseUrl,
                fullUrl: `https://${baseUrl}`,
                code,
                label,
                flagName: `Flag${label}`,
            };
        },
    },
};
