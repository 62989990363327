import { SEARCH_RESULTS_PAGE_NAME, CATALOG_PAGE_NAME } from '@search/routing/names';

export const CONNECT_CLIENT_DELAY = 2000;

export const ROUTES_USING_GENERATE_AVATAR_ID_IN_SSR = [SEARCH_RESULTS_PAGE_NAME, CATALOG_PAGE_NAME];

export const GENERATE_AVATAR_ID_IN_SSR_TEST_NAME = 'mod_all_generate_avatar_id_in_ssr';
export const VARIANT_ON = 'on';

export const AVATAR_ADDRESS_TYPES = {
    DELIVERY: 'delivery',
    BILLING: 'billing',
};

export const AVATAR_ID_CREATION_TIMEOUT_THRESHOLD_IN_MILLISECONDS = 250;
