import productMutations from './mutations';
import productActions from './actions';
import productGetters from './getters';

export const state = () => ({
    urlKey: '',
    productsReviewsTotalCount: {},
    relatedProducts: [],
    colorVariants: [],
    listingCategoryId: '',
});

export const getters = productGetters;
export const mutations = productMutations;
export const actions = productActions;
