import { GENDER_COOKIE_NAME } from '@configs/storage';
import { FOUND_HTTP_CODE } from '@configs/redirect';

import { PAGE_PATH_SUFFIX } from '@router/config';
import { HOME_PAGE_NAME, MAIN_CATEGORY_PAGE_NAME } from '@router/names';

import { CATALOG_PAGE_NAME } from '@search/routing/names';

export const redirectToMainCategoryPage = ($cookies, routeName, redirect, query) => {
    if (routeName !== HOME_PAGE_NAME) {
        return;
    }

    const mainCategorySlug = $cookies.get(GENDER_COOKIE_NAME);

    if (mainCategorySlug === 'home-decor') {
        return redirect(FOUND_HTTP_CODE, {
            name: CATALOG_PAGE_NAME,
            params: {
                url: mainCategorySlug,
            },
            query,
        });
    }

    if (mainCategorySlug) {
        return redirect(FOUND_HTTP_CODE, {
            name: MAIN_CATEGORY_PAGE_NAME,
            params: {
                slug: `${mainCategorySlug}${PAGE_PATH_SUFFIX}`,
            },
            query,
        });
    }
};
