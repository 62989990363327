export const DEFAULT_DEBOUNCE_TIME = 1000;
export const HEADING_SLOT_INDEX = 0;
export const SUBHEADING_SLOT_INDEX = 1;
export const LINK_SLOT_INDEX = 2;
export const DEFAULT_SLOT_CONTENT = 'Unnamed slot';
export const EMPTY_SLOT_CONTENT = '-';

export const AB_TEST_NAME_PREFIX = 'ab_test_reko_';
export const AB_TEST_TYPE_NAME_PREFIX = 'ab_test_visual_reko_';
export const VISUAL_SEARCH_INDEX_ID = '04e302a31d23e44c32b59d68b02e4e191673272173';
export const VISUAL_SEARCH_CAMPAIGN = 'visual_search';

export const UNKNOWN_ERROR_CODE = 'UNKNOWN_ERROR_CODE';

export const WOMAN_CATEGORY_ID = '10000';
export const MAN_CATEGORY_ID = '10287';
