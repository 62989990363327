import VisaMastercard from '@core-static/icons/methods/payment/56px/visa_mastercard.svg?inline';
import PayPalHorizontal from '@core-static/icons/methods/payment/56px/paypal-horizontal.svg?inline';
import Blik from '@core-static/icons/methods/payment/56px/blik.svg?inline';
import PayU from '@core-static/icons/methods/payment/56px/payu.svg?inline';
import Klarna from '@core-static/icons/methods/payment/56px/klarna.svg?inline';
import PayPo from '@core-static/icons/methods/payment/56px/paypo.svg?inline';
import GPay from '@core-static/icons/methods/payment/56px/gpay.svg?inline';
import Apple from '@core-static/icons/methods/payment/40px/apple.svg?inline';

import Payment from '@static/icons/48px/payment.svg?inline';
import Money from '@static/icons/48px/money.svg?inline';
import Visa from '@core-static/icons/methods/payment/48px/visa.svg?inline';
import Mastercard from '@core-static/icons/methods/payment/48px/mastercard.svg?inline';

export const GATEWAY_PAYU_CODE = 'payu_gateway';
export const GATEWAY_PAYU_CARD_CODE = 'payu_gateway_card';
export const GPAY_CODE = 'google_pay';

export const GATEWAY_BLIK_CODE = 'blik_payment';
export const GATEWAY_REDIRECT_BLIK_CODE = 'blik_gateway';
export const GATEWAY_PAYPAL_EXPRESS_CODE = 'paypal_express';
export const GATEWAY_PAYPO_CODE = 'paypo_gateway';
export const CARD_PAYMENT_CODE = 'card_payment';
export const CASH_CODE = 'cash';
export const CASH_ON_DELIVERY_IN_STORE_CODE = 'cashOnDeliveryInStore';
export const CASH_ON_DELIVERY_CODE = 'cashondelivery';
export const CHECKMO_CODE = 'checkmo';
export const BANK_TRANSFER_CODE = 'banktransfer';
export const BANK_TRANSFER_PERMANENT_ACCOUNT_NUMBER_CODE = 'bank_transfer_permanent_account_number';
export const GATEWAY_KLARNA_CODE = 'klarna_gateway';
export const GATEWAY_APPLE_PAY = 'apple_pay';

export const VISA_CODE = 'visa';
export const MASTERCARD_CODE = 'mastercard';
export const CARD_ON_FILE_SUPPORTED_CARD_BRANDS = [VISA_CODE, MASTERCARD_CODE];

export const EVENT_REPAYMENT_TYPE_AFTER_ERROR = 'after_error';

export const PAYMENT_PAYU_STORE_CODES_WITH_ICON = {
    default: PayU,
    cz: PayU,
    sk: PayU,
    de: Klarna,
};

export const PAYMENT_METHOD_CODES_WITH_DEDICATED_COMPONENT = {
    GATEWAY_BLIK_CODE,
    GATEWAY_PAYPO_CODE,
    GATEWAY_PAYU_CARD_CODE,
    GATEWAY_KLARNA_CODE,
};

export const CHECKOUT_PAYMENT_STATIC_BLOCK_IDS = {
    GATEWAY_PAYPO_CODE: 'psb_paypo_additional_checkout_info',
};

export const PAYMENT_METHOD_ICONS_MAP = {
    default: Payment,
    [GATEWAY_KLARNA_CODE]: Klarna,
    [GATEWAY_PAYU_CODE]: PAYMENT_PAYU_STORE_CODES_WITH_ICON.default,
    [GATEWAY_PAYU_CARD_CODE]: VisaMastercard,
    [GATEWAY_BLIK_CODE]: Blik,
    [GATEWAY_REDIRECT_BLIK_CODE]: Blik,
    [GATEWAY_PAYPAL_EXPRESS_CODE]: PayPalHorizontal,
    [GATEWAY_PAYPO_CODE]: PayPo,
    [CARD_PAYMENT_CODE]: VisaMastercard,
    [BANK_TRANSFER_CODE]: Payment,
    [CASH_CODE]: Money,
    [CASH_ON_DELIVERY_IN_STORE_CODE]: Money,
    [CHECKMO_CODE]: Money,
    [CASH_ON_DELIVERY_CODE]: Money,
    [VISA_CODE]: Visa,
    [MASTERCARD_CODE]: Mastercard,
    [GPAY_CODE]: GPay,
    [GATEWAY_APPLE_PAY]: Apple,
};

export const GATEWAY_PAYMENTS_METHODS = [
    GATEWAY_PAYU_CODE,
    GPAY_CODE,
    GATEWAY_PAYU_CARD_CODE,
    GATEWAY_BLIK_CODE,
    GATEWAY_REDIRECT_BLIK_CODE,
    GATEWAY_PAYPAL_EXPRESS_CODE,
    GATEWAY_PAYPO_CODE,
    GATEWAY_APPLE_PAY,
];

export const PAYMENT_METHOD_CUSTOM_PLACE_ORDER_TEXT_MAP = {
    [GATEWAY_PAYPO_CODE]: 'I order and pay up to 30 days',
};

export const BANK_TRANSFER_CODES = [
    BANK_TRANSFER_CODE,
    BANK_TRANSFER_PERMANENT_ACCOUNT_NUMBER_CODE,
];

export const PAYMENT_METHOD_NOT_SUPPORTED_TEXT = 'Not all sellers support this payment method.';

export const PAYMENT_METHOD_NOT_AVAILABLE_FOR_SHIPPING_METHOD_TEXT =
    'This payment method is not available for the selected delivery method.';

export const PAYMENT_METHOD_NOT_AVAILABLE_FOR_SHIPPING_METHODS_TEXT =
    'This method is not available for selected shipping methods.';

export const GPAY_SCRIPT = {
    hid: 'gpay',
    src: 'https://pay.google.com/gp/p/js/pay.js',
    async: true,
};

export const REJECTION_REASON_GROUPS_BLIK = {
    CODE_HAS_EXPIRED: [
        'REG_ERROR_AUTH_CODE_EXPIRED',
        'REG_ERROR_AUTH_CODE_LIMIT_EXCEEDED',
        'AUT_ERROR_USER_TIMEOUT',
        'AUTH_CODE_EXPIRED',
    ],
    PAYMENT_WAS_DECLINED: [
        'REG_ERROR_AUTH_CODE_CANCELED',
        'AUT_ERROR_USER_DECLINED',
        'AUT_ERROR_NO_AUTHORIZATION',
        'AUT_ERROR_ISSUER_DECLINED',
        'AUT_ERROR_BLIK_DECLINED',
        'AUTH_CODE_CANCELED',
    ],
    BLIK_CODE_INCORRECT: [
        'REG_ERROR_AUTH_CODE_USED',
        'REG_ERROR_AUTH_CODE_INVALID',
        'AUTH_CODE_INVALID',
        'AUTH_CODE_USED',
    ],
    LIMIT_EXCEEDED: ['AUT_ERROR_LIMIT_EXCEEDED', 'AUT_ERROR_INSUFFICIENT_FUNDS'],
    TECHNICAL_OUTAGE: ['REG_ERROR_ISSUER_TEMPORARY', 'AUT_ERROR_INSUFFICIENT_FUNDS'],
};

export const PAYMENT_STATUS_SUCCESS = 'success';
export const PAYMENT_STATUS_FAILED = 'failed';
