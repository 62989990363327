export const MEGATRON_SEARCH_GET_AUTOCOMPLETE_OPERATION_NAME = 'getAutocomplete';
export const MEGATRON_SEARCH_GET_STORE_OPERATION_NAME = 'getStore';
export const MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME = 'getProducts';
export const MEGATRON_SEARCH_GET_PRODUCTS_CATALOG_OPERATION_NAME = 'getProductsCatalog';
export const MEGATRON_SEARCH_GET_CATALOG_COLOR_VARIANTS_OPERATION_NAME = 'getCatalogColorVariants';
export const MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME = 'getProduct';
export const MEGATRON_SEARCH_GET_SEARCH_OPERATION_NAME = 'getSearch';
export const MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME = 'getSponsoredProducts';
export const MEGATRON_SEARCH_GET_CATEGORIES_OPERATION_NAME = 'getCategories';
export const MEGATRON_SEARCH_GET_FILTERS_OPERATION_NAME = 'getFilters';

export const MEGATRON_REVIEWS_GET_PRODUCT_REVIEWS_OPERATION_NAME = 'getProductReviews';

export const MEGATRON_CUSTOMER_GET_CUSTOMER_ORDER_EXT_DATA_OPERATION_NAME =
    'getCustomerOrderExtData';

export const MEGATRON_CHECKOUT_GET_SUCCESS_PAGE_DATA_OPERATION_NAME = 'getSuccessPageData';
export const MEGATRON_CHECKOUT_GET_REPAYMENT_ORDER_DETAILS_OPERATION_NAME =
    'getRepaymentOrderDetails';

export const MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO = 'magento';
export const MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT = 'checkout';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG = 'smaug-api';
export const MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST = 'magento-rest';
export const MEGATRON_CLIENT_ENDPOINT_NAME_NEAREST_PICKUP_POINTS = 'nearest-pickup-points';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE = 'synerise';
export const MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING = 'package-tracking';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH = 'search';
export const MEGATRON_CLIENT_ENDPOINT_NAME_PRODUCT_SEARCH = 'product-search';
export const MEGATRON_CLIENT_ENDPOINT_NAME_TRUSTMATE = 'trustmate';

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT,
};
export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG,
};
export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_NEAREST_PICKUP_POINTS = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_NEAREST_PICKUP_POINTS,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SYNERISE = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_PACKAGE_TRACKING = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_PRODUCT_SEARCH = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_PRODUCT_SEARCH,
};
