export const recentlyViewedProducts = {
    add: () => null,
    get: () => null,
    set: () => null,
};

export default {
    install: Vue => {
        Vue.prototype.$recentlyViewedProducts = recentlyViewedProducts;
    },
};
