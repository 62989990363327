<template>
    <div class="account-menu">
        <div v-if="showAccountVerificationWarning" class="account-verification-block">
            <div class="verification-description">
                <Icon :icon="Envelope" class="verification-icon" />
                {{ $t('Your email address is not verified.') }}
            </div>

            <ButtonLarge
                :variant="BUTTON_LARGE_PRIMARY_ACCENT"
                :disabled="isLoading"
                :loading="isLoading"
                @click="sendVerification()"
            >
                {{ $t('Confirm email address') }}
            </ButtonLarge>
        </div>

        <div class="account-navigation-links">
            <BaseLink
                v-for="(accountTooltipLink, index) in accountTooltipLinks"
                :key="index"
                :label="accountTooltipLink.name"
                :path="accountTooltipLink.path"
                :class="{
                    highlight: accountTooltipLink.highlight,
                }"
                class="account-navigation-link"
                @click.native="onLinkSelection()"
            />
        </div>

        <div class="button-container">
            <ButtonSmall :variant="LOGOUT_BUTTON_VARIANT" :wide="true" @click="onLogout()">
                {{ $t('Logout') }}
            </ButtonSmall>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import getTranslatedAccountLinks from '@assets/account-links';

import { ACCOUNT_VERIFICATION_STATES } from '@configs/account-verification';
import { MODAL_ACCOUNT_VERIFICATION, MODAL_ACCOUNT_VERIFICATION_TYPE } from '@configs/modals';

import { MODULE_NAME as CLIENT_MODULE_NAME } from '@analytics-modules/client/meta';
import {
    CONFIRM_EMAIL_HEADER_TOOLTIP_VERIFICATION_CLICK,
    CONFIRM_EMAIL_HEADER_TOOLTIP_VIEW,
} from '@analytics-modules/client/types/Events';

import { HEADER_MY_ACCOUNT_MODAL_LOGOUT_BUTTON_CLICK } from '@analytics-types/Events';

import { Envelope } from '@modivo-ui/icons/v2/action';

import BaseLink from '@atoms/BaseLink/BaseLink';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';

const { mapActions: mapCustomerActions } = createNamespacedHelpers('customer');
const { mapActions: mapAvatarActions, mapGetters: mapAvatarGetters } = createNamespacedHelpers(
    'avatar'
);
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'AccountMenu',

    components: {
        BaseLink,
        ButtonLarge,
        ButtonSmall,
        Icon,
    },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapAvatarGetters(['canVerifyAccount']),
        ...mapConfigGetters(['isAccountVerificationEnabled', 'storeCode']),

        showAccountVerificationWarning() {
            return this.isAccountVerificationEnabled && this.canVerifyAccount;
        },
    },

    beforeCreate() {
        this.Envelope = Envelope;
        this.LOGOUT_BUTTON_VARIANT = BUTTON_SMALL_VARIANTS.SECONDARY;
        this.BUTTON_LARGE_PRIMARY_ACCENT = BUTTON_LARGE_VARIANTS.PRIMARY_ACCENT;

        this.accountTooltipLinks = getTranslatedAccountLinks(
            this.$t.bind(this),
            this.$tPath.bind(this)
        );
    },

    mounted() {
        if (this.showAccountVerificationWarning) {
            this.$analytics.moduleEmit(CLIENT_MODULE_NAME, CONFIRM_EMAIL_HEADER_TOOLTIP_VIEW);
        }
    },

    methods: {
        ...mapCustomerActions(['logout']),
        ...mapAvatarActions(['sendAccountVerification']),

        async sendVerification() {
            this.isLoading = true;
            this.$analytics.moduleEmit(
                CLIENT_MODULE_NAME,
                CONFIRM_EMAIL_HEADER_TOOLTIP_VERIFICATION_CLICK
            );

            const isSuccess = await this.sendAccountVerification();

            this.$emit('close');

            if (isSuccess) {
                await this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
                    [MODAL_ACCOUNT_VERIFICATION_TYPE]: ACCOUNT_VERIFICATION_STATES.PENDING,
                });
            }

            this.isLoading = false;
        },

        async onLogout() {
            await this.logout();
            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_LOGOUT_BUTTON_CLICK);
        },

        onLinkSelection() {
            this.$modals.close('account-menu');
        },
    },
};
</script>

<style scoped lang="scss">
.account-menu {
    .account-verification-block {
        @apply p-3 bg-warning-shadow flex flex-col;
    }

    .verification-description {
        @apply flex items-start leading-r text-r font-bold text-tertiary mb-12p;
    }

    .verification-icon {
        @apply shrink-0 mr-2 mt-1;
    }

    .account-navigation-links {
        @apply px-ui-4 flex flex-col;
    }

    .account-navigation-link {
        @apply py-ui-3 leading-ui-24 text-ui-16;
    }

    .button-container {
        @apply px-ui-4 mt-ui-4;
    }

    @screen lg {
        .account-verification-block {
            @apply -mx-4 -mt-4;
        }

        .account-navigation-links {
            @apply px-ui-0;
        }

        .button-container {
            @apply px-ui-0;
        }
    }
}
</style>
