<template>
    <div class="footer-menu">
        <FooterLinks
            v-for="(section, index) in menuSections"
            :key="`${section.sectionName}-${index}`"
            :heading="section.sectionName"
            class="links"
        >
            <FooterLink
                v-for="({ path, label, isEmitAction }, key) in section.links"
                :key="`${label}-${key}`"
                :path="path"
                :label="label"
                :is-emit-action="isEmitAction"
            />
        </FooterLinks>
    </div>
</template>

<script>
import FooterLink from '@atoms/FooterLink/FooterLink';

import FooterLinks from '@molecules/FooterLinks/FooterLinks';

export default {
    name: 'FooterMenu',

    components: {
        FooterLink,
        FooterLinks,
    },

    props: {
        menuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-menu {
    @screen lg {
        @apply flex;

        .links {
            @apply w-1/3 mr-3;
        }
    }

    @screen xl {
        .links {
            @apply mr-0;
        }
    }
}
</style>
