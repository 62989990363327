<template>
    <BaseTooltip
        :is-mobile="isMobile"
        :show-tooltip-on-mobile="false"
        class="account-tooltip"
        position="bottom-left"
        padding="9px"
        @mouseenter.native="onMouseEnterAccountTooltip()"
    >
        <HeaderActionButton
            :path="accountIconPath"
            :label="accountButtonText"
            :icon="User"
            :analytics-click-event="HEADER_MY_ACCOUNT_CLICK"
        />

        <template #tooltipContent="{ closeTooltip }">
            <AccountMenu v-if="isLoggedIn" class="tooltip-content" @close="closeTooltip()" />

            <div v-else class="tooltip-content logged-out">
                <ButtonSmall :wide="true" @click="showLoginModal()">
                    {{ $t('Login') }}
                </ButtonSmall>

                <BaseParagraph>
                    {{ $t('Are you a new customer?') }}
                </BaseParagraph>

                <TextButton class="highlight register-btn" @click.native="showRegisterModal()">
                    {{ $t('Register') }}
                </TextButton>
            </div>
        </template>
    </BaseTooltip>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { MODAL_CUSTOMER_ACCOUNT_NAME, MODAL_TYPE } from '@configs/modals';

import {
    HEADER_MY_ACCOUNT_CLICK,
    HEADER_MY_ACCOUNT_MODAL_LOGIN_CLICK,
    HEADER_MY_ACCOUNT_MODAL_REGISTER_CLICK,
    HEADER_MY_ACCOUNT_MODAL_VIEW,
} from '@analytics-types/Events';

import { REGISTER, LOGIN } from '@types/CustomerModal';

import { CUSTOMER_PAGE, LOGIN_PAGE } from '@router/paths';

import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import TextButton from '@atoms/TextButton/TextButton';
import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import AccountMenu from '@header-molecules/AccountMenu/AccountMenu';

import { User } from '@modivo-ui/icons/v2/other';

import { ButtonSmall } from '@modivo-ui/components/ButtonSmall/v1';

const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    name: 'AccountTooltip',

    components: {
        ButtonSmall,
        BaseTooltip,
        BaseParagraph,
        TextButton,
        AccountMenu,
        HeaderActionButton,
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCustomerGetters(['isLoggedIn']),

        accountIconPath() {
            return this.isLoggedIn ? this.accountPath : this.loginPath;
        },

        accountButtonText() {
            return this.isLoggedIn ? this.$t('My account') : this.$t('Login');
        },
    },

    beforeCreate() {
        this.HEADER_MY_ACCOUNT_CLICK = HEADER_MY_ACCOUNT_CLICK;

        this.User = User;

        this.accountPath = `/${CUSTOMER_PAGE}`;
        this.loginPath = `/${LOGIN_PAGE}`;
    },

    methods: {
        onMouseEnterAccountTooltip() {
            if (!this.fetchingCustomerInProgress) {
                this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_VIEW);
            }
        },

        async showRegisterModal() {
            await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                [MODAL_TYPE]: REGISTER,
            });

            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_REGISTER_CLICK);
        },

        async showLoginModal() {
            await this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                [MODAL_TYPE]: LOGIN,
            });

            this.$analytics.emit(HEADER_MY_ACCOUNT_MODAL_LOGIN_CLICK);
        },
    },
};
</script>

<style scoped lang="scss">
$account-tooltip-width: 240px;

.account-tooltip {
    .button-desktop {
        @apply hidden;
    }

    .tooltip-content {
        @apply leading-m;
        width: $account-tooltip-width;

        &.logged-out {
            .paragraph {
                @apply inline-block text-s;
            }
        }
    }

    .register-btn {
        @apply text-s text-text cursor-pointer;

        &.highlight {
            @apply text-primary;
        }
    }

    @screen ui-desktopPlus {
        .button-mobile {
            @apply hidden;
        }

        .button-desktop {
            @apply inline-flex;
        }
    }
}
</style>
