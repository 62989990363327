import { createNamespacedHelpers } from 'vuex';

import { OPTIMIZELY_SCRIPT_SRC } from '@localeConfig/keys';

const { mapState: mapConfigState, mapGetters: mapConfigGetters } = createNamespacedHelpers(
    'config'
);

export default {
    computed: {
        ...mapConfigState(['globalConfig']),
        ...mapConfigGetters(['localeConfigByKey']),

        isOptimizelyEnabled() {
            return (
                this.optimizelyScriptUrl &&
                this.globalConfig.optimizelyEnabledPages.includes(this.$route.name)
            );
        },

        optimizelyScriptUrl() {
            return this.localeConfigByKey(OPTIMIZELY_SCRIPT_SRC);
        },
    },
};
