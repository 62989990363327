export default ({ app, $mobileApp }) => {
    if (app.$abTests.getVariant('dev_mod_mobile_deeplink') !== 'on') {
        return;
    }

    if (!$mobileApp.isWebView) {
        return;
    }

    app.router.beforeEach((to, from, next) => {
        const isFirstRequest = from.path === '/' && from.name === null;

        if (isFirstRequest) {
            next();
        } else {
            const { protocol, host } = window.location;

            const url = `${protocol}//${host}${to.fullPath}`;
            const shouldContinueRouting = !$mobileApp.handleRoutingByMobileApp(url);

            if (shouldContinueRouting) {
                next();
            } else {
                next(false);
            }
        }
    });
};
