<template>
    <component
        :is="linkComponent"
        :title="category.name"
        :path="category.path"
        :data-test-id="NAVIGATION_NESTED_ITEM_LINK"
        class="nav-i"
        @click.native="onNavigationItemClick()"
        @click.native.middle="emitAnalytics()"
    >
        <slot>
            <span class="truncate">{{ category.name }}</span>
        </slot>
    </component>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { NAVIGATION_NESTED_ITEM_LINK } from '@types/AutomaticTestIDs';

import { HEADER_MEGA_MENU_SELECTION } from '@analytics-types/Events';

import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

const { mapState: mapNavigationState, mapActions: mapNavigationActions } = createNamespacedHelpers(
    'navigation'
);

export default {
    name: 'NavigationNestedItem',

    components: {
        BaseLink,
        BaseLinkStatic,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },
    },

    setup() {
        return {
            NAVIGATION_NESTED_ITEM_LINK,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen']),

        isHttpLink() {
            return isHttpLink(this.category.path);
        },

        linkComponent() {
            if (!this.category.path) {
                return 'div';
            }

            return this.isHttpLink ? BaseLinkStatic : BaseLink;
        },
    },

    methods: {
        ...mapNavigationActions(['setMenuDesktopIsOpen']),

        onNavigationItemClick() {
            this.emitAnalytics();
        },

        emitAnalytics() {
            this.$analytics.emit(HEADER_MEGA_MENU_SELECTION, { url: this.category.path });
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-i {
    @apply text-ui-text-primary-default flex items-center my-ui-1;
    @apply relative cursor-pointer px-ui-4;
    @apply h-6 truncate font-ui-body-m;

    &:hover {
        @apply no-underline;

        &:after {
            @apply absolute bottom-[-1px] w-full h-ui-1 z-ui-1 content-[''];
            @apply bg-ui-container-primary-default;
        }
    }
}

@screen lg {
    .nav-t,
    .nav-i {
        @apply px-0;
    }
}
</style>
