export const isTTFBTestOn = abTests => abTests.getVariant('dev_ttfb') === 'on';

export const startPerformanceMeasurement = metric => {
    window?.performance?.mark(metric.startTag);
};

export const finishPerformanceMeasurement = metric => {
    if (!window?.performance) {
        return;
    }

    const { startTag, endTag, label } = metric;

    window.performance.mark(endTag);

    const measurementStarted = window.performance
        .getEntriesByType('mark')
        .find(({ name }) => name === startTag);

    if (measurementStarted) {
        window.performance.measure(label, startTag, endTag);
    }
};

export const getPerformanceMeasurement = metric => {
    if (!window?.performance) {
        return;
    }

    const { startTag, endTag, label } = metric;

    const loadTime = window.performance.getEntriesByName(label);

    window.performance.clearMeasures(label);
    window.performance.clearMarks(startTag);
    window.performance.clearMarks(endTag);

    if (loadTime.length === 1) {
        return loadTime[0].duration.toFixed(0);
    }
};
