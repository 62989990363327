<template>
    <div class="legal-information-links">
        <BaseLinkStatic
            v-for="{ link, image, linkText } in links"
            :key="link"
            :path="link"
            target="_blank"
            class="image"
        >
            <BasePicture alt="" :src="image" :is-lazy="true" :width="160" :height="40" />
            <span class="link-text">{{ linkText }}</span>
        </BaseLinkStatic>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { LEGAL_INFORMATION_FIRST, LEGAL_INFORMATION_SECOND } from '@localeConfig/keys';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'LegalInformationLinks',

    components: {
        BaseLinkStatic,
        BasePicture,
    },

    computed: {
        ...mapConfigGetters(['localeConfigByKey']),

        links() {
            return [
                this.localeConfigByKey(LEGAL_INFORMATION_FIRST),
                this.localeConfigByKey(LEGAL_INFORMATION_SECOND),
            ].filter(legalInformation => !!legalInformation);
        },
    },
};
</script>

<style lang="scss" scoped>
$image-width-mobile: 144px;
$image-width-desktop: 160px;
$image-height-mobile: 36px;

.legal-information-links {
    @apply flex justify-center gap-2;

    .image {
        width: $image-width-mobile;
        height: $image-height-mobile;
    }

    .link-text {
        @apply sr-only;
    }

    @screen lg {
        @apply justify-start;

        .image {
            @apply h-6;
            width: $image-width-desktop;
        }
    }
}
</style>
