import { MOBILE_TOKEN_EXPIRED_PAGE_NAME } from '@router/names';

import { shouldRefreshToken, isExpired } from '@assets/auth';

let isTokenRefreshing = false;
let refreshTokenPromise;

const refreshAccessToken = async (store, refreshToken) => {
    isTokenRefreshing = true;

    await store.dispatch('customer/refreshAuthToken', refreshToken);

    isTokenRefreshing = false;
};

const buildAuthorizationHeader = accessToken => ({
    authorization: `Bearer ${accessToken}`,
});

export const createAuthorizationHeader = async ({ store, $mobileApp, $router }) => {
    const {
        accessToken,
        refreshToken,
        accessTokenExpirationISO,
        refreshTokenExpirationISO,
    } = store.state.customer;

    if ($mobileApp.isWebView) {
        if (accessToken && !isExpired(accessTokenExpirationISO)) {
            return buildAuthorizationHeader(accessToken);
        }

        const currentRouteName = $router.currentRoute.name;

        if (
            $mobileApp.hasAuthorizationTokensFromMobileApp &&
            currentRouteName !== MOBILE_TOKEN_EXPIRED_PAGE_NAME
        ) {
            refreshTokenPromise = $mobileApp.refreshMobileTokens();

            await refreshTokenPromise;

            const { accessToken: refreshedAccessToken } = store.state.customer;

            return refreshedAccessToken ? buildAuthorizationHeader(refreshedAccessToken) : {};
        }

        return {};
    }

    if (!accessToken && !refreshToken) {
        return {};
    }

    if (accessToken && !isExpired(accessTokenExpirationISO)) {
        return buildAuthorizationHeader(accessToken);
    }

    if (
        shouldRefreshToken(
            accessToken,
            accessTokenExpirationISO,
            refreshToken,
            refreshTokenExpirationISO
        )
    ) {
        if (!isTokenRefreshing) {
            refreshTokenPromise = refreshAccessToken(store, refreshToken);
        }

        await refreshTokenPromise;

        const { accessToken: refreshedAccessToken } = store.state.customer;

        return refreshedAccessToken ? buildAuthorizationHeader(refreshedAccessToken) : {};
    }

    return {};
};
