import { MAIN_CATEGORY_PAGE_PREFIX, PAGE_PATH_SUFFIX } from '@router/config';

import Navigation from '@header/models/Navigation/Navigation';

import { createPagePath } from '@assets/path';

const categoryPath = categoryUrlPath => {
    return createPagePath(categoryUrlPath, MAIN_CATEGORY_PAGE_PREFIX, PAGE_PATH_SUFFIX);
};

export default {
    activeMainCategory: (state, getters) => {
        return getters.mainCategories.find(
            category => category.slug === state.activeMainCategoryPimSlug
        );
    },

    activeMainCategoryPimCode: state => {
        const activeCategory = state.categories.find(
            category => category.pim.slug === state.activeMainCategoryPimSlug
        );

        return activeCategory?.pim.code;
    },

    isMainCategory: (_, getters) => slug => {
        return getters.mainCategoriesSlugs.includes(slug);
    },

    mainCategoriesSlugs: (_, getters) => {
        return getters.mainCategories.map(({ slug }) => slug);
    },

    mainCategories: state => {
        return state.categories
            .filter(mainCategory => mainCategory.path && mainCategory.url_path && mainCategory.pim)
            .map(category => {
                const {
                    static_block_suffix: staticBlockSuffix,
                    id,
                    display_name: displayName,
                    path,
                    url_path: urlPath,
                    custom_url_path: customUrlPath,
                    children,
                    pim: { slug = '', code = -1 },
                } = category;

                return new Navigation(
                    {
                        staticBlockSuffix,
                        id,
                        displayName,
                        slug,
                        path,
                        url: customUrlPath || categoryPath(urlPath),
                        children,
                        withDisclosures: state.isAccordionAbTestOn && code === 10496,
                        isActive: slug === state.activeMainCategoryPimSlug,
                        pimCode: code,
                    },
                    state.allowDoubleColumns
                ).normalize();
            });
    },
};
