<template>
    <div class="social-icons">
        <BaseHeading class="heading">{{ $t('Follow us') }}</BaseHeading>

        <div v-if="socialMediaLinksMap.length" class="icons">
            <BaseLinkStatic
                v-for="({ link, icon }, linkId) in socialMediaLinksMap"
                :key="linkId"
                :path="link"
                target="_blank"
                class="icon"
            >
                <SvgIcon width="36px" height="36px">
                    <component :is="icon" />
                </SvgIcon>
            </BaseLinkStatic>
        </div>
    </div>
</template>

<script>
import Instagram from '@static/icons/custom/instagram.svg?inline';
import Facebook from '@static/icons/custom/facebook.svg?inline';
import Youtube from '@static/icons/custom/youtube.svg?inline';
import Tiktok from '@static/icons/custom/tiktok.svg?inline';
import Pinterest from '@static/icons/custom/pinterest.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'SocialIcons',

    components: {
        SvgIcon,
        BaseLinkStatic,
        BaseHeading,
    },

    props: {
        instagramUrl: {
            type: String,
            default: '',
        },

        facebookUrl: {
            type: String,
            default: '',
        },

        youtubeUrl: {
            type: String,
            default: '',
        },

        tiktokUrl: {
            type: String,
            default: '',
        },

        pinterestUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        socialMediaLinksMap() {
            return [
                {
                    link: this.facebookUrl,
                    icon: Facebook,
                },
                {
                    link: this.instagramUrl,
                    icon: Instagram,
                },
                {
                    link: this.youtubeUrl,
                    icon: Youtube,
                },
                {
                    link: this.tiktokUrl,
                    icon: Tiktok,
                },
                {
                    link: this.pinterestUrl,
                    icon: Pinterest,
                },
            ].filter(({ link }) => link);
        },
    },
};
</script>

<style lang="scss" scoped>
.social-icons {
    @apply text-center;

    .heading {
        @apply uppercase;
    }

    .icons {
        @apply flex justify-center mt-3;
    }

    .icon {
        &:not(:first-of-type) {
            @apply ml-2;
        }
    }

    @screen lg {
        @apply text-left;

        .icons {
            @apply justify-start;
        }
    }
}
</style>
