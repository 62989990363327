<template>
    <header class="header-simple">
        <ContainerContent>
            <div class="wrapper">
                <div class="left-wrapper">
                    <BaseLink :path="homePagePath">
                        <MainLogo class="header-simple-logo" />
                    </BaseLink>
                    <div v-if="heading" class="header-simple-heading">
                        <BaseHeading theme="h3">{{ heading }}</BaseHeading>
                    </div>
                </div>
                <div v-if="!!$slots.default" class="center-wrapper">
                    <slot />
                </div>
                <div class="right-wrapper">
                    <!--
                        @slot Right items for header simple
                    -->
                    <slot name="right" />
                </div>
            </div>
        </ContainerContent>
    </header>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { HOME_PAGE } from '@router/paths';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseLink from '@atoms/BaseLink/BaseLink';

import MainLogo from '@molecules/MainLogo/MainLogo';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

const { mapGetters: mapNavigationGetters } = createNamespacedHelpers('navigation');

export default {
    name: 'HeaderSimple',

    components: {
        ContainerContent,
        MainLogo,
        BaseHeading,
        BaseLink,
    },
    props: {
        /**
         * Heading text.
         */
        heading: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapNavigationGetters(['activeMainCategory']),

        homePagePath() {
            return this.activeMainCategory?.url || HOME_PAGE;
        },
    },
};
</script>

<style lang="scss" scoped>
.right-wrapper,
.header-simple-heading {
    @apply hidden;
}

.header-simple {
    @apply border-b-1 border-gray4 py-3;

    .wrapper {
        @apply flex flex-col w-full items-center;

        .center-wrapper {
            @apply w-full;
        }
    }

    @screen mobile-only {
        &.is-cart {
            .wrapper {
                @apply flex-row justify-between;
            }

            .right-wrapper {
                @apply block;
            }

            .header-simple-heading {
                @apply hidden;
            }
        }
    }

    @screen lg {
        .wrapper {
            @apply flex flex-row w-full items-center justify-between;

            .center-wrapper {
                @apply hidden w-auto;
            }
        }

        .header-simple-logo {
            @apply mr-3;
        }

        .left-wrapper {
            @apply flex;
        }

        .header-simple-heading {
            @apply self-center flex;
        }

        .right-wrapper {
            @apply flex justify-end items-center text-xs;
        }

        &:deep() {
            .item {
                @apply flex mr-6 whitespace-nowrap;

                &:last-of-type {
                    @apply mr-0;
                }
            }

            .item-svg {
                @apply mr-2 text-text;

                .svg-icon {
                    @apply stroke-current;
                }
            }
        }
    }

    @screen xl {
        .right-wrapper {
            @apply text-r;
        }
    }
}
</style>
