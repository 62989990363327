import Vuex from 'vuex';

import { state, actions, mutations } from '@store/index.js';

import { LOCALE } from '@mocks/config';

import { localeConfigBuilder } from '@assets/locale-config';

import {
    state as stateLayout,
    actions as actionsLayout,
    mutations as mutationsLayout,
} from '@store/layout';

import {
    state as stateCustomer,
    actions as actionsCustomer,
    getters as gettersCustomer,
    mutations as mutationsCustomer,
} from '@store/customer';

import {
    state as stateCart,
    actions as actionsCart,
    getters as gettersCart,
    mutations as mutationsCart,
} from '@store/cart';

import {
    state as stateNavigation,
    actions as actionsNavigation,
    getters as gettersNavigation,
    mutations as mutationsNavigation,
} from '@store/navigation';

import {
    state as stateCmsBlock,
    actions as actionsCmsBlock,
    getters as gettersCmsBlock,
    mutations as mutationsCmsBlock,
} from '@store/cmsBlock';

import {
    state as stateConfig,
    actions as actionsConfig,
    getters as gettersConfig,
    mutations as mutationsConfig,
} from '@store/config';

import {
    state as stateValidation,
    actions as actionsValidation,
    mutations as mutationsValidation,
} from '@store/validation';

import {
    state as stateRecentlyViewedProducts,
    actions as actionsRecentlyViewedProducts,
    mutations as mutationsRecentlyViewedProducts,
} from '@store/recentlyViewedProducts';

import {
    state as stateLoyaltyClub,
    actions as actionsLoyaltyClub,
    getters as gettersLoyaltyClub,
    mutations as mutationsLoyaltyClub,
} from '@store/loyaltyClub';

import {
    state as stateAvatar,
    actions as actionsAvatar,
    mutations as mutationsAvatar,
    getters as gettersAvatar,
} from '@store/avatar';

import headerStore from '@header/store';

import { errorHandler } from '~/.storybook/errorHandler';
import { checkoutService, cmsBlocksService } from '~/.storybook/services';
import { recentlyViewedProducts } from '~/.storybook/recentlyViewedProducts';

const stateConfigInit = stateConfig();

stateConfigInit.storeViews = [
    {
        base_currency_code: 'PLN',
        base_url: 'modivo-local.pl',
        code: 'default',
        default_display_currency_code: 'PLN',
        locale: 'pl_PL',
        timezone: 'Europe/Warsaw',
        weight_unit: 'kgs',
    },
];

stateConfigInit.storeView = {
    base_currency_code: 'PLN',
    base_url: 'modivo-local.pl',
    code: 'default',
    default_display_currency_code: 'PLN',
    locale: 'pl_PL',
    timezone: 'Europe/Warsaw',
    weight_unit: 'kgs',
};

export const initStore = () => {
    const store = new Vuex.Store({
        state,
        actions,
        mutations,
        modules: {
            layout: {
                namespaced: true,
                state: stateLayout,
                actions: actionsLayout,
                mutations: mutationsLayout,
            },
            customer: {
                namespaced: true,
                state: stateCustomer,
                getters: gettersCustomer,
                actions: actionsCustomer,
                mutations: mutationsCustomer,
            },
            cart: {
                namespaced: true,
                state: stateCart,
                getters: gettersCart,
                actions: actionsCart,
                mutations: mutationsCart,
            },
            cmsBlock: {
                namespaced: true,
                state: stateCmsBlock,
                getters: gettersCmsBlock,
                actions: actionsCmsBlock,
                mutations: mutationsCmsBlock,
            },
            config: {
                namespaced: true,
                state: () => {
                    const config = stateConfig();

                    return {
                        ...config,
                        storeView: {
                            ...config.storeView,
                            locale: LOCALE,
                        },
                        localeConfig: localeConfigBuilder(LOCALE),
                    };
                },
                getters: gettersConfig,
                actions: actionsConfig,
                mutations: mutationsConfig,
            },
            validation: {
                namespaced: true,
                state: stateValidation,
                actions: actionsValidation,
                mutations: mutationsValidation,
            },
            navigation: {
                namespaced: true,
                state: stateNavigation,
                getters: gettersNavigation,
                actions: actionsNavigation,
                mutations: mutationsNavigation,
            },
            recentlyViewedProducts: {
                namespaced: true,
                state: stateRecentlyViewedProducts,
                actions: actionsRecentlyViewedProducts,
                mutations: mutationsRecentlyViewedProducts,
            },
            loyaltyClub: {
                namespaced: true,
                state: stateLoyaltyClub,
                getters: gettersLoyaltyClub,
                actions: actionsLoyaltyClub,
                mutations: mutationsLoyaltyClub,
            },
            avatar: {
                namespaced: true,
                state: stateAvatar,
                actions: actionsAvatar,
                mutations: mutationsAvatar,
                getters: gettersAvatar,
            },
            header: headerStore,
        },
    });

    store.app = {};
    store.app.$errorHandler = errorHandler;
    store.app.$services = {
        checkout: checkoutService,
        cmsBlocks: cmsBlocksService,
    };

    store.app.$recentlyViewedProducts = recentlyViewedProducts;

    return store;
};
