export const TYPE_CATEGORY = 'category';
export const TYPE_CUSTOM = 'custom';
export const TYPE_LOYALTY_CLUB = 'modivo_fashion_club';
export const TYPE_CART = 'cart';
export const TYPE_CART_CONFIRMATION = 'cart_confirmation';
export const TYPE_WISHLIST_PRODUCTS = 'wishlist_products';
export const TYPE_SELECTED_FOR_YOU = 'selected_for_you';
export const TYPE_DESIGNER_NEW_PRODUCTS = 'designer_new_products';

export const WEB_MOD_PDP_1 = 'web-mod-pdp-1';
export const WEB_MOD_PDP_2 = 'web-mod-pdp-2';
export const WEB_MOD_PDP_SPONSORED = 'web-mod-pdp-sponsored';
export const WEB_MOD_PDP_SIMILAR = 'web-mod-pdp-similar';
export const WEB_MOD_PDP_UNAVBL_1 = 'web-mod-pdp-unavbl-1';
export const WEB_MOD_PRECART_1 = 'web-mod-precart-1';
export const WEB_MOD_CART_1 = 'web-mod-cart-1';
export const WEB_MOD_CATEGORY_1 = 'web-mod-category-1';
export const WEB_MOD_EMPTY_CART_1 = 'web-mod-empty-cart-1';
export const WEB_PDP_PRODUCT_NOTIFICATION = 'web-pdp-product-notification';
export const WEB_MOD_CART_SPONSORED = 'web-mod-cart-sponsored';
export const WEB_MOD_HOMEPAGE_SPONSORED = 'web-mod-homepage-sponsored';
export const WEB_MOD_SEARCH_RESULTS_1 = 'web-mod-search-results-1';
export const WEB_MOD_ERROR_PAGE_1 = 'web-mod-error-page-1';

export const SYNERISE_RECOMMENDATION_TYPES = {
    TYPE_CATEGORY,
    TYPE_CUSTOM,
    TYPE_LOYALTY_CLUB,
    TYPE_CART,
    TYPE_CART_CONFIRMATION,
    TYPE_WISHLIST_PRODUCTS,
    TYPE_SELECTED_FOR_YOU,
    TYPE_DESIGNER_NEW_PRODUCTS,
    WEB_PDP_PRODUCT_NOTIFICATION,
};

export const SYNERISE_RECOMMENDATION_PLACEMENTS = {
    WEB_MOD_PDP_1,
    WEB_MOD_PDP_2,
    WEB_MOD_PDP_UNAVBL_1,
    WEB_MOD_CART_1,
    WEB_MOD_PRECART_1,
    WEB_MOD_PDP_SIMILAR,
    WEB_PDP_PRODUCT_NOTIFICATION,
    WEB_MOD_CATEGORY_1,
    WEB_MOD_SEARCH_RESULTS_1,
    WEB_MOD_ERROR_PAGE_1,
    WEB_MOD_EMPTY_CART_1,
};

export const SYNERISE_RECOMMENDATION_PRODUCT_PLACEMENTS = [WEB_MOD_PDP_UNAVBL_1, WEB_MOD_PRECART_1];
export const SYNERISE_RECOMMENDATION_CART_PLACEMENTS = [WEB_MOD_CART_1];
export const SYNERISE_RECOMMENDATION_CATEGORY_PLACEMENTS = [WEB_MOD_CATEGORY_1];
export const SYNERISE_RECOMMENDATION_SEARCH_RESULTS_PLACEMENTS = [WEB_MOD_SEARCH_RESULTS_1];
export const SYNERISE_RECOMMENDATION_ERROR_PAGE_PLACEMENTS = [WEB_MOD_ERROR_PAGE_1];

export const SYNERISE_RECOMMENDATION_TYPES_WITH_NO_UUID = [];

export const SYNERISE_RECOMMENDATION_TYPES_WITH_PRODUCT_SKU = [
    TYPE_SELECTED_FOR_YOU,
    TYPE_CART,
    TYPE_CART_CONFIRMATION,
    TYPE_DESIGNER_NEW_PRODUCTS,
    WEB_MOD_PDP_1,
    WEB_MOD_PDP_2,
    WEB_MOD_PDP_UNAVBL_1,
    WEB_MOD_CART_1,
    WEB_MOD_PRECART_1,
    WEB_MOD_PDP_SIMILAR,
    WEB_PDP_PRODUCT_NOTIFICATION,
];
