import { createNamespacedHelpers } from 'vuex';

import { ACTIVE_MAIN_CATEGORY_KEY, ORIGINAL_REFERRER_KEY } from '@configs/storage';
import { META_ROBOTS_NOINDEX_NOFOLLOW } from '@configs/seo';

import { METRICS_BY_PAGES } from '@configs/page-performance';

import GTMExcluded from '@models/GTMExcluded/GTMExcluded';

import { PAGE } from '@analytics-types/Events';
import { NOTIFICATION_NOT_SUPPORTED } from '@types/Notifications';

import { MODULE_NAME as CLIENT_MODULE } from '@analytics-module/modules/client/meta';
import { USER_TYPE_STATUS_COMPLETE } from '@analytics-module/modules/client/types/Events';

import Deferred from '@core-assets/deferred';
import { getDeviceType } from '@assets/device';
import { setNavigationClientContext } from '@assets/navigation-context';
import { startPerformanceMeasurement } from '@assets/performance';

import WithCanonicalUrl from '@mixins/WithCanonicalUrl';
import Optimizely from '@mixins/Optimizely';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapPWAState } = createNamespacedHelpers('pwa');
const { mapState: mapCustomerState, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);
const { mapActions: mapNavigationActions } = createNamespacedHelpers('navigation');

export default ({ sendPageEvent = true } = {}) => ({
    data() {
        return {
            meta: {
                title: '',
                description: '',
                robots: META_ROBOTS_NOINDEX_NOFOLLOW,
            },
            responseStatusCode: 200,
            isPageEventEmittedDeferred: new Deferred(),
            isCustomerFetchedDeferred: new Deferred(),
            isPageEventSent: false,
        };
    },

    mixins: [WithCanonicalUrl, Optimizely],

    beforeCreate() {
        if (process.client) {
            const GTMExcludedProvider = new GTMExcluded(this.$storage);

            this.excludedCategories = GTMExcludedProvider.getCategoriesAnalyticsData();
            this.excludedProviders = GTMExcludedProvider.getProvidersAnalyticsData();
            this.areConsentsAccepted = GTMExcludedProvider.setAreConsentsAccepted();
        }
    },

    created() {
        if (process.client) {
            this.$analytics.waitPromise = this.isPageEventEmittedDeferred.promise;
        }
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency']),
        ...mapPWAState(['displayMode']),
        ...mapCustomerGetters(['customerUuid', 'isLoggedIn', 'customerHashedEmail', 'userType']),
        ...mapCustomerState(['fetchingCustomerInProgress']),

        shouldSendPageEvent() {
            return sendPageEvent && !this.isPageEventSent;
        },
    },

    beforeRouteLeave(to, from, next) {
        const metricName = METRICS_BY_PAGES?.[to.name];

        if (metricName) {
            startPerformanceMeasurement(metricName);
        }

        this.$correlation.refreshIds();

        next();
    },

    watch: {
        fetchingCustomerInProgress: {
            async handler(fetchingInProgress) {
                if (fetchingInProgress) {
                    return;
                }

                this.isCustomerFetchedDeferred.resolve();
            },

            immediate: true,
        },
    },

    async beforeMount() {
        if (this.shouldSendPageEvent) {
            await this.emitPageEvent();
        }
    },

    methods: {
        ...mapNavigationActions(['setNavigationContext']),

        async sendGAEventWithUserType() {
            await this.isCustomerFetchedDeferred.promise;

            this.$analytics.moduleEmit(CLIENT_MODULE, USER_TYPE_STATUS_COMPLETE, {
                eventLabel: this.userType,
            });
        },

        async emitPageEvent({
            route,
            searchData,
            responseStatusCode,
            pagePagination,
            treeCategory,
            syneriseCampaignIds,
        } = {}) {
            const originalReferrer = this.getOriginalReferrer();

            await this.$analytics.emit(
                PAGE,
                {
                    route: route || this.$route,
                    canonicalUrl: this.canonicalUrl,
                    locale: this.locale,
                    currency: this.currency,
                    robots: this.meta.robots,
                    searchData,
                    pagePagination,
                    deviceType: getDeviceType(),
                    displayMode: this.displayMode,
                    notifications: window.Notification?.permission || NOTIFICATION_NOT_SUPPORTED,
                    responseStatusCode: responseStatusCode || this.responseStatusCode,
                    locationHref: document.location.href,
                    consents: this.areConsentsAccepted,
                    gtmCookiesSettings: {
                        excludedCategories: this.excludedCategories,
                        excludedProviders: this.excludedProviders,
                    },
                    referrer: originalReferrer,
                    syneriseContainerIds: syneriseCampaignIds,
                    treeCategory,
                },
                true
            );

            if (originalReferrer) {
                this.removeOriginalReferrer();
            }

            this.isPageEventEmittedDeferred.resolve();

            this.isPageEventSent = true;

            if (this.$lux) {
                const { name = '' } = route || this.$route;

                if (name) {
                    this.$lux.label(name);
                }

                this.$lux.markLoadTime();
                this.$lux.readyToSend();
            }

            this.sendGAEventWithUserType();
        },

        getOriginalReferrer() {
            return this.$storage.getItem(ORIGINAL_REFERRER_KEY) || '';
        },

        removeOriginalReferrer() {
            this.$storage.removeItem(ORIGINAL_REFERRER_KEY);
        },

        updateNavigationClientContext() {
            const { $store, $storage } = this;

            const clientActiveMainCategory = $storage.getItem(ACTIVE_MAIN_CATEGORY_KEY);

            if (
                clientActiveMainCategory &&
                $store.getters['navigation/isMainCategory'](clientActiveMainCategory)
            ) {
                this.setNavigationContext(clientActiveMainCategory);
            } else {
                const { slug: storeCategorySlug } =
                    $store.getters['navigation/activeMainCategory'] || {};

                if (storeCategorySlug) {
                    setNavigationClientContext($storage, storeCategorySlug);
                }
            }
        },
    },

    mounted() {
        this.updateNavigationClientContext();

        window.optimizely?.push?.({
            type: 'activate',
        });

        if (this.isOptimizelyEnabled) {
            // reruns optimizely test after hydration
            const { testOptimizely } = window;

            if (Array.isArray(testOptimizely)) {
                testOptimizely.forEach(test => test());
            }
        }
    },
});
