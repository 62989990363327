<template>
    <ExpandCollapse class="footer-links">
        <template #header>
            {{ heading }}
        </template>

        <template #expand-icon>
            <SvgIcon>
                <Add2 />
            </SvgIcon>
        </template>
        <template #collapse-icon>
            <SvgIcon>
                <Minus />
            </SvgIcon>
        </template>

        <ul class="links">
            <slot />
        </ul>
    </ExpandCollapse>
</template>

<script>
import Minus from '@static/icons/24px/minus.svg?inline';
import Add2 from '@static/icons/24px/add2.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import ExpandCollapse from '@atoms/ExpandCollapse/ExpandCollapse';

export default {
    name: 'FooterLinks',

    components: {
        ExpandCollapse,
        Minus,
        Add2,
        SvgIcon,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-links {
    .links {
        @apply flex flex-col pb-3;
    }

    &:deep() {
        .footer-link {
            &:not(:first-of-type) {
                @apply mt-3;
            }
        }

        .heading {
            @apply text-s leading-s font-book uppercase;
        }
    }

    @screen mobile-only {
        @apply border-b-1 border-gray4;

        &:first-of-type {
            @apply border-t-1;
        }

        &:deep() {
            .header {
                @apply py-14p cursor-pointer;
            }
        }
    }

    @screen lg {
        &:deep() {
            .heading {
                @apply text-r leading-r font-semibold;
            }
        }
    }
}
</style>
