<template>
    <div>
        <LoyaltyClubProvider
            v-if="isSomeModalOpen"
            #default="{ openLoyaltyClubRegistrationModal, shouldDisplayModalAfterLogin }"
        >
            <div>
                <component
                    :is="authModal"
                    v-if="isCustomerAccountModalOpen"
                    @open-loyalty-club-registration-modal="openLoyaltyClubRegistrationModal()"
                    @close-customer-modal="closeModal(MODAL_CUSTOMER_ACCOUNT_NAME)"
                />
                <MagicLinkAuth
                    v-if="isMagicLinkAuthModalOpen && isMagicLinkEnabled"
                    @close="closeModal(MODAL_MAGIC_LINK_AUTH)"
                />

                <AccountVerificationModal
                    v-if="
                        isAccountVerificationModalOpen &&
                        !isCustomerAccountModalOpen &&
                        !shouldOpenModalLoyaltyClubRegistration &&
                        !shouldOpenModalAfterLoyaltyClubRegistration &&
                        !shouldDisplayModalAfterLogin &&
                        !isModalAfterRegistrationBySocialMediaOpen
                    "
                    @close="closeModal(MODAL_ACCOUNT_VERIFICATION)"
                />
            </div>
        </LoyaltyClubProvider>
        <ModalAfterRegistrationBySocialMedia
            v-if="isModalAfterRegistrationBySocialMediaOpen"
            @close-modal="closeModal(MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA)"
        />

        <NewsletterTriggerTooltip v-if="shouldOpenNewsletterTriggerTooltip" />
        <ModalNewsletter
            v-if="shouldOpenModalNewsletter"
            @close="closeModal(MODAL_NEWSLETTER_NAME)"
        />

        <NewsletterTooltip
            v-if="isNewsletterTooltipOpenFromListing"
            :is-snackbar="true"
            @close="closeModal(NEWSLETTER_TOOLTIP)"
        />
        <InformationModal
            v-if="isModalInformationOpen"
            @close="closeModal(MODAL_ADVERTISEMENT_INFORMATION)"
        />

        <NewsletterConfirmationModal
            :show="
                isModalNewsletterConfirmationOpen &&
                shouldOpenModalNewsletterConfirmation &&
                !shouldOpenModalLoyaltyClubRegistration
            "
            @close="closeModal(MODAL_NEWSLETTER_CONFIRMATION)"
        />
        <SimilarProductsVSModal
            v-if="isSimilarProductsVSModalOpen"
            @close="closeModal(MODAL_SIMILAR_PRODUCTS_VS)"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import {
    MODAL_ADVERTISEMENT_INFORMATION,
    MODAL_CUSTOMER_ACCOUNT_NAME,
    MODAL_MAGIC_LINK_AUTH,
    MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA,
    MODAL_NEWSLETTER_NAME,
    SHOULD_OPEN_MODAL_ON_CLICK,
    MODAL_ACCOUNT_VERIFICATION,
    MODAL_NEWSLETTER_CONFIRMATION,
    MODAL_NEWSLETTER_RELEASER,
    NEWSLETTER_RELEASER_AUTO,
    NEWSLETTER_TOOLTIP,
    NEWSLETTER_TOOLTIP_RELEASER,
    NEWSLETTER_RELEASER_WISHLIST_LISTING,
    MODAL_SIMILAR_PRODUCTS_VS,
} from '@configs/modals';
import {
    GTM_CATEGORIES_STORAGE_KEY,
    PAGE_VIEWS_KEY,
    MODAL_NEWSLETTER_VIEWS_KEY,
    MODAL_NEWSLETTER_LAST_VIEW_EXPIRATION_KEY,
    STORAGE_TYPE_SESSION,
    IS_ADD_TO_WISHLIST_CLICKED,
} from '@configs/storage';
import {
    NUMBER_OF_PAGE_VIEWS_TO_SHOW_NEWSLETTER,
    NEWSLETTER_MODAL_VIEWS_LIMIT,
    MODAL_NEWSLETTER_EXCLUDED_ROUTES,
    isMobileNewsletterTooltipTestOn,
} from '@configs/newsletter';

import { incrementPagesViewsCountInStorage } from '@assets/session';
import { isSingleInputAuthTestOn } from '@configs/abtest-single-input-auth';

import InformationModal from '@organisms/InformationModal/InformationModal';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapCustomerState, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);
const { mapState: mapModalState } = createNamespacedHelpers('modal');
const { mapState: mapNavigationState } = createNamespacedHelpers('navigation');

export default {
    name: 'GlobalModals',

    components: {
        LoyaltyClubProvider: () =>
            import(
                /* webpackChunkName: "LoyaltyClubProvider" */
                '@functionals/LoyaltyClubProvider/LoyaltyClubProvider'
            ),

        CustomerAccountModal: () =>
            import(
                /* webpackChunkName: "CustomerAccountModal" */
                '@organisms/CustomerAccountModal/CustomerAccountModal'
            ),

        CustomerAccountModalSingleAuth: () =>
            import(
                /* webpackChunkName: "CustomerAccountModalSingleAuth" */
                '@organisms/CustomerAccountModalSingleAuth/CustomerAccountModalSingleAuth'
            ),

        MagicLinkAuth: () =>
            import(
                /* webpackChunkName: "MagicLinkAuth" */
                '@molecules/MagicLinkAuth/MagicLinkAuth'
            ),

        ModalAfterRegistrationBySocialMedia: () => ({
            component: import(
                /* webpackChunkName: "modal-after-registration-by-social-media" */
                '@molecules/ModalAfterRegistrationBySocialMedia/ModalAfterRegistrationBySocialMedia'
            ),
        }),

        ModalNewsletter: () => ({
            component: import(
                /* webpackChunkName: "modal-newsletter" */
                '@organisms/ModalNewsletter/ModalNewsletter'
            ),
        }),

        AccountVerificationModal: () => ({
            component: import(
                /* webpackChunkName: "AccountVerificationModal" */
                '@organisms/AccountVerificationModal/AccountVerificationModal'
            ),
        }),

        NewsletterTooltip: () => ({
            component: import(
                /* webpackChunkName: "newsletter-tooltip" */
                '@molecules/NewsletterTooltip/NewsletterTooltip'
            ),
        }),

        NewsletterConfirmationModal: () => ({
            component: import(
                /* webpackChunkName: "newsletter-confirmation-modal" */
                '@molecules/NewsletterConfirmationModal/NewsletterConfirmationModal'
            ),
        }),

        NewsletterTriggerTooltip: () => ({
            /* webpackChunkName: "newsletter-trigger-tooltip" */
            component: import('@molecules/NewsletterTriggerTooltip/NewsletterTriggerTooltip'),
        }),

        InformationModal,
        SimilarProductsVSModal: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "smilar-products-modal-vs" */ '@search-organisms/SimilarProductsVSModal/SimilarProductsVSModal'
            ),
        }),
    },

    props: {
        isAuthorizationLayout: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            modalOpeningTimeout: null,
            isModalNewsletterEnabled: false,
        };
    },

    computed: {
        ...mapConfigGetters(['isMagicLinkEnabled', 'isAccountVerificationEnabled']),
        ...mapCustomerState([
            'fetchingCustomerInProgress',
            'shouldOpenModalLoyaltyClubRegistration',
            'shouldOpenModalAfterLoyaltyClubRegistration',
            'fetchingCustomerInProgress',
            'isCustomerSubscribedToNewsletter',
            'shouldOpenModalNewsletterConfirmation',
        ]),

        ...mapCustomerGetters(['isLoggedIn']),
        ...mapModalState(['isSizeModalOpen']),
        ...mapNavigationState(['isSearchInputActive', 'isSortFilterPanelActive']),
        ...mapState(['isMobile']),

        authModal() {
            return this.isSingleInputAuthTest
                ? 'CustomerAccountModalSingleAuth'
                : 'CustomerAccountModal';
        },

        isSingleInputAuthTest() {
            return isSingleInputAuthTestOn(this.$abTests);
        },

        isAccountVerificationModalOpen() {
            return (
                this.isAccountVerificationEnabled &&
                !this.isAuthorizationLayout &&
                this.$modals.isOpen(MODAL_ACCOUNT_VERIFICATION)
            );
        },

        isCustomerAccountModalOpen() {
            return this.$modals.isOpen(MODAL_CUSTOMER_ACCOUNT_NAME);
        },

        isMagicLinkAuthModalOpen() {
            return this.$modals.isOpen(MODAL_MAGIC_LINK_AUTH);
        },

        isSomeModalOpen() {
            return (
                this.isCustomerAccountModalOpen ||
                this.isMagicLinkAuthModalOpen ||
                this.isAccountVerificationModalOpen
            );
        },

        isModalAfterRegistrationBySocialMediaOpen() {
            return this.$modals.isOpen(MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA);
        },

        isModalNewsletterOpen() {
            return this.$modals.isOpen(MODAL_NEWSLETTER_NAME);
        },

        isModalInformationOpen() {
            return this.$modals.isOpen(MODAL_ADVERTISEMENT_INFORMATION);
        },

        isModalNewsletterConfirmationOpen() {
            return this.$modals.isOpen(MODAL_NEWSLETTER_CONFIRMATION);
        },

        isSimilarProductsVSModalOpen() {
            return this.$modals.isOpen(MODAL_SIMILAR_PRODUCTS_VS);
        },

        shouldOpenNewsletterModalOnClick() {
            return (
                this.$modals.getConfig(MODAL_NEWSLETTER_NAME)[SHOULD_OPEN_MODAL_ON_CLICK] || false
            );
        },

        newsletterTooltipReleaser() {
            return this.$modals.getConfig(NEWSLETTER_TOOLTIP)[NEWSLETTER_TOOLTIP_RELEASER] || '';
        },

        isNewsletterTooltipOpen() {
            return this.$modals.isOpen(NEWSLETTER_TOOLTIP);
        },

        isNewsletterTooltipOpenFromListing() {
            return (
                this.isNewsletterTooltipOpen &&
                this.newsletterTooltipReleaser === NEWSLETTER_RELEASER_WISHLIST_LISTING
            );
        },

        shouldBlockNewsletterModal() {
            return (
                this.isCustomerAccountModalOpen ||
                this.isMagicLinkAuthModalOpen ||
                this.isSizeModalOpen ||
                this.isSearchInputActive ||
                this.isNewsletterTooltipOpen ||
                this.isModalAfterRegistrationBySocialMediaOpen ||
                this.isSortFilterPanelActive ||
                this.getIsAddToWishlistClicked() ||
                this.shouldOpenModalNewsletterConfirmation
            );
        },

        shouldOpenNewsletterTriggerTooltip() {
            return (
                this.isModalNewsletterEnabled &&
                isMobileNewsletterTooltipTestOn(this.$abTests) &&
                this.isMobile
            );
        },

        shouldOpenModalNewsletter() {
            return (
                this.isModalNewsletterOpen &&
                (this.shouldOpenNewsletterModalOnClick ||
                    (this.isModalNewsletterEnabled &&
                        (!isMobileNewsletterTooltipTestOn(this.$abTests) || !this.isMobile)))
            );
        },
    },

    watch: {
        $route: {
            async handler() {
                incrementPagesViewsCountInStorage(this.$storage);
                this.clearTimeoutForOpenNewsletterModal();
                await this.modalNewsletterInit();
            },
        },

        fetchingCustomerInProgress: {
            async handler(inProgress) {
                if (!inProgress && !this.shouldBlockNewsletterModal) {
                    await this.modalNewsletterInit();
                }
            },
        },

        shouldBlockNewsletterModal(shouldBlock) {
            if (shouldBlock) {
                this.clearTimeoutForOpenNewsletterModal();
            }
        },
    },

    beforeCreate() {
        this.MODAL_NEWSLETTER_TIMEOUT = 5000;
        this.MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA = MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA;
        this.MODAL_MAGIC_LINK_AUTH = MODAL_MAGIC_LINK_AUTH;
        this.MODAL_ACCOUNT_VERIFICATION = MODAL_ACCOUNT_VERIFICATION;
        this.MODAL_NEWSLETTER_NAME = MODAL_NEWSLETTER_NAME;
        this.MODAL_ADVERTISEMENT_INFORMATION = MODAL_ADVERTISEMENT_INFORMATION;
        this.MODAL_NEWSLETTER_NAME = MODAL_NEWSLETTER_NAME;
        this.NEWSLETTER_TOOLTIP = NEWSLETTER_TOOLTIP;
        this.MODAL_NEWSLETTER_CONFIRMATION = MODAL_NEWSLETTER_CONFIRMATION;
        this.MODAL_CUSTOMER_ACCOUNT_NAME = MODAL_CUSTOMER_ACCOUNT_NAME;
        this.MODAL_SIMILAR_PRODUCTS_VS = MODAL_SIMILAR_PRODUCTS_VS;
        this.isMobileNewsletterTooltipTestOn = isMobileNewsletterTooltipTestOn;
    },

    beforeDestroy() {
        this.clearTimeoutForOpenNewsletterModal();
    },

    async mounted() {
        incrementPagesViewsCountInStorage(this.$storage);
        await this.modalNewsletterInit();

        if (this.isMagicLinkEnabled) {
            const magicLink = this.$route.query.magiclink || null;

            if (magicLink) {
                this.$modals.open(MODAL_MAGIC_LINK_AUTH);
            }
        }

        if (this.isAccountVerificationEnabled) {
            const accountVerificationToken = this.$route.query.accountVerificationToken || null;

            if (accountVerificationToken) {
                this.$modals.open(MODAL_ACCOUNT_VERIFICATION);
            }
        }
    },

    methods: {
        closeModal(modalName) {
            this.$modals.close(modalName);

            if (this.shouldOpenModalNewsletterConfirmation) {
                this.$modals.open(MODAL_NEWSLETTER_CONFIRMATION);
            }
        },

        getModalNewsletterViews() {
            return this.$storage.getItem(MODAL_NEWSLETTER_VIEWS_KEY) || 0;
        },

        async modalNewsletterInit() {
            if (!this.fetchingCustomerInProgress) {
                this.isModalNewsletterEnabled = await this.setIsModalNewsletterEnabled();
            }

            if (this.isModalNewsletterEnabled) {
                this.setTimeoutForOpenNewsletterModal();
            }
        },

        setTimeoutForOpenNewsletterModal() {
            if (this.modalOpeningTimeout) {
                return;
            }

            this.modalOpeningTimeout = setTimeout(() => {
                this.$modals.open(MODAL_NEWSLETTER_NAME, {
                    [MODAL_NEWSLETTER_RELEASER]: NEWSLETTER_RELEASER_AUTO,
                });
            }, this.MODAL_NEWSLETTER_TIMEOUT);
        },

        clearTimeoutForOpenNewsletterModal() {
            if (!this.modalOpeningTimeout) {
                return;
            }

            clearTimeout(this.modalOpeningTimeout);
            this.modalOpeningTimeout = null;
        },

        getIsAddToWishlistClicked() {
            return (
                this.$storage?.getItem(IS_ADD_TO_WISHLIST_CLICKED, STORAGE_TYPE_SESSION) || false
            );
        },

        async setIsModalNewsletterEnabled() {
            if (this.isMagicLinkAuthModalOpen) {
                return false;
            }

            if (this.getIsAddToWishlistClicked()) {
                return false;
            }

            const isExcludedRoute = MODAL_NEWSLETTER_EXCLUDED_ROUTES.includes(this.$route.name);

            if (isExcludedRoute) {
                return false;
            }

            const modalLastViewExpirationDate = this.$storage.getItem(
                MODAL_NEWSLETTER_LAST_VIEW_EXPIRATION_KEY
            );

            if (modalLastViewExpirationDate > new Date().getTime()) {
                return false;
            }

            const isModalViewsLimitExceeded =
                this.getModalNewsletterViews() > NEWSLETTER_MODAL_VIEWS_LIMIT;

            if (isModalViewsLimitExceeded) {
                return false;
            }

            const areRequiredPageViewsExceeded =
                this.$storage?.getItem(PAGE_VIEWS_KEY, STORAGE_TYPE_SESSION) <
                NUMBER_OF_PAGE_VIEWS_TO_SHOW_NEWSLETTER;

            if (areRequiredPageViewsExceeded) {
                return false;
            }

            const consentsCategoriesFromStorage =
                this.$storage?.getItem(GTM_CATEGORIES_STORAGE_KEY) || [];

            if (!consentsCategoriesFromStorage.length) {
                return false;
            }

            const areAllConsentsAccepted = consentsCategoriesFromStorage.every(
                ({ isActive }) => isActive
            );

            if (!areAllConsentsAccepted) {
                return false;
            }

            return !this.isCustomerSubscribedToNewsletter;
        },
    },
};
</script>
