import { BASE_ACCOUNT_LINKS, NEW_RMA_LIST_ACCOUNT_LINK } from '@configs/account-links';

export default ($t, $tPath) => {
    return [...BASE_ACCOUNT_LINKS, NEW_RMA_LIST_ACCOUNT_LINK].map(item => {
        const { name, path, useTranslatePath = false } = item;

        return {
            ...item,
            name: $t(name),
            path: useTranslatePath ? $tPath(path) : path,
        };
    });
};
