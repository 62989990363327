export const types = {
    SET_URL_KEY: 'SET_URL_KEY',
    SET_PRODUCTS_REVIEWS_TOTAL_COUNT: 'SET_PRODUCTS_REVIEWS_TOTAL_COUNT',
    SET_PRODUCTS_REVIEWS_AVERAGE_RATING: 'SET_PRODUCTS_REVIEWS_AVERAGE_RATING',
    SET_RELATED_PRODUCTS: 'SET_RELATED_PRODUCTS',
    RESET_RELATED_PRODUCTS: 'RESET_RELATED_PRODUCTS',
    SET_COLOR_VARIANTS: 'SET_COLOR_VARIANTS',
    SET_LISTING_CATEGORY_ID: 'SET_LISTING_CATEGORY_ID',
};

export default {
    [types.SET_URL_KEY](state, urlKey) {
        state.urlKey = urlKey;
    },

    [types.SET_PRODUCTS_REVIEWS_TOTAL_COUNT](state, review) {
        state.productsReviewsTotalCount = {
            ...state.productsReviewsTotalCount,
            ...review,
        };
    },

    [types.SET_PRODUCTS_REVIEWS_AVERAGE_RATING](state, rating) {
        state.productsReviewsAverageRating = rating;
    },

    [types.SET_RELATED_PRODUCTS](state, products) {
        state.relatedProducts = products;
    },

    [types.RESET_RELATED_PRODUCTS](state) {
        state.relatedProducts = [];
    },

    [types.SET_COLOR_VARIANTS](state, colorVariants) {
        state.colorVariants = colorVariants;
    },

    [types.SET_LISTING_CATEGORY_ID](state, listingCategory) {
        state.listingCategoryId = listingCategory;
    },
};
